<template>
  <div class="fragment">
    <div class="progress-steps">
      <template
        v-for="(item, index) in options"
      >
        <div class="progress-steps__item"
             :class="{active : step === index + 1}"
             :key="index"
             @click="progressStepHandler(index)"
        >

          <div class="progress-steps__item-num">
            <span>{{ index + 1  }}</span>
          </div>
          <div class="progress-steps__item-name">
            {{ item }}
          </div>
        </div>
        <span class="progress-steps__spacer"
              :key="item"
        ></span>
      </template>
    </div>

    <div class="progress-steps-mobile">
      <div class="progress-steps-mobile__number">

        <div class="progress-steps-mobile__number-i">
          {{ step  }}/{{ options.length }}
        </div>
        <div class="progress-steps-mobile__circle">
          <svg
              class="progress-ring"
              width="50"
              height="50">
            <circle
                class="progress-ring__circle"
                stroke="#8F7A61"
                stroke-width="3"
                fill="transparent"
                r="22"
                cx="25"
                cy="25"
                id="progress-step-circle"
            />
          </svg>
        </div>
      </div>

      <div class="progress-steps-mobile__right">
        <div class="progress-steps-mobile__name-step">
          {{options[step - 1]}}
        </div>
        <!--<div class="progress-steps-mobile__next"-->
             <!--v-if="options[step] !== '' && options[step] !== undefined"-->
             <!--@click="progressStepHandler(step)"-->
        <!--&gt;-->
        <div class="progress-steps-mobile__next"
          v-if="step !== options.length"
        >
          {{$t('common_Next.localization_value.value')}}: {{options[step]}}
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    name: "ProgressSteps",

    props: [
      'step',
      'options',
    ],

    data(){
      return {

      }
    },

    mounted() {
      this.setProgress()
    },

    watch: {
      step: function(newVal) {
        this.step = newVal
        this.setProgress()
      }
    },

    methods:{
      progressStepHandler(val){
        if(this.step < val + 1)
          this.$emit('nextPageHandler')
        else
          this.$emit('changeStep',  val)
      },

      setProgress(){
        let circle = document.getElementById('progress-step-circle')
        let radius = circle.r.baseVal.value
        let circumference = radius * 2 * Math.PI
        let percent = ((this.step / this.options.length) * 100 )

        circle.style.strokeDasharray = `${circumference} ${circumference}`
        circle.style.strokeDashoffset = `${circumference}`


        const offset = circumference - percent / 100 * circumference
        circle.style.strokeDashoffset = offset
      }
    }
  }
</script>

<style lang="scss">
  @import "../../../../scss/colors";
  @import "../../../../scss/mixins/mixins";

  .progress-steps{
    display: flex;
    margin:  0 -12px;
    overflow-x: auto;

    @include for-992{
      display: none;
    }

    &__item{
      display: flex;
      align-items: center;
      width: fit-content;
      max-width: 100%;
      padding:  0 12px;
      cursor: pointer;

      @include for-992{
        display: none;
      }

      &.active{

        @include for-992{
          display: flex;
        }

        .progress-steps__item-num{
          background: $accent;
          color: white;

          &:before, &:after{
            opacity: 0;
            z-index: -1;
            visibility: hidden;
          }
        }

        ~ .progress-steps__item .progress-steps__item-num{
          color: $borderBrown;
          background: transparent;
          border: 1px solid $borderBrown;

          &:before, &:after{
            opacity: 0;
            z-index: -1;
            visibility: hidden;

          }
        }


        ~ .progress-steps__item .progress-steps__item-name{
          color: $borderBrown;
        }

        ~ .progress-steps__spacer:before{
          background: $mainBg;


          @include for-1120{
            background: $brownOpacity02;
          }
        }
      }



      &:nth-last-child(2) + .progress-steps__spacer{
        display: none;
      }
    }

    &__item-num{
      min-width: 32px;
      width: 100%;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      background: transparent;
      transition: .3s ease;
      border: 1px solid $accent;
      border-radius: 50%;
      margin-right: 12px;
      background: $accent;
      color: white;
      position: relative;

      &:before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background: $accent;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        transition: .15s ease;
      }

      &:after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: .15s ease;
        background: url("../../../../assets/img/common/check-white.svg") center/auto no-repeat;

      }
    }

    &__item-name{
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      white-space: nowrap;
      color: black;
      transition: .15s ease;
    }

    &__spacer{
      width: 100%;
      position: relative;
      min-width: 5px;

      @include for-992{
        display: none;
      }

      &:before{
        content: '';
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 2px;
        border-radius: 23px;
        position: absolute;
        transition: .15s ease-in-out;
        background: $accent;
        width: 100%;

      }
    }


    &-mobile {

      @include from-992{
        display: none;
      }

      display: flex;
      justify-content: space-between;

      &__circle{
        transform: translate(-50%, -50%);
        position: absolute;
        left: 50%;
        top: 50%;
        width: 50px;
        height: 50px;
      }

      .progress-ring {
        transform: rotate(180deg);


        &__circle {
          transition: 0.35s stroke-dashoffset;
          // axis compensation
          transform-origin: 50% 50%;
        }
      }


      &__number{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;

        &:after{
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          border: 2px solid $brownOpacity02;
          width: 47px;
          height: 47px;
          display: block;
          border-radius: 50%;
        }
      }

      &__number-i{
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        letter-spacing: -0.06em;
        color: $brown;
        font-weight: bold;
      }

      &__right{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        padding: 4px 0;
      }

      &__name-step{
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        text-align: right;
        color: $black;
      }

      &__next{
        font-size: 14px;
        line-height: 16px;
        text-align: right;
        color: $brown;
        cursor: pointer;
      }

    }

  }

</style>
