<template>
  <modal
      @close="$emit('close')"
      class="custom-popup large-size"
  >
    <template slot="header">
      <div class="set__name"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['consolidations_products'])"></div>
        {{ $t(`consolidations_products.localization_value.value`) }}
      </div>

    </template>
    <template slot="body">
      <div class="custom-popup__content">

        <AddProductFromWarehouse
            :consolidation="consolidation"
            :user="consolidation.data.User"
            :orderUserId="consolidation.data.User.userId"
            :localProducts="true"
            :products="localProductsForCellArray"
            @addLocalProduct="addLocalProduct"
            @removeLocalProduct="removeLocalProduct"
        />
      </div>
    </template>
    <template slot="footer">
      <div class="d-flex flex-wrap mt-2 scoped-btns"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'common_cancel',
            'common_addProducts',
          ])"></div>

        <span class="site-link site-link--alt mr-3 mb-2 mt-2"
              @click="$emit('close')"
        >
              {{ $t(`common_cancel.localization_value.value`) }}
            </span>

        <MainButton
            class="wfc"
            :value="$t(`common_addProducts.localization_value.value`)"
            @click.native="addProduct"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "../../../../commonModals/Modal";
import MainButton from "../../../../UI/buttons/MainButton/MainButton";
// import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
import {proformMixins} from "../../../../../mixins/proformMixins/proformMixins";
import {ProformProduct} from "../../../../globalModels/ProformProduct";
import {ProductForCell} from "../../../../globalModels/ProductForCell";
import {consolidationMixin} from "../../../../../mixins/consolidationMixins/consolidationMixin";
import AddProductFromWarehouse from "../../components/chunks/chunks/AddProductFromWarehouse/AddProductFromWarehouse";
// import SelectProductBlock from "../../../../coreComponents/SelectProductBlock/SelectProductBlock";

export default {
  name: "ProductsPopup",
  components: {
    AddProductFromWarehouse,
    Modal,
    MainButton,
  },

  mixins: [
    consolidationMixin,
    proformMixins
  ],

  props: {
    consolidation: Object,
    user: Object,
    productDefault: Array,
  },

  data() {
    return {
      ProformProducts: [new ProformProduct()],
      localProductsForCellArray: []
    }
  },

  mounted() {
    this.addNewLocalProductForCell()
  },

  methods: {
    addProduct(){
      let valid = true
      this.localProductsForCellArray.map((item) => {
        if(!item.data.ProformProduct.productProformValidation({
          productName: true,
          proformCategory: true,
          itemsQuantity: true,
          price: true,
          proformMaterial: true,
        })) valid = false
      })

      if(!valid) return

      //form an array of ProformProduct objects to check for duplicates
      let formattedProductsForCellArray = this.consolidation.data.productsForCellArray.map(item => {
        return item.data.ProformProduct
      })

      this.localProductsForCellArray.map((item) => {
        item.setProductCount(item.data.ProformProduct.getProductItemsQuantity())

        let duplicateItem = this.consolidation.checkForProformProductDuplicates(formattedProductsForCellArray, item.data.ProformProduct)

        if (duplicateItem === false) {
          this.consolidation.data.productsForCellArray.push(item)
          return
        }

        if (duplicateItem !== false) {
          this.consolidation.data.productsForCellArray[duplicateItem].data.productCount += item.data.ProformProduct.product.itemsQuantity
          this.consolidation.data.productsForCellArray[duplicateItem].data.ProformProduct.product.itemsQuantity += item.data.ProformProduct.product.itemsQuantity
        }

        // let currentConsolidation = this.$store.getters.getConsolidationItem
        //
        // let freeCells = [...this.consolidation.addOtherFreeCells(currentConsolidation)]
        //
        // let mixedFreeCells = [...this.consolidation.addMixedFreeCells(currentConsolidation)]
        //
        // let userCells = [...this.consolidation.addOtherUserCells(currentConsolidation, false, [])]
        //
        // item.data.FreeCellArray = freeCells
        // item.data.MixedCellArray = mixedFreeCells
        // item.data.UserCellArray = userCells


      })
      this.$emit('close')
    },

    addLocalProduct(){
      this.addNewLocalProductForCell()
    },

    removeLocalProduct(index){
      this.localProductsForCellArray.splice(index, 1)
    },

    addNewLocalProductForCell(){
      let newProformModel = new ProformProduct()
      this.initializeProductProformArray({product: newProformModel}, 'product')
      let productForCell = new ProductForCell(false, newProformModel)
      this.localProductsForCellArray.push(productForCell)
      console.log(321321, this.localProductsForCellArray);
    }
  },
}
</script>

<style scoped>

</style>
