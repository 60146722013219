<template>
  <div class="detail-cards">
    <div class="detail-cards__inner">
      <div class="detail-cards__content">
        <div class="detail-cards__section noPrintable">
          <Destination
              :consolidation="consolidation"
              :destinationData="destinationData"
              :deliveryServices="deliveryServices"
          />
        </div>
        <div class="detail-cards__section noPrintable">
          <Dimensions
              :consolidation="consolidation"
          />
        </div>
        <div class="detail-cards__section noPrintable">
          <ShippingDetails
              :consolidation="consolidation"
              :consolidationUnions="consolidationUnions"
              :deliveryServices="deliveryServices"
          />
        </div>
        <div class="detail-cards__section noPrintable">
          <Prices
              :consolidation="consolidation"
              :orderFees="orderFees"
              :showPromoFields="showPromoFields"
          />
        </div>
        <div class="detail-cards__section noPrintable">
          <Orders
              v-if="!isEUProduct"
              :consolidation="consolidation"
              :orderUserId="consolidation.data.User.userId"
          />
        </div>
        <div class="detail-cards__section">
          <Scanner
              :consolidation="consolidation"
          />
        </div>
        <div v-if="isAdmin && isFromAPI && !isOwnCarrier" class="detail-cards__section">
          <div class="accord">
            <ShowLabelPriceBlock
                :labelPrice="labelPrice"
            />
          </div>
        </div>
        <div class="detail-cards__section detail-cards__section--btn noPrintable">
          <div class="detail-cards__section-col">
            <router-link :to="$store.getters.GET_PATHS.ordersConsolidation"
                         class="site-link site-link--alt brown"
            >
              {{$t('common_cancel.localization_value.value')}}
            </router-link>
          </div>
          <div class="detail-cards__section-col">
            <MainButton
                v-if="(isFromAPI && (isFromYourCity &&
                  (Number(CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id) === Number(consolidation.getConsolidationDestination().id) ||
                  Number(CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY.id) === Number(consolidation.getConsolidationDestination().id))
                )) &&
                !hasCreateLabel &&
                !consolidation.getLabelFileUUID()"
                class="sharp"
                :value="$t('common_buyLabel.localization_value.value')"
                @click.native="getLabelPriceFromCard"
                v-bind:class="{'disabled-btn' : $store.getters.getConsolidationBtn}"
            />

            <div style="position: relative;"
                 v-else-if="isFromAPI && hasCreateLabel &&
                          (Object.keys(labelPrice).length > 0 && parseFloat(labelPrice.price) !== 0) && !consolidation.getLabelFileUUID()"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                    'consolidations_buyLabelError',
                  ])"></div>
              <MainButton
                  class="sharp"
                  :value="$t('express_CreateLabel.localization_value.value')"
                  @click.native="$emit('createLabel', $route.params.id)"
                  v-bind:class="{'disabled-btn' : $store.getters.getConsolidationBtn || blockBuyLabelConsolidation}"
              />
              <span class="default-input-wrap__error error-field error-field--btn" v-if="blockBuyLabelConsolidation">
                {{$t('consolidations_buyLabelError.localization_value.value')}}
              </span>
            </div>

            <MainButton
                v-else
                    class="sharp"
                    :value="$t('common_update.localization_value.value')"
                    @click.native="$emit('prepareSaveConsolidation', {draft: false, edit: true})"
                    v-bind:class="{'disabled-btn' : $store.getters.getConsolidationBtn}"
            />
          </div>
        </div>
      </div>
      <div class="detail-cards__aside">
        <div class="detail-cards__aside-inner">
          <CardLeftBlock
              :blockForAdmin="true"
              :backgroundImage="'product-admin'"
          >
            <template slot="body">
              <UserInfo
                  :consolidation="consolidation"
                  :user="consolidation.data.User"
              />

              <div class="user-card__body">
                <div class="block-table">
                  <div class="block-table__row mb-0">
                    <div class="block-table__col mb-3">
                      <TitleHorizontal
                          class="black mb-0"
                          :value="$t('fbm_SendFromInformation.localization_value.value')"
                          :rightBtn="true"
                          :rightBtnType="'edit'"
                          :rightBtnText="'Edit'"
                          @rightBtnClick="isSendFromDataEditPopup = true"
                      />
                    </div>
                    <div class="block-table__col block-table__col--50 mb-3">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        {{$t('common_name.localization_value.value')}}
                      </div>

                      <div class="block-table__content word-break">
                        <ValueHelper
                            :value="consolidation.data.sendFrom"
                            :deep="'first_name'"
                        />
                      </div>
                    </div>
                    <div class="block-table__col block-table__col--50 mb-3">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        {{$t('common_surName.localization_value.value')}}
                      </div>

                      <div class="block-table__content word-break">
                        <ValueHelper
                            :value="consolidation.data.sendFrom"
                            :deep="'last_name'"
                        />
                      </div>
                    </div>
                    <div class="block-table__col block-table__col--50 mb-3">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        {{$t('profile_companyName.localization_value.value')}}
                      </div>

                      <div class="block-table__content word-break">
                        <ValueHelper
                            :value="consolidation.data.sendFrom"
                            :deep="'company_name'"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <SendFromDataEditPopup
                  v-if="isSendFromDataEditPopup"
                  :consolidation="consolidation"
                  @close="isSendFromDataEditPopup = false"
              />

              <div v-if="loadButtons" class="d-flex flex-wrap noPrintable">
                <div class="mt-4  w-50"
                     v-if="consolidation.getRelatedOrders().length > 0"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate([
                'consolidations_printLabels',
                ])"></div>
                  <MainButton
                      class="w-100 mr-2"
                      :value="$t('consolidations_printLabels.localization_value.value')"
                      @click.native="$emit('printLabels')"
                  />
                </div>

                <div class="mt-4 w-50"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate([
                'common_termalPrint',
                ])"></div>
                  <MainButton
                      class="w-100"
                      :value="$t('common_termalPrint.localization_value.value')"
                      @click.native="$emit('thermalPrint')"
                  />
                </div>

                <div class="mt-4  w-50"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate([
                'consolidations_print',
                ])"></div>
                  <MainButton
                      class="mr-2"
                      :value="$t('consolidations_print.localization_value.value')"
                      @click.native="$emit('print')"
                  />
                </div>

                <div class="mt-4  w-50"
                     v-if="consolidation.getLabelFileUUID()"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate([
                'consolidation_fedexDocs',
                ])"></div>
                  <router-link :to="$store.getters.GET_PATHS.getPdfFile + '/' + consolidation.getLabelFileUUID()" :target="'_blank'">
                    <MainButton
                        class="mr-2"
                        v-bind:class="{'disabled-btn' : $store.getters.getConsolidationBtn}"
                        :value="$t(`${Number(CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id) === Number(consolidation.getConsolidationDestination().id) ?
                        'consolidation_fedexDocs' : 'common_DocsDHL'}.localization_value.value`)"
                    />
                  </router-link>
                </div>

                <div class="mt-4  w-50"
                     v-if="isFromYourCity && !isOwnCarrier && consolidation.getSystemStatus() && consolidation.getSystemStatus().value === 'completed'"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['consolidations_refundLabel',])"></div>
                  <MainButton
                      :value="$t('consolidations_refundLabel.localization_value.value')"
                      class="card-detail-left__btn mt-0"
                      @click.native="$emit('refundLabel')"
                  />
                </div>

                <div class="mt-4  w-50"
                     v-if="isAdmin && _.has(this.currentPermissions, PERMISSIONS.ORDER_NOTIFICATION_GENERAL)"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_OrderNotification',])"></div>
                  <MainButton

                      :value="$t('common_OrderNotification.localization_value.value')"
                      class="card-detail-left__btn mt-0"
                      @click.native="$emit('openOrderNotification')"
                  />
                </div>

                <div class="mt-4  w-50"
                     v-if="isAdmin && (isFedex || isDHL_USA)"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_downloadInnerLabels',])"></div>
                  <MainButton

                      :value="$t('common_downloadInnerLabels.localization_value.value')"
                      class="card-detail-left__btn mt-0"
                      @click.native="$emit('downloadInnerLabels')"
                  />
                </div>
              </div>

            </template>
          </CardLeftBlock>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Scanner from "./components/Scanner/Scanner";
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  import Destination from "./components/Destination/Destination";
  import Dimensions from "./components/Dimensions/Dimensions";
  import ShippingDetails from "./components/ShippingDetails/ShippingDetails";
  import Prices from "./components/Prices/Prices";
  import Orders from "./components/Orders/Orders";
  import UserInfo from "./components/UserInfo/UserInfo";
  import {consolidationMixinHelper} from "../../../../../../mixins/consolidationMixins/consolidationMixinHelper";
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock";
  import ShowLabelPriceBlock from "../../chunks/ConsolidationAdminBlocks/ShowLabelPriceBlock/ShowLabelPriceBlock";
  import {
    CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY,
    CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY
  } from "../../../../../../staticData/staticVariables";
  import {consolidationMixin} from "../../../../../../mixins/consolidationMixins/consolidationMixin";
  import SendFromDataEditPopup from "@/components/coreComponents/Popups/SendFromDataEditPopup/SendFromDataEditPopup";
  import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
  import TitleHorizontal from "@/components/coreComponents/TitleHorizontal/TitleHorizontal";

  export default {
    name: "ConsolidationEditingAdmin",
    components: {
      TitleHorizontal,
      ValueHelper,
      SendFromDataEditPopup,
      ShowLabelPriceBlock,
      CardLeftBlock,
      UserInfo,
      Destination,
      Dimensions,
      ShippingDetails,
      Prices,
      Orders,
      MainButton,
      Scanner
    },

    props: {
      consolidation: Object,
      orderFees: Array,
      destinationData: Array,
      consolidationUnions: Array,
      deliveryServices: Array,
      loadButtons: {
        type: Boolean,
        default: false,
      },
      showPromoFields: {
        type: Boolean,
        default: false,
      },
    },

    mixins: [
      consolidationMixinHelper,
      consolidationMixin
    ],

    watch: {
      labelPrice: function () {
        this.hasCreateLabel = true
      },
    },

    data() {
      return {
        hasCreateLabel: false,
        labelPrice: {},
        CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY: CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY,
        CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY: CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY,
        blockBuyLabelConsolidation: false,

        isSendFromDataEditPopup: false,
      }
    },
  }
</script>

<style scoped>
.card-detail-left {
  padding-bottom: 550px;
}
@media (max-width: 1600px) {
  .card-detail-left {
    padding-bottom: 150px;
  }
}
@media (max-width: 1220px) {
  .card-detail-left {
    padding-bottom: unset;
  }
}
</style>
