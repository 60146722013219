<template>
  <div class="order-create__section">

    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'consolidations_sendFrom',
        ])"></div>
      {{ $t('consolidations_sendFrom.localization_value.value') }}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'consolidations_warehouse',
          'consolidations_sender',
        ])"></div>

<!--            v-bind:class="{-->
<!--              'disable-large-switch': checkBlock()-->
<!--            }"-->
        <LargeSwitch
            :activeValue="consolidation.data.sendTypes.warehouse"
            :inactiveValue="consolidation.data.sendTypes.sender"
            :labelActiveValue="$t('consolidations_warehouse.localization_value.value')"
            :labelInactiveValue="$t('consolidations_sender.localization_value.value')"
            :checkedItem="consolidation.data.sendType"
            :name="'orderCreateSwitch'"
            @change="changeConsolidationCreateSwitch"
        />
      </div>
    </div>

    <div v-if="consolidation.data.sendType === consolidation.data.sendTypes.warehouse && warehousesOptions.length > 0"
         class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'consolidations_warehouse',
        ])"></div>
        <DefaultSelect
            :options="warehousesOptions"
            :optionsLabel="'name'"
            :otherValue="'currentTranslation'"
            :clearable="false"
            :label="$t('consolidations_warehouse.localization_value.value')"
            @change="(val) => {consolidation.setWarehouse(val)}"
            :selected="consolidation.data.warehouse"
        />
      </div>
    </div>

    <PersonalInformation
        v-if="consolidation.data.sendType === consolidation.data.sendTypes.sender"
        :consolidation="consolidation"
    />

    <AddressBlock
        v-if="consolidation.data.sendType === consolidation.data.sendTypes.sender"
        :consolidation="consolidation"
    />

  </div>
</template>

<script>
import PersonalInformation from "../../chunks/PersonalInformation/PersonalInformation";
import AddressBlock from "../../chunks/AddressBlock/AddressBlock";
import LargeSwitch from "../../../../../../UI/radiobuttons/LargeSwitch/LargeSwitch";
import {consolidationMixinHelper} from "../../../../../../../mixins/consolidationMixins/consolidationMixinHelper";
import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
import {queryFilterMixin} from "../../../../../../../mixins/routeFilterMixins/queryFilterMixin";

export default {
  name: "StepD",

  components: {
    DefaultSelect,
    LargeSwitch,
    AddressBlock,
    PersonalInformation,
  },

  props: {
    consolidation: Object,
  },
  mixins: [consolidationMixinHelper, queryFilterMixin],

  data(){
    return {
      warehousesOptions: []
    }
  },

  mounted() {
    if (this.isFromYourCity) {
      this.getWarehousesForSend()
    }
  },

  methods: {
    getWarehousesForSend() {
      let myQuery = this.createQueryBuilderObject()
      let userContacts = this.getUserContactDataFromUser(this.consolidation.data.User.user)

      if(this.getUserAlternativeContact(this.consolidation.data.User.user)) {
        userContacts = this.getUserAlternativeContact(this.consolidation.data.User.user)
      }

      let countryGroupIds = userContacts?.country_group?.ids

      myQuery.params({
        delivery_service_id: this.consolidation.getConsolidationDestination()?.delivery_service_id,
        country_group_id: countryGroupIds && countryGroupIds.length > 0 ? countryGroupIds.join(',') : '',
        consolidationType: this.consolidation.getConsolidationDestination()?.id,
        user_id: this.consolidation.data.User.user?.id,
      })

      let url = this.generateGetParamsFromQuery(myQuery, 1000, 0)

      this.$store.dispatch('fetchWarehousesFromConsolidation', url).then(response => {
        this.warehousesOptions = this.getRespData(response)
        if(!this.consolidation.getWarehouse()?.id) {
          this.consolidation.setWarehouse(this.warehousesOptions.length > 0 ? this.warehousesOptions[0] : '')
        }
      }).catch(error => this.createErrorLog(error))
    },

    changeConsolidationCreateSwitch(val) {
      this.consolidation.data.sendType = val
    },

    checkBlock() {
      // let countryGroup = this.getUserContactDataFromUser(this.consolidation.data.User.user)?.country_group

      // if(this.getUserAlternativeContact(this.consolidation.data.User.user)) {
      //   countryGroup = this.getUserAlternativeContact(this.consolidation.data.User.user)?.country_group
      // }
      // this.isUkrainianByUserData(countryGroup) ||

      if ( this.consolidation.getLabelFileUUID() !== '') {
        return true
      }
      else {
        return false
      }
    }
  }
}
</script>

<style scoped>

</style>