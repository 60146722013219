import {checkValid} from "./functions/ModelsValidate";
import {Order} from "@/components/globalModels/Order";
import {PersonalInformation} from "@/components/globalModels/PersonalInformation";


export function SendFrom() {

  this.checkValid = checkValid

  this.data = {
    SendFromArray: [
      {
        data: {
          Order: new Order(),
          PersonalInformation: new PersonalInformation(),
          defaultAddress: false,
        }
      }
    ],

  }

  this.validation = {}

  this.validationTranslate = {}

  this.validationTxt = {}

}


/*****************   FUNCTIONS   ******************/

SendFrom.prototype.addSendFrom = function () {
  this.data.SendFromArray.push(
    {
      data: {
        Order: new Order(),
        PersonalInformation: new PersonalInformation(),
        defaultAddress: false,
      }
    }
  )
}

SendFrom.prototype.removeSendFrom = function (index) {
  this.data.SendFromArray.splice(index, 1)
}

/*****************   SETTERS   ******************/

SendFrom.prototype.setSendFromItems = function (items) {
  items.forEach(((item, index) => {
    this.data.SendFromArray[index].data.PersonalInformation.setPersonalName(item.first_name)
    this.data.SendFromArray[index].data.PersonalInformation.setPersonalSurName(item.last_name)
    this.data.SendFromArray[index].data.PersonalInformation.setPersonalCompany(item.company_name)
    this.data.SendFromArray[index].data.defaultAddress = item.default_value
  }))
}

/*****************   VALIDATION   ******************/

SendFrom.prototype.sendFromValidate = function () {

  // let validationItems = {}
  //
  // let validationOptions = {}
  //
  // return this.checkValid(validationItems, validationOptions)

  let valid = true

  this.data.SendFromArray.map(item => {
    if(!item.data.PersonalInformation.personalInfoPartsValidate({
      personalName: true,
      personalSurName: true,
      personalCompany: true,
    })) {
      valid = false
    }
  })

  return valid
}

/*****************   PREPARE DATA   ******************/

SendFrom.prototype.prepareData = function () {
  let data = []

  this.data.SendFromArray.map(item => {
    data.push({
      "first_name": item.data.PersonalInformation.getPersonalName(),
      "last_name": item.data.PersonalInformation.getPersonalSurName(),
      "company_name": item.data.PersonalInformation.getPersonalCompany(),
      "default_value": item.data.defaultAddress ? 1 : 0,
    })
  })

  return data
}

