// import {ORDER_INBOUND_TYPE} from "../../staticData/staticVariables";


import {ProductCells} from "../../components/globalModels/ProductCells";
// import {ORDER_INBOUND_STATUS} from "@/staticData/staticVariables";

export const ordersInboundProductMixin = {

  methods: {

    changeInboundProductInCells() {
      // if(this.ordersInbound.data.Order.getOldStatus() === ORDER_INBOUND_STATUS.STATUS_COMPLETE) {
      //   return
      // }
      //
      // let prepareSaveData = this.ordersInbound.prepareSave({isAdmin: true, edit: true})
      // let data = {
      //   product_data: prepareSaveData['products']
      // }
      //
      // this.$store.dispatch('updateInboundProductInCells', {id: this.ordersInbound.data.Order.data.id, data}).then(response => {
      //   switch (response.status) {
      //     /**
      //      * Success
      //      */
      //     case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
      //       break
      //     }
      //     /**
      //      * Validation Error
      //      */
      //     case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
      //       let errors = response.response.data.errors;
      //       this.notifyErrorHelper(errors)
      //       break
      //     }
      //     /**
      //      * Undefined Error
      //      */
      //     default: {
      //       this.openNotify('error', 'common_notificationUndefinedError')
      //     }
      //   }
      // })
    },

    /**
     * Proforma and Product
     */

    async initProform(itemProduct, id, withoutOptions = false) {
      return this.$store.dispatch('getProducts', id + '?storageId=' + this.ordersInbound.data.Order.data.warehouse?.id).then((response) => {
        let data = this.getRespData(response)

        if (!data?.proformData?.proformOptions) {
          data.proformData.proform_category = ''
          data.proformData.proformOptions = {}
          data.proformData.proformOptions.handmade = '1'
          data.proformData.proformOptions.item_price = 0
          data.proformData.proformOptions.item_quantity = 0
        }

        itemProduct.setProductItem(data, null, this)

        if(withoutOptions) return

        return this.initialProformSelectOptions({
          val: itemProduct.product.proformData.proformCategory,
          item: itemProduct
        }).then(() => {
          itemProduct.copyStartProfile()
        }).catch(error => this.createErrorLog(error))
      }).catch(error => this.createErrorLog(error))

    },

    async changeProductObject(item, val, productForCell = false, withoutOptions = false) {
      if(val === ''){
        item.unsetProductProformType({})
        item.setProductProformDataCategory('')
        item.setProductObject('')
        item.setProductName('')
      } else {
        if(productForCell !== false){
          this.setCellsInProductForCellInbound(val, productForCell)
        }

        return this.initProform(item, val.id, withoutOptions)
      }
    },

    setCellsInProductForCellInbound(val, productForCell){
      let currentOrder = this.$store.getters.getOrdersInboundItem
      let userProductCells = []

      productForCell.data.UserMixedProductCellArray = []
      productForCell.data.UserProductCellArray = []

      val.items_in_storage.map(itemInStorage => {
        if(itemInStorage.cell_id === null) {
          productForCell.data.withoutCellDeclaredCounts = itemInStorage.quantity
        } else {
          let productCells = new ProductCells(false, itemInStorage.cell, itemInStorage.quantity, false)
          if(itemInStorage?.cell?.mixed){
            productForCell.data.UserMixedProductCellArray.push(productCells)
          } else {
            productForCell.data.UserProductCellArray.push(productCells)
          }
          userProductCells.push(productCells)
        }
      })

      let freeCells = [...this.addOtherFreeCells(currentOrder)]

      let mixedFreeCells = [...this.addMixedFreeCells(currentOrder)]

      let userCells = [...this.addOtherUserCells(currentOrder, true, userProductCells)]

      productForCell.data.FreeCellArray = freeCells
      productForCell.data.MixedCellArray = mixedFreeCells
      productForCell.data.UserCellArray = userCells

      /**
       * sort by cell count
       */
      let userArray = productForCell.data.UserProductCellArray
      let userMixedProductCellArray = productForCell.data.UserMixedProductCellArray

      productForCell.data.UserProductCellArray = this.sortCellArrayByStorageCount(userArray)
      productForCell.data.UserMixedProductCellArray = this.sortCellArrayByStorageCount(userMixedProductCellArray)

    },

    addProformProduct(array = false) {
      let newIndex = this.ordersInbound.addProformProduct(array)
      this.initializeProductProformArray(array, newIndex)
    },

    removeProformProduct(array, index) {
      this.ordersInbound.removeProformProduct(array, index)
    },

    productMassStore() {

      let dataProducts = {
        items: this.ordersInbound.prepareItems(),
      }

      let saveType = 'massStoreProducts'

      return this.productMassSave(dataProducts, saveType)
    },


    productMassUpdate(arg = false) {

      let dataProducts = {
        items: this.ordersInbound.prepareItems(
          // false,
          arg.addNewProductsFlag,
          true
        ),
      }

      this.productMassSave(dataProducts, 'massUpdateProducts').then(() => {
        /**
         * Записываем промежуточный результат ProcessResults, так как нам нужно получить product_id
         */
        let updateProcessResults = this.ordersInbound.getProcessResults()

        if (arg.addNewProductsFlag){
          let dataProductsNew = {
            items: this.ordersInbound.prepareItems(
              // false,
              arg.addNewProductsFlag,
              false
            ),
          }

          /**
           * После того, как продукты обновились, далее идет проверка есть ли новые продукты.
           * В таком случае отправляем их на massStore
           */


          this.productMassSave(dataProductsNew, 'massStoreProducts').then(() => {

            /**
             * Когда получаем ответ, берем массив с обновленными продуктами и только что созданными
             * и отправляем их массивом в prepareData ордера на prepareProducts()
             */

            arg['products'] = this.ordersInbound.prepareProducts(
              [...updateProcessResults, ...this.ordersInbound.getProcessResults()]
            )
            this.saveShipment(arg)
          })

        } else {
          arg['products'] = this.ordersInbound.prepareProducts(
            this.ordersInbound.getProcessResults()
          )
          this.saveShipment(arg)
        }
      })
    },

    productMassSave(dataProducts, saveType) {

      /**
       * Вызываем функцию перед созданием ордера
       * Если статус 200 записываем ответ в processResults
       * Сделал это через модель, так как нужно было при редактирвоании и создании
       * отлавливать этот массив
       */

      return this.$store.dispatch(saveType, dataProducts).then(response => {
        response.status = response?.data?.status || response?.response?.status

        switch (response.status) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

            /*** All items created **/
            this.ordersInbound.setProcessResults(this.getRespData(response)['processResults'])
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            this.$emit('changeStep', 2)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },


    addExistCellsForProduct(product) {
      /**
       * Добавляем ячейки которые уже сохранены в заказе
       */

      let cells = []
      let cellOptions = product.cells.map(cell => {return cell.cell})

      product.cells.map(cellItem => {
        let cell = new ProductCells(cellOptions, cellItem.cell, false, cellItem.item_quantity)
        cells.push(cell)
      })

      return cells
    },

    addCellsWithProductInWarehouse(currentProduct, existCell, existingCells) {
      /**
       * Добавляем ячейки в которых еще есть этот продукт на складе
       * Map product -> items_in_storage
       * if exist cell in storage and not selected =>
       * add this cell to productsForCellArray
       */
      let cells = []
      let cellOptions = currentProduct?.items_in_storage.map(cell => {return cell.cell})

      currentProduct?.items_in_storage.map(productInWarehouse => {
        if(productInWarehouse.damage) return

        // /**
        //  * if this cell will be selected and saved
        //  * go to next productInWarehouse
        //  */
        let findExistCell = this._.find(existingCells, function (findItem) {
          return findItem.data.cellSelected?.id === productInWarehouse.cell_id
        })
        /**
         * set storageCount from items_in_storage[index]
         */
        if(findExistCell) {
          findExistCell.data.storageCount = productInWarehouse.quantity
          return
        }
        // if(existCell){
        // }

        let cell = new ProductCells(cellOptions, productInWarehouse.cell, productInWarehouse.quantity, false)

        cells.push(cell)
      })

      return cells
    },

    addOtherUserCells(order, existCell, existingCells) {
      /**
       * Добавляем остальные ячейки юзера
       */
      let cells = []
      let userCells = order?.user_cells

      userCells = this._.sortBy(userCells, item => {return item.mixed})

      userCells.map(userCell => {

        let selectOptions = this._.clone(userCells)
        let findExistCell = null

        existingCells.map(existCell => {
          // find existingCell and change flag
          if(existCell.data.cellSelected?.id === userCell.id) {
            findExistCell = true
          }

          // remove in selectOptions existingCells
          let foundCell = this._.findIndex(selectOptions, {id: existCell.data.cellSelected?.id})
          if(foundCell > -1) {
            selectOptions.splice(foundCell, 1)
          }
        })

        // If exist -> next step
        if(findExistCell) {
          return
        }

        console.log(3333);
        console.log(selectOptions);

        let cell = new ProductCells(selectOptions, userCell, false, false)

        cells.push(cell)
      })

      return cells
    },

    addOtherFreeCells(order) {
      /**
       * Добавляем пустые ячейки
       */
      let cells = []

      order?.free_cells_without_mixed.map(freeCell => {
        let cell = new ProductCells(order.free_cells_without_mixed, freeCell, false, false)

        cell.data.cellType = 'free'

        cells.push(cell)
      })

      return cells
    },

    addMixedFreeCells(order) {
      /**
       * Добавляем пустые миксовые ячейки
       */
      let cells = []

      order?.mixed_cells_without_this_user.map(freeCell => {
        let cell = new ProductCells(order.mixed_cells_without_this_user, freeCell, false, false)

        cells.push(cell)
      })

      return cells
    },

    sortCellArrayByStorageCount(userCellArray) {
      return this._.sortBy(userCellArray, function (item) {
        return 0 + item.data.storageCount
      })
    },
  }
}
