<template>
  <div class="custom-row">
    <div class="custom-col"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit" @click="editTranslate([
                    'product_ProductName',
                  ])"></span>
      <DefaultSelect
              class="w-100"
              :label="$t('product_ProductName.localization_value.value')"
              :optionsLabel="productLabel"
              :options="optionsProducts"
              v-bind:class="{'ui-no-valid': item.validation.productName}"
              :errorTxt="$t(`${item.validationTranslate.productName}.localization_value.value`)"
              :error="item.validation.productName"
              :selected="item.product.productObject"
              :disabled="disabledProformProduct"
              @change="(val) => $emit('changeProductProformSelect', val)"
      />
              <!--@change="(val) => changeProductObjectByLocal(item, val, products[index])"-->
    </div>
  </div>
</template>

<script>
  import DefaultSelect from "../../UI/selectiones/DefaultSelect/DefaultSelect";
  export default {
    name: "SelectProformProductBlock",
    components: {DefaultSelect},

    props: {
      item: Object,
      userId: Number,
      productLabel: {
        type: String,
        default: 'name'
      },
      getProductsWithCellsData: {
        type: Boolean,
        default: false
      },
      disabledProformProduct: {
        type: Boolean,
        default: false
      },
    },

    data() {
      return {
        optionsProducts: [],
      }
    },

    mounted() {
      this.getProductsByUserId(this.userId)
    },

    methods: {
      getProductsByUserId(id) {
        const query = new this.Query();
        let url = '?';
        let myQuery = query
          .for('posts')

        if (!this.getProductsWithCellsData) {
          myQuery.where('ProductUserId', id)
        }

        url = url + myQuery.limit(100000).page(parseInt(0)).url().split('?')[1]

        let typeFetch
        if (this.getProductsWithCellsData) {
          typeFetch = 'fetchProductsWithCells'
          url = url + `&user_id=${id}`
        } else {
          typeFetch = 'fetchProducts'
        }

        this.$store.dispatch(typeFetch, url).then((response) => {
          if (this.getProductsWithCellsData) {
            this.optionsProducts = this.getRespData(response)
          } else {
            this.optionsProducts = this.getRespPaginateData(response)
          }
        })
      },
    },

  }
</script>

<style scoped>

</style>
