<template>
  <DefaultCheckbox
          :value="checkboxValue"
          @input="(val) => $emit('changeProductChecked', val)"
  />
</template>

<script>
  import DefaultCheckbox from "../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  export default {
    name: "ProductCheckedBlock",
    components: {DefaultCheckbox},

    props: {
      item: Object,
    },

    data() {
      return {
        checkboxValue: false,
      }
    },

    computed: {
      scanned(){
        return this.item.data.scannedCount
      },
      count(){
        return this.item.data.productCount
      },
    },

    watch: {
      scanned(scannedCount){
        this.checkboxValue = this.item.data.productCount === scannedCount
      },
      count(productCount){
        this.checkboxValue = this.item.data.scannedCount === productCount
      },
    },

    mounted() {
      setTimeout(() => {
        this.checkboxValue = this.item.data.productCount === this.item.data.scannedCount
      }, 500)
    }

  }
</script>

<style scoped>

</style>
