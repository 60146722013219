<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup medium-size"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([popupTitle])"></div>
        {{$t('consolidations_personalInfo.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="custom-row">

          <div class="custom-col  custom-col--md-100">
            <DefaultInput
                :label="$t('consolidations_customerName.localization_value.value')"
                :type="'text'"
                v-bind:class="{'ui-no-valid': consolidation.validation.senderContactName}"
                :errorTxt="$t(`${consolidation.validationTranslate.senderContactName}.localization_value.value`)"
                :error="consolidation.validation.senderContactName"
                v-model="senderContactName"
            />
          </div>

        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn align-items-center d-flex flex-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_close'])"></div>
        <span
            class="site-link site-link--alt custom-popup__btn-i mr-4 mt-3 mb-3"
            @click="$emit('close')"
        >
        {{$t('common_close.localization_value.value')}}
        </span>
        <MainButton
            class="custom-popup__btn-i "
            :value="$t('common_save.localization_value.value')"
            @click.native="saveChanges"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";

  export default {
    name: "ChangePersonalDataPopup",

    props: {
      consolidation: Object,
    },

    components: {
      MainButton,
      Modal,
      DefaultInput,
    },

    data() {
      return {
        senderContactName: '',
      }
    },

    mounted() {
      this.senderContactName = this.consolidation.getSenderContactName()
    },

    methods: {
      saveChanges() {
        this.consolidation.setSenderContactName(this.senderContactName)
        this.$emit('close')
      }
    }
  }
</script>

<style scoped>

</style>
