<template>
  <modal
      @close="$emit('close')"
      class="custom-popup medium-size"
  >
    <template slot="header">
      {{ $t('fbm_SendFromInformation.localization_value.value') }}
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <SendFromBlock
            v-if="FBM"
            :FBM="FBM"
            :isAdminEdit="true"
            :isEdit="true"
        />
        <SendFromBlockConso
            v-if="consolidation"
            :consolidation="consolidation"
            :isAdminEdit="true"
            :isEdit="true"
        />
      </div>
    </template>
    <template slot="footer">
      <div class="buy-label__btn d-flex align-items-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
<!--        <div class="admin-edit" @click="editTranslate(['common_cancel'])"></div>-->
<!--        <span-->
<!--            class="site-link site-link&#45;&#45;alt buy-label__btn-i mr-3"-->
<!--            @click="$emit('close')"-->
<!--        >-->
<!--          {{ $t('common_cancel.localization_value.value') }}-->
<!--        </span>-->

        <MainButton
            :value="$t('common_save.localization_value.value')"
            v-bind:class="{'disabled-btn' : $store.getters.getOrderSendFromBtn}"
            class="profiles-package__btn-i"
            @click.native="$emit('close')"
        />
      </div>
    </template>
  </modal>

</template>

<script>
import Modal from '../../../commonModals/Modal.vue'
import SendFromBlock
  from "@/components/modules/OrdersFBMModule/components/components/chunks/SendFromBlock/SendFromBlock";
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
import SendFromBlockConso
  from "@/components/modules/ConsolidationModule/components/chunks/chunks/SendFromBlockConso/SendFromBlockConso";

export default {
  name: "SendFromDataEditPopup",

  components: {
    SendFromBlockConso,
    SendFromBlock,
    MainButton,
    Modal,
  },

  props: {
    FBM: Object,
    consolidation: Object,
  },

  data() {
    return {

    }
  },

  mounted() {

  },

  methods: {

  },

}

</script>

<style scoped>

</style>
