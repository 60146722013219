<template>
  <div class="fragment">
    <div class="order-create__section-label section-label"
      v-bind:class="{
        'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate(),
        'ui-no-valid': consolidation.validation.relatedOrders
      }"
    >
      <div class="admin-edit" @click="editTranslate([
          'consolidations_ordersFBMInc',
          'consolidations_number',
          'consolidations_name',
          'consolidations_deliveryService',
          'consolidations_trackingNumber',
      ])"></div>
      {{$t('consolidations_ordersFBMInc.localization_value.value')}}
      <div class="section-label__error error-field"
           v-if="consolidation.validation.relatedOrders && $t(`${consolidation.validationTranslate.relatedOrders}.localization_value.value`)"
      >
        {{$t(`${consolidation.validationTranslate.relatedOrders}.localization_value.value`)}}
      </div>
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col">


        <div class="site-table-wrap table-small"
             v-if="!isMobileFunc()"
        >
          <table class="site-table"
                 v-if="consolidation.getRelatedOrders().length"
          >
            <thead>
            <tr>
              <th class="text-lg-right">#</th>
              <th>{{$t('consolidations_number.localization_value.value')}}</th>
              <th class="white-space-line">{{$t('consolidations_name.localization_value.value')}}</th>
              <th>{{$t('consolidations_deliveryService.localization_value.value')}}</th>
              <th>{{$t('consolidations_trackingNumber.localization_value.value')}}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>

            <tr
                v-for="(item, index) in consolidation.getRelatedOrders()"
                :key="index"
            >
              <td class="white-space-line" align="right" width="40px">
                <b>{{index + 1}}</b>
              </td>
              <td>
                <b>#{{item.id}}</b>
              </td>
              <td>
                <div class="table-row ellipsis">
                  {{item.recipient_contact_name}}
                </div>
              </td>
              <td>
                <div>
                  <ValueHelper
                      :value="item"
                      :deep="'delivery_method.name'"
                  />
                </div>
              </td>
              <td>
                <div>
                  <ValueHelper
                      :value="item"
                      :deep="'tracking_number'"
                  />
                </div>
              </td>
              <td>
                <div class="table-right table-manage-list">

                  <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
                    <template slot="item">
                      <LinkButton
                          :value="$t('common_edit.localization_value.value')"
                          :type="'edit'"
                          :target="'_blank'"
                          :link="$store.getters.GET_PATHS.ordersFBM + '/' + getOrderFBMTypeById(item.order_type_id).name + '/' + item.id"
                      />
                    </template>
                    <template slot="item">
                      <LinkButton
                          :value="$t('common_delete.localization_value.value')"
                          :type="'delete'"
                          @click.native="removeOrder(item.id)"
                      />
                    </template>
                  </ManagerButton>
                </div>
              </td>
            </tr>

            </tbody>
          </table>

        </div>

        <div class="site-table-mobile"
             v-if="isMobileFunc()"
        >
          <div class="site-table-mobile__inner custom-row">
            <div
                class="custom-col custom-col--50 custom-col--sm-100"
                v-for="(item, index) in consolidation.getRelatedOrders()"
                :key="index"
            >
              <div class="site-table-mobile__item">
                <div class="site-table-mobile__head">
                  {{item.recipient_contact_name}}
                </div>
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col custom-col--33">
                    <span>
											#{{index + 1}}
										</span>
                  </div>
                  <div class="site-table-mobile__piece custom-col custom-col--66">
                    {{$t('consolidations_number.localization_value.value')}}
                    <span>
											#{{item.id}}
										</span>
                  </div>
                  <div v-if="item.delivery_method" class="site-table-mobile__piece custom-col">
                    <span>
                      <ValueHelper
                          :value="item"
                          :deep="'delivery_method.name'"
                      />
                    </span>
                  </div>
                  <div class="site-table-mobile__piece custom-col">
                    {{$t('consolidations_trackingNumber.localization_value.value')}}
                    <div>
                      <ValueHelper
                          :value="item"
                          :deep="'tracking_number'"
                      />
                    </div>
                  </div>
                  <div class="site-table-mobile__piece custom-col">
                    <div class="table-right table-manage-list">
                      <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
                        <template slot="item">
                          <LinkButton
                              :value="$t('common_edit.localization_value.value')"
                              :type="'edit'"
                              :link="$store.getters.GET_PATHS.ordersFBM + '/' + getOrderFBMTypeById(item.order_type_id).name + '/' + item.id"
                          />
                        </template>
                        <template slot="item">
                          <LinkButton
                              :value="$t('common_delete.localization_value.value')"
                              :type="'delete'"
                              @click.native="removeOrder(item.id)"
                          />
                        </template>
                      </ManagerButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="order-create__product custom-row"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
              'consolidations_addOrder',
            ])"></div>
          <div class="order-create__product-col custom-col custom-col--16 custom-col--md-50 mt-2 ml-auto mr-auto add-order-btn">
            <span class="add-product-btn site-link justify-content-center"
                  style="font-size: 24px;"
                  @click="changeSelectOrdersToAdd(true)"
            >
              + {{$t('consolidations_addOrder.localization_value.value')}}
            </span>
          </div>
        </div>
      </div>
    </div>
    <SelectOrdersToAdd
        v-if="isModalSelectOrdersToAdd"
        :orders="ordersFBM"
        :consolidation="consolidation"
        @close="changeSelectOrdersToAdd(false)"
    />
  </div>
</template>

<script>
import SelectOrdersToAdd from "../../../../popups/SelectOrdersToAdd/SelectOrdersToAdd";
import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
import {FBMMixins} from "../../../../../../../mixins/FBMMixins/FBMMixins";
import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
import {DPD_DELIVERY_SERVICE, POSTNL_DELIVERY_SERVICE, GLOBAL24_DELIVERY_SERVICE,} from "../../../../../../../staticData/staticVariables";
import {consolidationMixinHelper} from "../../../../../../../mixins/consolidationMixins/consolidationMixinHelper";
import {proformMixins} from "../../../../../../../mixins/proformMixins/proformMixins";

export default {
  name: "OrdersFBMBlock",

  components: {
    ValueHelper,
    LinkButton,
    ManagerButton,
    SelectOrdersToAdd,
  },

  props: {
    consolidation: Object,
    orderUserId: {
      type: [Number, String]
    }
  },

  mixins: [
    mixinDetictingMobile,
    FBMMixins,
    consolidationMixinHelper,
    proformMixins,
  ],

  data() {
    return {
      isModalSelectOrdersToAdd: false,
      ordersFBM: [],
      DPD_DELIVERY_SERVICE: DPD_DELIVERY_SERVICE
    }
  },

  watch: {
    orderUserId() {
      this.initOrders()
    }
  },

  mounted() {
    if (this.consolidation.data.User.getUserId()) {
      this.initOrders()
    }
  },

  methods: {
    changeSelectOrdersToAdd(val){
      this.isModalSelectOrdersToAdd = val
    },

    initOrders() {
      const query = new this.Query();
      let url = '?';
      let myQuery = query
          .for('posts')

      console.log('user company ',this.consolidation.data.User)
      //select orders with consolidation and label type id
      myQuery.where('OrderFbmTypeId', '1,3')
      if (this.consolidation.data.User.user?.company_owner?.id) {
        myQuery.where('user_id', this.consolidation.data.User.user?.company_owner?.id)
      }
      else {
        myQuery.where('user_id', this.consolidation.data.User.getUserId())
      }
      myQuery.where('consolidationOrderQuery', '1')
      if (this.isDPD) {
        let queryString = this.DPD_DELIVERY_SERVICE.id + ',' + POSTNL_DELIVERY_SERVICE.id + ',' + GLOBAL24_DELIVERY_SERVICE.id
        myQuery.where('deliveryService',  queryString)
      }
      else {
        let queryString = this.DPD_DELIVERY_SERVICE.id + ',' + GLOBAL24_DELIVERY_SERVICE.id
        myQuery.where('exemptManyDeliveryServices',  queryString)
      }
      //COMMENT AND REDO LATER (optimisation, get proform data after picking order from select)
      // myQuery.appends('proformData')

      url = url + myQuery.limit(100000).page(1).url().split('?')[1]

      this.$store.dispatch('fetchOrdersFBMListForConsolidation', url).then(response => {
        //filter orders in draft
        this.ordersFBM = this.getRespPaginateData(response)
        console.log('orders for popup', this.ordersFBM);

        if (this.$route.query.setInitOrders) {

          const setQuery = new this.Query();
          let setUrl = '?';
          let mySetQuery = setQuery
              .for('posts')

          mySetQuery.where('OrderFbmId', this.$route.query.setInitOrders)
          mySetQuery.appends('total_proform_data')

          setUrl = setUrl + mySetQuery.limit(10000).page(1).url().split('?')[1]

          this.$store.dispatch('fetchPublicOrdersFBM', setUrl).then(setResponse => {
              //filter orders in draft
            console.log(11111, setResponse, this.$route.query.setInitOrders, setUrl);
            let setOrdersFromParams = this.getRespPaginateData(setResponse)

            this.consolidation.setRelatedOrders(setOrdersFromParams, this, false)
            setOrdersFromParams.forEach(item => {
              this.consolidation.addProformByProduct(item?.total_proform_data, true, this)
            })
          }).catch(error => this.createErrorLog(error))
        }
      }).catch(error => this.createErrorLog(error))
    },

    removeOrder(id) {
      this.consolidation.setRelatedOrders(this.consolidation.getRelatedOrders().filter(item => {
        return item.id !== id
      }))
    }
  },
}
</script>

<style lang="scss" scoped>

  .add-order-btn {
    padding: 20px 15px;
    border: 2px solid #efddc8;
    border-radius: 5px;
    width: fit-content;
  }

  .site-table thead th,
  .site-table tbody td {
    padding-right: 5px;
    padding-left: 5px;
  }

  .site-table thead th:last-child,
  .site-table tbody td:last-child {
    padding-right: 0!important;
  }

  .table-manage-list .manager-btn {
    max-width: 200px;
  }

  .table-manage-list.table-right {
    justify-content: flex-end;
  }

  .order-create__section-label.ui-no-valid {
    color: #F2994A;
    position: relative;
  }

  @media (min-width: 1600px){
    .site-table .ellipsis {
      max-width: 70%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
    }
  }
</style>