<template>
  <modal
          @close="$emit('close')"
          class="order-notification-modal order-notification"
  >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_OrderNotification'])"></div>
        {{$t(`common_OrderNotification.localization_value.value`)}}
      </div>
    </template>
    <template slot="body">
      <div class="order-notification__content">
        <div class="order-notification__row custom-row" v-if="!typeInfo">
          <div class="order-notification__col custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_comment',])"></div>
            <TextareaDefault
                    :label="$t('common_comment.localization_value.value')"
                    v-model="txt"
                    :error="validation.txt"
                    :errorTxt="$t(`${validationTranslate.txt}.localization_value.value`)"
                    :type="'text'"
                    v-bind:class="{'ui-no-valid': validation.txt}"
            />
          </div>
          <div class="order-notification__col custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_OrderNotificationActive',])"></div>
            <DefaultCheckbox
                v-model="active"
                :label="$t('common_OrderNotificationActive.localization_value.value')"
                @click="active = !active"
            />
          </div>

          <div class="order-notification__col custom-col">
            <router-link
                :target="'_blank'"
                :to="$store.getters.GET_PATHS.historyOfChanges + `?uuid=${orderNotificationData.activityUUID}`"
                class="site-link text-decoration-underline btn-style brown">
              {{$t('common_CheckHistory.localization_value.value')}}
            </router-link>
          </div>

        </div>
        <div class="order-notification__row custom-row" v-else>
          <div class="order-notification__col custom-col">
            {{orderNotificationData.description}}
          </div>
        </div>
      </div>
    </template>
    <template slot="footer" v-if="!typeInfo">
      <div class="order-notification__btn"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
              'common_cancel',
              'common_submit',
            ])"></div>
        <span
                class="site-link site-link--alt order-notification__btn-i"
                @click="$emit('close')"
        >
          {{ $t('common_cancel.localization_value.value') }}
        </span>

        <MainButton
                :value="$t('common_submit.localization_value.value')"
                class="order-notification__btn-i"
                v-on:click.native="submitForm"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../commonModals/Modal.vue'
  import MainButton from '../../../UI/buttons/MainButton/MainButton.vue'
  import TextareaDefault from '../../../UI/textarea/TextareaDefault/TextareaDefault.vue'
  import {validation} from "../../../../services/validation";
  import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";

  export default {
    name: "OrderNotificationPopup",
    components: {
      DefaultCheckbox,
      Modal,
      MainButton,
      TextareaDefault,
    },

    props: {
      orderNotificationData: Object,
      orderType: String,
      orderId: [Number, String],
      typeInfo: {
        type: Boolean,
        default: false,
      }
    },

    data() {
      return {
        txt: '',
        active: false,

        validation: {
          txt: false,
        },

        validationTranslate: {
          txt: '',
        },

        validationTxt: {
          txt: false,
        }
      }
    },



    mounted() {
      // this.txt = this.orderNotificationData.description
      // this.active = this.orderNotificationData.active === 1

      if(!this.orderNotificationData?.id) {
        return
      }

      this.$store.dispatch('getOrderNotification', this.orderNotificationData.id).then(response => {
        let data = this.getRespData(response)
        this.txt = data.description
        this.active = data.active
      })
    },

    methods: {

      submitForm() {
        let validationItems = {
          txt: this.txt,
        }

        let validationOptions = {
          txt: {type: []}
        }

        let validate = validation(validationItems, validationOptions)
        this.validation = validate.validation
        this.validationTranslate = validate.validationError

        if(validate.isValidate){
          let data = {
            'active': this.active ? 1 : 0,
            'description': this.txt,
            'order_type': this.orderType,
            'order_id': this.orderId,
          }

          if(!this.orderNotificationData?.id) {
            this.createNotification(data)
            return
          }

          this.updateNotification(data)


        }

      },

      createNotification(data) {
        this.$store.dispatch('createOrderNotification', data).then((response) => {

          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordChanged')
              this.$emit('reload')
              this.$emit('close')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },

      updateNotification(data) {
        this.$store.dispatch('updateOrderNotification', {data: data, id: this.orderNotificationData.id}).then((response) => {

          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordChanged')
              this.$emit('close')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },



    }
  }

</script>

<style lang="scss">
@import "../../../../scss/colors";

.order-notification{

  .modal-component__inner {
    max-width: 740px;
  }


  &__content{

  }

  &__txt{
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $borderBrown;
    margin-bottom: 30px;
  }


  &__alert{
    font-size: 12px;
    line-height: 14px;
    color: $black;
  }

  &__btn{
    display: flex;
    align-items: center;
  }

  &__btn-i{
    margin-right: 30px;

    &:last-child{
      margin-right: 0;
    }
  }
}

</style>
