var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-create__section"},[_c('div',{staticClass:"order-create__section-label section-label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'consolidations_sendFrom',
        ])}}}),_vm._v(" "+_vm._s(_vm.$t('consolidations_sendFrom.localization_value.value'))+" ")]),_c('div',{staticClass:"order-create__row custom-row"},[_c('div',{staticClass:"order-create__col custom-col",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'consolidations_warehouse',
          'consolidations_sender',
        ])}}}),_c('LargeSwitch',{attrs:{"activeValue":_vm.consolidation.data.sendTypes.warehouse,"inactiveValue":_vm.consolidation.data.sendTypes.sender,"labelActiveValue":_vm.$t('consolidations_warehouse.localization_value.value'),"labelInactiveValue":_vm.$t('consolidations_sender.localization_value.value'),"checkedItem":_vm.consolidation.data.sendType,"name":'orderCreateSwitch'},on:{"change":_vm.changeConsolidationCreateSwitch}})],1)]),(_vm.consolidation.data.sendType === _vm.consolidation.data.sendTypes.warehouse && _vm.warehousesOptions.length > 0)?_c('div',{staticClass:"order-create__row custom-row"},[_c('div',{staticClass:"order-create__col custom-col custom-col--50 custom-col--sm-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'consolidations_warehouse',
        ])}}}),_c('DefaultSelect',{attrs:{"options":_vm.warehousesOptions,"optionsLabel":'name',"otherValue":'currentTranslation',"clearable":false,"label":_vm.$t('consolidations_warehouse.localization_value.value'),"selected":_vm.consolidation.data.warehouse},on:{"change":(val) => {_vm.consolidation.setWarehouse(val)}}})],1)]):_vm._e(),(_vm.consolidation.data.sendType === _vm.consolidation.data.sendTypes.sender)?_c('PersonalInformation',{attrs:{"consolidation":_vm.consolidation}}):_vm._e(),(_vm.consolidation.data.sendType === _vm.consolidation.data.sendTypes.sender)?_c('AddressBlock',{attrs:{"consolidation":_vm.consolidation}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }