<template>
  <div class="fragment">
    <div class="section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['consolidations_scannerInKiev'])"></div>
      {{ $t('consolidations_scannerInKiev.localization_value.value') }}
    </div>

    <div class="custom-row">
      <div class="custom-col custom-col--50 custom-col--md-100">
        <div class="section-label">
          <DefaultInput
              :label="$t('fba_scannerHere.localization_value.value')"
              :type="'text'"
              v-model="scan"
              @change="changeScanner"
          />
        </div>
      </div>
    </div>


    <div class="custom-row order-admin-products">
      <div class="custom-col" v-if="consolidation.data.productsForCellArray.length > 0">

        <div class="site-table-wrap table-small"
             v-if="!isMobileFunc()"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['productForCell_scannedCount','fbm_ProductName','fba_Cells'])"></div>
          <table class="site-table">
            <thead>
            <tr>
              <th style="width: 20px;"></th>
              <th style="width: 100px;">{{$t('productForCell_scannedCount.localization_value.value')}}</th>
              <th>{{ $t('fbm_ProductName.localization_value.value') }}</th>
            </tr>
            </thead>
            <tbody>

            <tr
                v-for="(item, index) in consolidation.data.productsForCellArray"
                :key="index"
            >
              <td>
                <ProductCheckedBlock
                    :item="item"
                    :type="'scannedInKievCount'"
                    @changeProductChecked="(val) => changeProductChecked(val, item)"
                />
              </td>
              <td v-bind:class="{
                'red-color' : item.data.scannedInKievCount !== item.data.productCount,
                'green-color' : item.data.scannedInKievCount === item.data.productCount
              }">
                {{item.data.scannedInKievCount}} / {{item.data.productCount}}
              </td>
              <td>
                <div class="table-row ellipsis max-200">
                  <ValueHelper
                      :value="item.data.ProformProduct"
                      :deep="'product.productObject.name_for_fbm'"
                  />
                </div>
              </td>


            </tr>

            </tbody>
          </table>
        </div>

        <div class="site-table-mobile"
             v-if="isMobileFunc()"
        >
          <div class="site-table-mobile__inner custom-row">
            <div class="custom-col custom-col--50 custom-col--sm-100"
                 v-for="(item, index) in consolidation.data.productsForCellArray"
                 :key="index"
            >
              <div class="site-table-mobile__item bg--main-bg">
                <div class="site-table-mobile__head">
                  <ProductCheckedBlock
                      :item="item"
                      @changeProductChecked="(val) => changeProductChecked(val, item)"
                  />
                  <ValueHelper
                      :value="item.data.ProformProduct"
                      :deep="'product.productObject.name_for_fba'"
                  />
                </div>
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col">
                    {{$t('productForCell_scannedCount.localization_value.value')}}
                    <span v-bind:class="{
                      'red-color' : item.data.scannedInKievCount !== item.data.productCount,
                      'green-color' : item.data.scannedInKievCount === item.data.productCount
                    }">
                      {{item.data.scannedInKievCount}} / {{item.data.productCount}}
                    </span>
                  </div>
                </div>
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col">
                    {{ $t('fbm_ProductName.localization_value.value') }}
                    <span v-bind:class="{
                      'red-color' : item.data.scannedInKievCount !== item.data.productCount,
                      'green-color' : item.data.scannedInKievCount === item.data.productCount
                    }">
                      <ValueHelper
                          :value="item.data.ProformProduct"
                          :deep="'product.productObject.name_for_fbm'"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>



</template>

<script>
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import ProductCheckedBlock
  from "@/components/modules/OrdersFBAModule/components/components/chunks/ProductCheckedBlock/ProductCheckedBlock";
import {mixinDetictingMobile} from "@/mixins/mobileFunctions";
import {consolidationMixinHelper} from "@/mixins/consolidationMixins/consolidationMixinHelper";
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
export default {
  name: "ScannerInKiev",
  components: {DefaultInput, ProductCheckedBlock, ValueHelper},

  mixins: [mixinDetictingMobile, consolidationMixinHelper],

  props: {
    consolidation: Object,
  },

  data() {
    return {
      scan: '',
    }
  },

  methods: {

    changeProductChecked(checked, item) {
      if(checked) {
        item.data.scannedInKievCount = item.data.productCount
      } else {
        item.data.scannedInKievCount = 0
      }
    },

    changeScanner(val) {
      let added = false
      if (val.length > 0) {
        this.consolidation.data.productsForCellArray.map(item => {
          if (added) return //|| item.data.scannedInKievCount === item.data.productCount
          if (item.data.ProformProduct.product.productObject.upc_code === val) {
            item.data.scannedInKievCount++
            added = true
          }
        })
      }

      this.scan = ''
    },

  }

}
</script>

<style scoped lang="scss">
@import "../../../../../../../../scss/colors";

.order-admin-products{
  .red-color{
    color: $red
  }
  .green-color{
    color: $green
  }
}

.onlyPrintable{
  display: none;
}
</style>