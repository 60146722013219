<template>
  <div class="fragment">
    <div class="order-create__section-label section-label"
         v-if="!checkIfUserIsFromBaltic()"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'consolidations_shippingCompany',
          ])"></div>
      {{$t('consolidations_shippingCompany.localization_value.value')}}

      <v-popover
          class="site-tooltip"
          :disabled="!tooltipAddProductActive"
          offset="5"
          placement="top"
          trigger="hover"

      >

        <TooltipBtn/>

        <template slot="popover">
          <p>
            <b>{{$t('consolidations_shippingCompany.localization_value.value')}}</b>
          </p>
          <p>
            {{$t('consolidations_shippingCompanyText.localization_value.value')}}
          </p>

        </template>
      </v-popover>
    </div>

    <div class="order-create__row custom-row">
      <div
          class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
          v-if="!checkIfUserIsFromBaltic()"
      >
        <div class="customs-info__search-input">
          <DefaultSelect
              :options="deliveryServices"
              :label="$t('consolidations_deliveryService.localization_value.value')"
              v-bind:class="{'ui-no-valid': consolidation.validation.deliveryService}"
              :errorTxt="$t(`${consolidation.validationTranslate.deliveryService}.localization_value.value`)"
              :error="consolidation.validation.deliveryService"
              :optionsLabel="'name'"
              :clearable="false"
              @change="changeDeliveryService"
              :selected="consolidation.data.deliveryService"
          />
        </div>
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100">
        <DefaultInput
            :label="$t('consolidations_trackingNumber.localization_value.value')"
            v-model="consolidation.data.trackingNumber"
        />
      </div>

    </div>
  </div>
</template>

<script>
import TooltipBtn from "../../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";

export default {
  name: "ShippingCompany",
  components: {
    DefaultSelect,
    DefaultInput,
    TooltipBtn
  },

  props: {
    consolidation: Object,
    deliveryServices: Array
  },

  data() {
    return {
      tooltipAddProductActive: true,
    }
  },

  methods: {
    changeDeliveryService(val) {
      this.consolidation.setDeliveryService(val)
    },

    checkIfUserIsFromBaltic() {
      if((this.isBaltic && !this.isAdmin) ||
          (this.isAdmin && this.isBalticByUserData(this.consolidation.data.User?.user?.user_personal_contact?.country_group))) {
        return true
      }
    },
  }
}
</script>

<style scoped>

</style>