<template>
  <div class="custom-row" v-if="isAdmin">
    <div class="custom-col custom-col--33 custom-col--md-100">
      <UserSelect
          :validationUser="consolidation.data.User.validation.userId"
          :validationTranslateUser="consolidation.data.User.validationTranslate.userId"
          :userSelected="consolidation.data.User.user"
          :typeSelect="'users'"
          :userEmail="true"
          @changeUser="changeUser"
      />
    </div>
  </div>
</template>

<script>
import UserSelect from "../../../../../../coreComponents/UserSelect/UserSelect";
export default {
  name: "SelectUserBlock",
  components: {
    UserSelect
  },

  props: {
    consolidation: Object,
  },

  methods: {
    changeUser(val) {
      this.consolidation.data.User.setUser(val)
      this.$emit('userChanged')
    }
  }
}
</script>

<style scoped>

</style>