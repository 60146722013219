<template>
  <div class="fragment" v-if="consolidation.data.User.userId">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'consolidations_address',
          'common_city',
          'common_region',
          'common_country',
          'common_zipCode',
          ])"></div>
      {{$t('consolidations_address.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100">
        <DefaultInput
            :label="$t('consolidations_address.localization_value.value')"
            :type="'text'"
            :disabled="true"
            :value="consolidation.data.User.user.use_alternative_address ? consolidation.data.User.user.user_alternative_contact.address :
            consolidation.data.User.user.user_personal_contact.address"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100">
        <DefaultInput
            :label="$t('common_city.localization_value.value')"
            :type="'text'"
            :disabled="true"
            :value="consolidation.data.User.user.use_alternative_address ? consolidation.data.User.user.user_alternative_contact.city :
            consolidation.data.User.user.user_personal_contact.city"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100">
        <DefaultInput
            :label="$t('common_region.localization_value.value')"
            :type="'text'"
            :disabled="true"
            :value="consolidation.data.User.user.use_alternative_address ? consolidation.data.User.user.user_alternative_contact.region.name :
            consolidation.data.User.user.user_personal_contact.region.name"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100">
        <DefaultInput
            :label="$t('common_country.localization_value.value')"
            :type="'text'"
            :disabled="true"
            :value="consolidation.data.User.user.use_alternative_address ? consolidation.data.User.user.user_alternative_contact.country.name :
            consolidation.data.User.user.user_personal_contact.country.name"
        />
      </div>
      <div class="order-create__col custom-col custom-col--16  custom-col--md-25 custom-col--sm-50">
        <DefaultInput
            :label="$t('common_zipCode.localization_value.value')"
            :type="'text'"
            :disabled="true"
            :value="consolidation.data.User.user.use_alternative_address ? consolidation.data.User.user.user_alternative_contact.zip :
            consolidation.data.User.user.user_personal_contact.zip"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";

export default {
  name: "AddressBlock",

  components: {
    DefaultInput
  },

  props: {
    consolidation: Object,
  },

  data() {
    return {

    }
  }
}
</script>

<style scoped>

</style>