var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.consolidation.data.User.userId)?_c('div',{staticClass:"fragment"},[_c('div',{staticClass:"order-create__section-label section-label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'consolidations_personalInfo',
        ])}}}),_vm._v(" "+_vm._s(_vm.$t('consolidations_personalInfo.localization_value.value'))+" ")]),_c('div',{staticClass:"order-create__row custom-row"},[_c('div',{staticClass:"order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'consolidations_customerName',
        ])}}}),_c('DefaultInput',{class:{'ui-no-valid': _vm.consolidation.validation.senderContactName},attrs:{"label":_vm.$t('consolidations_customerName.localization_value.value'),"type":'text',"errorTxt":_vm.$t(`${_vm.consolidation.validationTranslate.senderContactName}.localization_value.value`),"error":_vm.consolidation.validation.senderContactName},model:{value:(_vm.consolidation.data.senderContactName),callback:function ($$v) {_vm.$set(_vm.consolidation.data, "senderContactName", $$v)},expression:"consolidation.data.senderContactName"}})],1),_c('div',{staticClass:"order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'consolidations_phoneNum',
        ])}}}),_c('DefaultInput',{attrs:{"label":_vm.$t('consolidations_phoneNum.localization_value.value'),"type":'text',"value":_vm.consolidation.data.User.user.user_personal_contact.phone,"disabled":true}})],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }