<template>
  <div class="order-create__section">
    <div class="order-create__title-horizontal"
         v-if="Object.keys(labelPrice).length > 0 || $store.getters.getExpressLoadingPrice"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
										'express_Shipping',
									])"></div>
      <TitleHorizontal
          :value="$t('express_Shipping.localization_value.value')"
      />
    </div>

    <div class="pt-2 pb-3" v-if="$store.getters.getExpressLoadingPrice">
      <DotsShadowPreloader/>
    </div>

    <template v-if="Object.keys(labelPrice).length > 0 && !$store.getters.getExpressLoadingPrice">
      <div class="date-time"
           v-if="_.has(labelPrice, 'estimated_time_of_arrival') && labelPrice.estimated_time_of_arrival"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['express_EstimatedTimeOfArrival',])"></div>
        {{$t('express_EstimatedTimeOfArrival.localization_value.value')}}
        {{labelPrice.estimated_time_of_arrival | moment("DD/MM/YYYY H:mm:ss")}}
      </div>

      <LabelPrice
          :withVariantPrice="false"
          :labelPrice="labelPrice"
          :bigCards="true"
          :dearCustomer="false"
          :totalShippingCostTranslate="'common_TotalShippingCostExpress'"
      />
      <!--:costDelivery="ex.express.type !== FEDEX_DELIVERY_SERVICE.id"-->


    </template>
  </div>
</template>

<script>
import LabelPrice from "../../../../../../coreComponents/LabelPrice/LabelPrice";
import DotsShadowPreloader from "../../../../../../coreComponents/preloaders/DotsShadowPreloader/DotsShadowPreloader";
import TitleHorizontal from "../../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
export default {
  name: "ShowLabelPriceBlock",
  components: {
    TitleHorizontal,
    DotsShadowPreloader,
    LabelPrice
  },

  props: {
    labelPrice: Object,
  }
}
</script>

<style scoped>

</style>