var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.consolidation.data.User.userId)?_c('div',{staticClass:"fragment"},[_c('div',{staticClass:"order-create__section-label section-label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'consolidations_address',
        'common_city',
        'common_region',
        'common_country',
        'common_zipCode',
        ])}}}),_vm._v(" "+_vm._s(_vm.$t('consolidations_address.localization_value.value'))+" ")]),_c('div',{staticClass:"order-create__row custom-row"},[_c('div',{staticClass:"order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"},[_c('DefaultInput',{attrs:{"label":_vm.$t('consolidations_address.localization_value.value'),"type":'text',"disabled":true,"value":_vm.consolidation.data.User.user.use_alternative_address ? _vm.consolidation.data.User.user.user_alternative_contact.address :
          _vm.consolidation.data.User.user.user_personal_contact.address}})],1),_c('div',{staticClass:"order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"},[_c('DefaultInput',{attrs:{"label":_vm.$t('common_city.localization_value.value'),"type":'text',"disabled":true,"value":_vm.consolidation.data.User.user.use_alternative_address ? _vm.consolidation.data.User.user.user_alternative_contact.city :
          _vm.consolidation.data.User.user.user_personal_contact.city}})],1),_c('div',{staticClass:"order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"},[_c('DefaultInput',{attrs:{"label":_vm.$t('common_region.localization_value.value'),"type":'text',"disabled":true,"value":_vm.consolidation.data.User.user.use_alternative_address ? _vm.consolidation.data.User.user.user_alternative_contact.region.name :
          _vm.consolidation.data.User.user.user_personal_contact.region.name}})],1),_c('div',{staticClass:"order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"},[_c('DefaultInput',{attrs:{"label":_vm.$t('common_country.localization_value.value'),"type":'text',"disabled":true,"value":_vm.consolidation.data.User.user.use_alternative_address ? _vm.consolidation.data.User.user.user_alternative_contact.country.name :
          _vm.consolidation.data.User.user.user_personal_contact.country.name}})],1),_c('div',{staticClass:"order-create__col custom-col custom-col--16 custom-col--md-25 custom-col--sm-50"},[_c('DefaultInput',{attrs:{"label":_vm.$t('common_zipCode.localization_value.value'),"type":'text',"disabled":true,"value":_vm.consolidation.data.User.user.use_alternative_address ? _vm.consolidation.data.User.user.user_alternative_contact.zip :
          _vm.consolidation.data.User.user.user_personal_contact.zip}})],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }