<template>
  <modal
      @close="$emit('close')"
      :stickyFooter="stickyFooter"
      class="custom-popup-modal custom-popup product-editing"
  >
    <template slot="header">
      <div v-if="!popupHeaderName"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_ProductEditing'])"></div>
        {{ $t(`common_ProductEditing.localization_value.value`) }}
      </div>
      <div v-else
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([popupHeaderName])"></div>
        {{ $t(`${popupHeaderName}.localization_value.value`) }}
      </div>

    </template>
    <template slot="body">
      <div class="custom-popup__content">

        <TextareaDefault
            class="mb-3"
            style="max-height: 78px; min-height: 78px;"
            v-if="typeMoveProductCell && _.has(localProductForCell.data.ProformProduct.product.productObject, 'name_for_fbm')"
            :label="$t('fbm_ProductName.localization_value.value')"
            :disabled="true"
            :forceMinHeight="true"
            :value="localProductForCell.data.ProformProduct.product.productObject.name_for_fbm"
        />

        <template v-if="allowScan">
          <div class="custom-row">
            <div class="custom-col" v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['consolidations_scanCell', 'refillProducts_scanToCell'])"></div>
              <div class="mb-3" v-if="scanToCell && scanToCell.data.cellSelected">{{$t('refillProducts_scanToCell.localization_value.value')}}:
                <b class="white-space-nowrap">{{scanToCell.data.cellSelected.combo_name}}</b></div>
              <DefaultInput
                  :label="$t('consolidations_scanCell.localization_value.value')"
                  :type="'text'"
                  style="width: 180px;"
                  v-model="scan"
                  @change="(val) => {changeScanner(val)}"
              />
            </div>
          </div>
        </template>

        <template v-if="!typeMoveProductCell">
          <SelectProductBlock
              v-if="isOutboundType"
              :item="localProductForCell.data.Product"
              :userId="parseInt(user.userId)"
              :productLabel="'name_for_fbm'"
              :price="price"
              :count="count"
              :description="description"
              :hsCode="hsCode"
              :typePopup="true"
              :canRemoveProduct="false"
              :getProductsWithCellsData="true"
              @removeProduct="false"
              @changeProductName="(item, val) => changeProductWithCells(item, val)"
              @changeProductQuantity="changeProductQuantity"
          />

          <SelectProformProductBlock
              v-if="isInboundType"
              :item="localProductForCell.data.ProformProduct"
              :productLabel="'name_for_fba'"
              :userId="parseInt(user.userId)"
              :getProductsWithCellsData="true"
              :disabledProformProduct="disabledProformProduct"
              @changeProductProformSelect="changeProformProduct"
          />
        </template>


        <div class="custom-row" v-if="showProductCounters">

          <div class="order-create__product-col custom-col custom-col--33"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['productForCell_scannedCount',])"></div>
            <InputQuantity
                :value="localProductForCell.data.scannedCount"
                :label="$t('productForCell_scannedCount.localization_value.value')"
                v-bind:class="{'ui-no-valid': localProductForCell.validation.scannedCount}"
                :error="localProductForCell.validation.scannedCount"
                @change="val => localProductForCell.data.scannedCount = val"
            />
          </div>
          <div class="order-create__product-col custom-col custom-col--33"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['productForCell_productCount',])"></div>
            <InputQuantity
                :value="localProductForCell.data.productCount"
                :label="$t('productForCell_productCount.localization_value.value')"
                v-bind:class="{'ui-no-valid': localProductForCell.validation.productCount}"
                :error="localProductForCell.validation.productCount"
                @change="changeProductCount"
            />
          </div>
          <div class="order-create__product-col custom-col custom-col--33"
               v-if="damaged"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['productForCell_damagedCount',])"></div>
            <InputQuantity
                :value="localProductForCell.data.damagedCount"
                :label="$t('productForCell_damagedCount.localization_value.value')"
                v-bind:class="{'ui-no-valid': localProductForCell.validation.damagedCount}"
                :error="localProductForCell.validation.damagedCount"
                @change="val => localProductForCell.data.damagedCount = val"
            />
          </div>

        </div>

        <template>
          <!--Товары без ячеек-->
          <div class="section-label mb-2"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['productForCell_GoodsWithoutCells'])"></div>
            {{ $t('productForCell_GoodsWithoutCells.localization_value.value') }}
          </div>

          <div class="custom-row">
            <div class="custom-col custom-col--50 custom-col-md--100">

            </div>

            <div class="custom-col custom-col--25 custom-col-md--100"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['fba_InWarehouse'])"></div>
              <DefaultInput
                  :label="$t(`fba_InWarehouse.localization_value.value`)"
                  v-model="localProductForCell.data.withoutCellDeclaredCounts"
                  :disabled="true"
              />
              <!--            v-bind:class="{'ui-no-valid': itemProductCell.validation.storageCount}"-->
              <!--            :errorTxt="$t(`${itemProductCell.validationTranslate.storageCount}.localization_value.value`)"-->
              <!--            :error="itemProductCell.validation.storageCount"-->
            </div>
            <div class="custom-col custom-col--25 custom-col-md--100"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['fba_Send'])"></div>
              <DefaultInput
                  :label="$t(`fba_Send.localization_value.value`)"
                  v-model="localProductForCell.data.withoutCellEndCounts"
              />
              <!--:errorTxt="$t(`${itemProductCell.validationTranslate.cellCount}.localization_value.value`)"-->
            </div>
          </div>
        </template>

        <template v-if="isOutboundType">

          <!--  USER CELLS   -->
          <template v-for="(itemProductCell, index) in localProductForCell.data.UserCellArray">
            <ProductForCellFields
                :key="index"
                :itemProductCell="itemProductCell"
                :selectName="'productForCell_user'"
                :inCellProductCountName="'fba_InCell'"
                :sendProductCountName="'fba_Send'"
            />
          </template>

          <!--  MIXED CELLS   -->

          <template v-for="(itemProductCell, index) in localProductForCell.data.MixedCellArray">
            <ProductForCellFields
                :key="index+'mix'"
                :itemProductCell="itemProductCell"
                :selectName="'productForCell_MixedCell'"
                :inCellProductCountName="'fba_InCell'"
                :sendProductCountName="'fba_Send'"
            />
          </template>

          <!--  FREE CELLS   -->

          <template v-for="(itemProductCell, index) in localProductForCell.data.FreeCellArray">
            <ProductForCellFields
                :key="index+'free'"
                :itemProductCell="itemProductCell"
                :selectName="'productForCell_freeCell'"
                :inCellProductCountName="'fba_InCell'"
                :sendProductCountName="'fba_Send'"
            />
          </template>

        </template>


        <!--MixedCellArray-->


        <template v-if="isInboundType">
          <!--  USER CELLS   -->

          <!--Ячейки с товаром-->
          <div class="section-label mb-2"
               v-if="localProductForCell.data.UserProductCellArray.length"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['productForCell_CellsWithGoods'])"></div>
            {{ $t('productForCell_CellsWithGoods.localization_value.value') }}
          </div>

          <template v-for="(itemProductCell, index) in localProductForCell.data.UserProductCellArray">
            <ProductForCellFields
                v-if="(checkForSeveralCellsWithProducts(localProductForCell.data.UserProductCellArray) && checkCellHasProducts(itemProductCell))
                || (!checkForSeveralCellsWithProducts(localProductForCell.data.UserProductCellArray) && index === 0) || typeMoveProductCell"
                :key="index"
                :itemProductCell="itemProductCell"
                :selectName="'productForCell_user'"
                :inCellProductCountName="'fba_InCell'"
                :sendProductCountName="'fba_Send'"
                :disabledSelect="typeMoveProductCell || (checkForSeveralCellsWithProducts(localProductForCell.data.UserMixedProductCellArray) && !typeMoveProductCell)"
                :mix="false"
            />
          </template>

          <!--  MIXED CELLS   -->

          <!--Ячейки микс с продуктами-->
          <div class="section-label mb-2"
               v-if="localProductForCell.data.UserMixedProductCellArray.length"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['productForCell_CellsMixWithGoods'])"></div>
            {{ $t('productForCell_CellsMixWithGoods.localization_value.value') }}
          </div>

          <template v-for="(itemProductCell, index) in localProductForCell.data.UserMixedProductCellArray">
            <ProductForCellFields
                v-if="(checkForSeveralCellsWithProducts(localProductForCell.data.UserMixedProductCellArray) && checkCellHasProducts(itemProductCell))
                || (!checkForSeveralCellsWithProducts(localProductForCell.data.UserMixedProductCellArray) && index === 0) || typeMoveProductCell"
                :key="index+'mix'"
                :itemProductCell="itemProductCell"
                :selectName="'productForCell_MixedCell'"
                :inCellProductCountName="'fba_InCell'"
                :sendProductCountName="'fba_Send'"
                :disabledSelect="typeMoveProductCell || (checkForSeveralCellsWithProducts(localProductForCell.data.UserMixedProductCellArray) && !typeMoveProductCell)"
                :mix="true"
            />
          </template>

          <!--  USER CELLS   -->

          <!--Ячейки пользователя-->
          <div class="section-label mb-2"
               v-if="localProductForCell.data.UserCellArray.length"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['productForCell_UserCells'])"></div>
            {{ $t('productForCell_UserCells.localization_value.value') }}
          </div>
          <template v-for="(itemProductCell, index) in localProductForCell.data.UserCellArray">
            <ProductForCellFields
                v-if="(checkForSeveralCellsWithProducts(localProductForCell.data.UserCellArray) && checkCellHasProducts(itemProductCell))
                || (!checkForSeveralCellsWithProducts(localProductForCell.data.UserCellArray) && index === 0) || typeMoveProductCell"
                :key="index+'user'"
                :itemProductCell="itemProductCell"
                :selectName="'productForCell_user'"
                :inCellProductCountName="'fba_InCell'"
                :sendProductCountName="'fba_Send'"
                :disabledSelect="typeMoveProductCell"
            />
          </template>


          <!--  FREE CELLS   -->

          <!--Ячейки свободные-->
          <div class="section-label mb-2"
               v-if="localProductForCell.data.FreeCellArray.length"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['productForCell_FreeCells'])"></div>
            {{ $t('productForCell_FreeCells.localization_value.value') }}
          </div>
          <template v-for="(itemProductCell, index) in localProductForCell.data.FreeCellArray">
            <ProductForCellFields
                v-if="(checkForSeveralCellsWithProducts(localProductForCell.data.FreeCellArray) && checkCellHasProducts(itemProductCell))
                || (!checkForSeveralCellsWithProducts(localProductForCell.data.FreeCellArray) && index === 0) || typeMoveProductCell"
                :key="index+'free'"
                :itemProductCell="itemProductCell"
                :selectName="'productForCell_freeCell'"
                :inCellProductCountName="'fba_InCell'"
                :sendProductCountName="'fba_Send'"
            />
          </template>

          <!--  FREE MIXED CELLS   -->

          <!--Ячейки микс-->
          <div class="section-label mb-2"
               v-if="localProductForCell.data.MixedCellArray.length"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['productForCell_MixedCells'])"></div>
            {{ $t('productForCell_MixedCells.localization_value.value') }}
          </div>
          <template v-for="(itemProductCell, index) in localProductForCell.data.MixedCellArray">
            <ProductForCellFields
                v-if="(checkForSeveralCellsWithProducts(localProductForCell.data.MixedCellArray) && checkCellHasProducts(itemProductCell))
                || (!checkForSeveralCellsWithProducts(localProductForCell.data.MixedCellArray) && index === 0) || typeMoveProductCell"
                :key="index+'mixed'"
                :itemProductCell="itemProductCell"
                :selectName="'productForCell_MixedCell'"
                :inCellProductCountName="'fba_InCell'"
                :sendProductCountName="'fba_Send'"
            />
          </template>
        </template>


      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn align-items-center d-flex flex-wrap">
        <span
            class="site-link site-link--alt custom-popup__btn-i mr-4 mt-3 mb-3"
            @click="$emit('close')"
        >
        {{ $t('common_cancel.localization_value.value') }}
        </span>

        <MainButton
            v-if="typeMoveProductCell"
            class="custom-popup__btn-i "
            :value="$t('common_save.localization_value.value')"
            @click.native="saveMoveProductCell"
        />
        <MainButton
            v-else
            class="custom-popup__btn-i "
            :value="$t('common_save.localization_value.value')"
            @click.native="saveLocalChanges"
        />
      </div>
    </template>
  </modal>
</template>

<script>

import SelectProductBlock from "../../SelectProductBlock/SelectProductBlock";
import Modal from "../../../commonModals/Modal";
import MainButton from "../../../UI/buttons/MainButton/MainButton";
import {productForCellMixin} from "../../../../mixins/productMixins/productForCellMixin";
import {routeFilter} from "../../../../mixins/routeFilterMixins/routeFilter";
import {ProductForCell} from "../../../globalModels/ProductForCell";
import InputQuantity from "../../../UI/inputs/InputQuantity/InputQuantity";
import {proformMixins} from "../../../../mixins/proformMixins/proformMixins";
import SelectProformProductBlock from "../../SelectProformProductBlock/SelectProformProductBlock";
import {ordersInboundProductMixin} from "../../../../mixins/ordersInbound/ordersInboundProductMixin";
import ProductForCellFields from "./ProductForCellFields/ProductForCellFields";
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
import TextareaDefault from "@/components/UI/textarea/TextareaDefault/TextareaDefault";


export default {
  name: "ProductForCellPopup",

  components: {
    TextareaDefault,
    DefaultInput,
    ProductForCellFields,
    SelectProformProductBlock,
    InputQuantity,
    SelectProductBlock,
    Modal,
    MainButton,
  },

  mixins: [
    productForCellMixin,
    routeFilter,
    proformMixins,
    ordersInboundProductMixin,
  ],

  props: {
    orderType: {
      type: String,
      default: 'outbound'
    },
    user: Object,
    productForCellItem: Object,
    productsForCellArray: Array,
    arrayIndex: Number,
    stickyFooter: {
      type: Boolean,
      default: false
    },
    disabledProformProduct: {
      type: Boolean,
      default: true
    },
    typeMoveProductCell: {
      type: Boolean,
      default: false
    },
    popupHeaderName: {
      type: String,
      default: null
    },
    showProductCounters: {
      type: Boolean,
      default: true
    },

    damaged: {
      type: Boolean,
      default: true
    },

    price: {
      type: Boolean,
      default: true,
    },
    count: {
      type: Boolean,
      default: true,
    },
    description: {
      type: Boolean,
      default: true,
    },
    hsCode: {
      type: Boolean,
      default: true,
    },
    allowScan: {
      type: Boolean,
      default: false,
    },
    scanToCellId: {
      type: Number,
      default: 0,
    }
  },

  data() {
    return {
      counterInitCells: false,

      localProductForCell: new ProductForCell(),
      scan: '',
      scanToCell: null,
    }
  },

  computed: {
    isOutboundType() {
      return this.orderType === 'outbound'
    },
    isInboundType() {
      return this.orderType === 'inbound'
    },
  },

  mounted() {
    this.localProductForCell = this._.cloneDeep(this.productForCellItem)

    if (this.scanToCellId && this.scanToCellId > 0) {
      this.initScanToCell()
    }
    // this.initCells(this.localProductForCell, this.isOutboundType)
  },

  methods: {

    changeProductWithCells(item, val) {
      this.changeProductName(item, val)

      // set item product count declared product
      item.product.count = this.localProductForCell.data.productCount

      if (this.isOutboundType) {
        this.setCellsInProductForCell(val, this.localProductForCell)
      }

      if (this.isInboundType) {
        this.setCellsInProductForCellInbound(val, this.localProductForCell)
      }

    },


    saveLocalChanges() {
      if (this.isOutboundType) {
        if (!this.localProductForCell.productValidation()) return
      }
      if (this.isInboundType) {
        if (!this.localProductForCell.proformProductValidation()) return
      }
      if (!this.localProductForCell.cellsArrayValidation()) return

      console.log(this.localProductForCell);

      this.productsForCellArray[this.arrayIndex] = this.localProductForCell

      this.$emit('saveChanges')
      this.$emit('close')
    },

    saveMoveProductCell() {
      if (!this.localProductForCell.productCountForMoveValidation()) {
        this.openNotify('error', 'common_defineProductNotEqualMove')
        return
      }

      this.productsForCellArray[this.arrayIndex] = this.localProductForCell

      this.$emit('moveProductCell')
    },

    changeProductCount(val) {
      this.localProductForCell.data.productCount = val
      this.localProductForCell.data.Product.product.count = val
      this.localProductForCell.data.ProformProduct.product.itemsQuantity = val
    },


    changeProformProduct(val) {
      this.changeProductObject(this.localProductForCell.data.ProformProduct, val, this.localProductForCell)
    },

    // checkValidProduct(){
    //   if(this.FBM.OrderFBMProductValidation(this.productForCell)){
    //     this.$emit('close')
    //   }
    // }

    changeScanner() {

      if (!(this.scan.length > 0 && this.scan.indexOf('-') > 0 && this.scan.toLowerCase().indexOf('cell') === 0)) {
        this.scan = ''
        return
      }

      let cellId = this.scan.split('-')[1]
      cellId = parseInt(cellId)

      let
          userProductCellArray = this.localProductForCell.data.UserProductCellArray,
          userMixedProductCellArray = this.localProductForCell.data.UserMixedProductCellArray,
          userCellArray = this.localProductForCell.data.UserCellArray,
          mixedCellArray = this.localProductForCell.data.MixedCellArray,
          freeCellArray = this.localProductForCell.data.FreeCellArray

      userProductCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id === +cellId) {
          this.scanToCell.data.cellCount += cellItem.data.cellCount
          cellItem.data.cellCount = 0
        }
      })

      userMixedProductCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id === +cellId) {
          this.scanToCell.data.cellCount += cellItem.data.cellCount
          cellItem.data.cellCount = 0
        }
      })

      userCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id === +cellId) {
          this.scanToCell.data.cellCount += cellItem.data.cellCount
          cellItem.data.cellCount = 0
        }
      })

      mixedCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id === +cellId) {
          this.scanToCell.data.cellCount += cellItem.data.cellCount
          cellItem.data.cellCount = 0
        }
      })

      freeCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id === +cellId) {
          this.scanToCell.data.cellCount += cellItem.data.cellCount
          cellItem.data.cellCount = 0
        }
      })

      this.scan = ''
    },

    checkForSeveralCellsWithProducts(cellArray) {
      let counter = 0
      cellArray.forEach(item => {
        if (+item?.data?.cellCount > 0 || item?.data?.storageCount) {
          counter++
        }
      })

      return counter > 1
    },

    checkCellHasProducts(cell) {
      return +cell?.data?.cellCount > 0 || +cell?.data?.storageCount > 0
    },

    initScanToCell() {
      let
          userProductCellArray = this.localProductForCell.data.UserProductCellArray,
          userMixedProductCellArray = this.localProductForCell.data.UserMixedProductCellArray,
          userCellArray = this.localProductForCell.data.UserCellArray,
          mixedCellArray = this.localProductForCell.data.MixedCellArray,
          freeCellArray = this.localProductForCell.data.FreeCellArray

      userProductCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id === +this.scanToCellId) {
          this.scanToCell = cellItem
        }
      })
      userMixedProductCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id === +this.scanToCellId) {
          this.scanToCell = cellItem
        }
      })
      userCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id === +this.scanToCellId) {
          this.scanToCell = cellItem
        }
      })
      mixedCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id === +this.scanToCellId) {
          this.scanToCell = cellItem
        }
      })
      freeCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id === +this.scanToCellId) {
          this.scanToCell = cellItem
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
