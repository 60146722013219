<template>
  <div class="order-create__product custom-row"
       @mouseover="isHovering2 = canRemoveProduct"
       @mouseout="isHovering2 = false"
       :class="{hover: isHovering2}"
  >
    <div class="order-create__product-col custom-col  custom-col--md-100 position-relative d-flex"
         v-bind:class="{
         'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate(),
         'custom-col--66': !typePopup,
         'custom-col--83': largeNameColumn
         }">
      <div class="admin-edit" @click="editTranslate(['fbm_ProductName', 'fbm_IncludesProducts'])"></div>
      <div class="order-create__product-delete" v-if="canRemoveProduct">
					<span @click="$emit('removeProduct')">
						<DeleteProductBtn/>
					</span>
      </div>
      <DefaultSelect
          v-if="!autoloadSelect"
          class="w-100"
          :label="$t('fbm_ProductName.localization_value.value')"
          :options="optionsProducts"
          :otherValue="'product'"
          :caption="productCaption"
          :optionsLabel="productLabel"
          v-bind:class="{'ui-no-valid': item.validation.productObject}"
          :errorTxt="serverError ? item.validationTxt.productObject : $t(`${item.validationTranslate.productObject}.localization_value.value`)"
          :error="item.validation.productObject"
          :selected="item.product.productObject"
          :disabled="disabled"
          @change="(val) => $emit('changeProductName', item, val)"
      />
      <AutoloadSelect
          v-else
          class="w-100"
          :label="$t('fbm_ProductName.localization_value.value')"
          :options="optionsProducts"
          :otherValue="'product'"
          :caption="productCaption"
          :optionsLabel="productLabel"
          v-bind:class="{'ui-no-valid': item.validation.productObject}"
          :errorTxt="serverError ? item.validationTxt.productObject : $t(`${item.validationTranslate.productObject}.localization_value.value`)"
          :error="item.validation.productObject"
          :selected="item.product.productObject"
          :disabled="disabled"
          @change="(val) => $emit('changeProductName', item, val)"
      />

    </div>
    <div class="order-create__product-col custom-col custom-col--md-100"
         v-if="count"
         v-bind:class="{
         'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate(),
         'custom-col--16': !typePopup && !largeCountColumn,
         'custom-col--50': typePopup,
         'custom-col--33': largeCountColumn
         }">
      <div class="admin-edit" @click="editTranslate(['fbm_ItemsQty',])"></div>
      <InputQuantity
              :value="item.product.count"
              :label="$t('fbm_ItemsQty.localization_value.value')"
              v-bind:class="{'ui-no-valid': item.validation.count}"
              :errorTxt="serverError ? item.validationTxt.count : $t(`${item.validationTranslate.count}.localization_value.value`)"
              :error="item.validation.count"
              :disabled="disabledAllFields"
              @change="val => $emit('changeProductQuantity', item, val)"
      />
    </div>
    <div class="order-create__product-col custom-col custom-col--md-100"
         v-if="price"
         v-bind:class="{
         'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate(),
         'custom-col--16': !typePopup && !largeCountColumn,
         'custom-col--50': typePopup,
         'custom-col--33': largeCountColumn
         }">
      <div class="admin-edit" @click="editTranslate(['fbm_ProductPrice',])"></div>
      <InputSum
              :label="$t('fbm_ProductPrice.localization_value.value')"
              :icoType="'dollar'"
              :numeric="true"
              :placeholder="'0'"
              v-bind:class="{'ui-no-valid': item.validation.price}"
              :errorTxt="$t(`${item.validationTranslate.price}.localization_value.value`)"
              :error="item.validation.price"
              :disabled="disabledAllFields"
              v-model="item.product.price"
      />
    </div>
    <div class="order-create__product-col custom-col"
         v-if="description"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fbm_ProductDescription',])"></div>
      <DefaultInput
              :label="$t('fbm_ProductDescription.localization_value.value')"
              v-bind:class="{'ui-no-valid': item.validation.description}"
              :errorTxt="$t(`${item.validationTranslate.description}.localization_value.value`)"
              :error="item.validation.description"
              :autocompleteOff="true"
              :disabled="disabledAllFields"
              :isOnlyLetters="true"
              v-model="item.product.description"
      />
    </div>
    <div class="order-create__product-col custom-col"
         v-if="hsCode"
         v-bind:class="{
         'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate(),
         'custom-col--25': !typePopup && !largeCountColumn,
         'custom-col--50': typePopup,
         'custom-col--33': largeCountColumn
         }">
      <div class="admin-edit" @click="editTranslate(['fbm_ProductHsCode',])"></div>
      <DefaultInput
              :label="$t('fbm_ProductHsCode.localization_value.value')"
              v-bind:class="{'ui-no-valid': item.validation.hsCode}"
              :errorTxt="$t(`${item.validationTranslate.hsCode}.localization_value.value`)"
              :error="item.validation.hsCode"
              :autocompleteOff="true"
              :disabled="disabledAllFields"
              v-model="item.product.hsCode"
      />
    </div>
    <div class="order-create__product-col custom-col custom-col--16 custom-col--md-0 mb-0"></div>
  </div>
</template>

<script>
  import InputQuantity from "../../UI/inputs/InputQuantity/InputQuantity";
  import DefaultSelect from "../../UI/selectiones/DefaultSelect/DefaultSelect";
  import DeleteProductBtn from "../Buttons/DeleteProductBtn/DeleteProductBtn";
  import InputSum from "../../UI/inputs/InputSum/InputSum";
  import DefaultInput from "../../UI/inputs/DefaultInput/DefaultInput";
  import AutoloadSelect from "@/components/UI/selectiones/AutoloadSelect/AutoloadSelect";
  export default {
    name: "SelectProductBlock",

    components: {
      AutoloadSelect,
      DefaultInput,
      InputSum,
      DeleteProductBtn,
      DefaultSelect,
      InputQuantity,
    },

    props: {
      item: Object,
      userId: Number,
      serverError: {
        type: Boolean,
        default: false,
      },
      autoloadSelect: {
        type: Boolean,
        default: false,
      },
      productLabel: String,
      price: {
        type: Boolean,
        default: true,
      },
      count: {
        type: Boolean,
        default: true,
      },
      description: {
        type: Boolean,
        default: true,
      },
      typePopup: {
        type: Boolean,
        default: false,
      },
      // largeCountColumn - fix html for FBA order => set pop-up
      largeCountColumn: {
        type: Boolean,
        default: false,
      },
      // largeNameColumn - fix html for FBA order => edit for admin
      largeNameColumn: {
        type: Boolean,
        default: false,
      },
      canRemoveProduct: {
        type: Boolean,
        default: true
      },
      productCaption: {
        type: String,
        default: null
      },
      withDraft: {
        type: Boolean,
        default: true
      },
      getProductsWithCellsData: {
        type: Boolean,
        default: false
      },
      getProductsDamage: {
        type: Boolean,
        default: false
      },
      hsCode: {
        type: Boolean,
        default: false
      },
      initialOptionsProducts: {
        type: Array,
        default: () => {return []}
      },
      byStorageId: {
        type: Number,
        default: null,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      disabledAllFields: {
        type: Boolean,
        default: false,
      },
    },

    watch: {
      initialOptionsProducts: function() {
        this.getProductsByUser()
      },

      loadUserAuthorizedData: function() {
        this.getProductsByUser()
      },

      userId() {
        if(this.isAdmin){
          this.getProductsByUser()
        }
      },
    },

    data() {
      return {
        isHovering:false,
        isHovering2:false,

        optionsProducts: [],
      }
    },

    mounted() {
      if(this.userId || this.loadUserAuthorizedData){
        this.getProductsByUser()
      }
    },

    methods: {
      getProductsByUser() {

        if(this.initialOptionsProducts.length > 0) {
          this.optionsProducts = this.initialOptionsProducts
          return
        }

        if(!this.userId && !this.$store.getters.getUserProfile?.id) return

        const query = new this.Query();
        let url = '?';
        let myQuery = query
          .for('posts')

        if(this.isAdmin){
          if (this.userId)
            myQuery.where('ProductUserId', this.userId)
          else
            myQuery.where('ProductUserId', this.$store.getters.getUserProfile.id)
        }


        if(!this.withDraft)
          myQuery.where('ProductInDraft', 0)

        if(this.getProductsDamage){
          // myQuery.where('withDamageInStorage', 1)
          myQuery.includes('itemsInStorage')
        }

        myQuery.sort(`ProductName`)
          // myQuery.where('ProductDamage', 1)

        if(this.byStorageId) {
          myQuery.where('byStorageId', this.byStorageId)
        }

        url = url + myQuery.limit(100000).page(parseInt(0)).url().split('?')[1]

        let typeFetch = 'fetchProducts'
        if(this.getProductsWithCellsData) {
          typeFetch = 'fetchProductsWithCells'
          url = url + `&user_id=${this.userId}`
        }

        this.$store.dispatch(typeFetch, url).then((response) => {
          if(this.getProductsWithCellsData) {
            this.optionsProducts = this.getRespData(response)
          } else {
            this.optionsProducts = this.getRespPaginateData(response)
          }
        })
        // this.$store.dispatch('fetchProducts', url)
      },
    },

  }
</script>

<style scoped>

</style>
