<template>
  <div class="delete-product-btn">
    <DeleteIconSVG/>
  </div>
</template>

<script>
  import DeleteIconSVG from '../../../../../public/img/UI-group/delete-icon.svg?inline'

  export default {
    name: "DeleteProductBtn",
    components: {
      DeleteIconSVG,
    }
  }
</script>

<style scoped>

</style>
