var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[_c('div',{staticClass:"detail-page"},[_c('div',{staticClass:"detail-page__left"},[_c('CardLeftBlock',{attrs:{"backgroundImage":'order-consolidation'}},[_c('template',{slot:"body"},[_c('div',{staticClass:"card-detail-left__date"},[_vm._v(" "+_vm._s(_vm._f("moment")(new Date(),"DD MMM, YYYY"))+" ")]),(_vm.consolidation.getRelatedOrders().length > 0)?_c('div',{staticClass:"mt-4",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'consolidations_printLabels',
                ])}}}),_c('MainButton',{staticClass:"btn-fit-content",attrs:{"value":_vm.$t('consolidations_printLabels.localization_value.value')},nativeOn:{"click":function($event){return _vm.$emit('printLabels')}}})],1):_vm._e(),(_vm.consolidation.getLabelFileUUID())?_c('div',{staticClass:"mt-4",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'consolidation_fedexDocs',
                ])}}}),_c('router-link',{attrs:{"to":_vm.$store.getters.GET_PATHS.getPdfFile + '/' + _vm.consolidation.getLabelFileUUID(),"target":'_blank'}},[_c('MainButton',{class:{'disabled-btn' : _vm.$store.getters.getConsolidationBtn},attrs:{"value":_vm.$t('consolidation_fedexDocs.localization_value.value')}})],1)],1):_vm._e()])],2)],1),_c('div',{staticClass:"detail-page__right"},[_c('CardRightBlock',[_c('template',{slot:"header"},[_c('div',{staticClass:"order-create__head"},[_c('div',{staticClass:"order-create__head-line"},[_c('div',{staticClass:"order-create__header-col",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'consolidations_backTo',
                  ])}}}),_c('router-link',{staticClass:"order-create__head-back",attrs:{"to":_vm.$store.getters.GET_PATHS.ordersConsolidation}},[_c('LinkBack',{attrs:{"value":_vm.$t('consolidations_backTo.localization_value.value')}})],1)],1)]),_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'consolidations_newConsolidationFedex',
                  // 'consolidations_newConsolidationDHL',
                  // 'consolidations_newConsolidationTNT',
                  'consolidations_newConsolidationEu',
                  'consolidations_newConsolidationYourCity',
                ])}}}),(_vm.isTNT)?_c('div',{staticClass:"order-create__head-title color-orange"},[_vm._v(" "+_vm._s(_vm.$t('consolidations_newConsolidationTNT.localization_value.value'))+" ")]):_vm._e(),(_vm.isDHL)?_c('div',{staticClass:"order-create__head-title color-red"},[_vm._v(" "+_vm._s(_vm.$t('consolidations_newConsolidationDHL.localization_value.value'))+" ")]):_vm._e(),(_vm.isFedex)?_c('div',{staticClass:"order-create__head-title color-blue"},[_vm._v(" "+_vm._s(_vm.$t('consolidations_newConsolidationFedex.localization_value.value'))+" ")]):_vm._e(),(_vm.isDHL_USA)?_c('div',{staticClass:"order-create__head-title color-blue"},[_vm._v(" "+_vm._s(_vm.$t('consolidations_newConsolidationDHLUSA.localization_value.value'))+" ")]):_vm._e(),(_vm.isDPD)?_c('div',{staticClass:"order-create__head-title color-blue"},[_vm._v(" "+_vm._s(_vm.$t('consolidations_newConsolidationEu.localization_value.value'))+" ")]):_vm._e(),(_vm.isEUProduct)?_c('div',{staticClass:"order-create__head-title color-blue"},[_vm._v(" "+_vm._s(_vm.$t('consolidations_newConsolidationEuProducts.localization_value.value'))+" ")]):_vm._e(),(_vm.isFromYourCity)?_c('div',{staticClass:"order-create__head-title color-blue"},[_vm._v(" "+_vm._s(_vm.$t('consolidations_newConsolidationYourCity.localization_value.value'))+" ")]):_vm._e()]),_c('div',{staticClass:"order-create__head-txt",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'consolidations_newConsolidationText',
                  ])}}}),_vm._v(" "+_vm._s(_vm.$t('consolidations_newConsolidationText.localization_value.value'))+" ")]),(!_vm.isAdmin)?_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'consolidations_shipping',
                'consolidations_consolidation',
                'consolidations_products',
                'consolidations_senderInfo',
                'consolidations_confirmation',
              ])}}}),_c('div',{staticClass:"order-create__head-steps"},[_c('ProgressSteps',{staticClass:"progress-step-shipments",attrs:{"options":_vm.progressStepsSetting,"step":_vm.activeStep},on:{"nextPageHandler":_vm.nextPageHandler,"changeStep":_vm.stepChange}})],1)]):_vm._e()])]),_c('template',{slot:"body"},[(_vm.activeStep === 1 || _vm.isAdmin)?_c('StepA',{attrs:{"consolidation":_vm.consolidation,"destinationData":_vm.destinationData,"deliveryServices":_vm.deliveryServices,"loadingFedexWarehouses":_vm.loadingFedexWarehouses,"isEdit":true}}):_vm._e(),((_vm.activeStep === 2 || _vm.isAdmin) && !_vm.isTNT)?_c('StepB',{attrs:{"consolidation":_vm.consolidation,"responseData":_vm.responseData}}):_vm._e(),(((_vm.activeStep === 3 || _vm.isAdmin) && !_vm.isTNT) ||
                ((_vm.activeStep === 2 || _vm.isAdmin) && _vm.isTNT))?_c('StepC',{attrs:{"consolidation":_vm.consolidation}}):_vm._e(),(((_vm.activeStep === 4 || _vm.isAdmin) && !_vm.isTNT  && _vm.isFromAPI && !_vm.isOwnCarrier) ||
                ((_vm.activeStep === 3 || _vm.isAdmin) && _vm.isTNT))?_c('StepD',{attrs:{"consolidation":_vm.consolidation}}):_vm._e(),(((_vm.activeStep === 5 && !_vm.isTNT && _vm.isFromAPI && !_vm.isOwnCarrier) ||
                (_vm.activeStep === 4 && !_vm.isTNT && !_vm.isFromAPI && !_vm.isOwnCarrier) ||
                (_vm.activeStep === 4 && (_vm.isTNT || _vm.isOwnCarrier))) && !_vm.isAdmin)?_c('StepFinal',{attrs:{"consolidation":_vm.consolidation,"labelPrice":_vm.labelPrice},on:{"changeStep":step => {_vm.activeStep = step}}}):_vm._e()],1),_c('template',{slot:"footer"},[_c('div',{staticClass:"order-create__footer"},[_c('div',{staticClass:"order-create__footer-link"},[(_vm.activeStep === 1)?_c('router-link',{staticClass:"site-link site-link--alt",attrs:{"to":_vm.$store.getters.GET_PATHS.ordersConsolidation}},[_vm._v(" "+_vm._s(_vm.$t('common_cancel.localization_value.value'))+" ")]):_vm._e(),(_vm.activeStep !== 1)?_c('span',{staticClass:"site-link site-link--alt",on:{"click":function($event){return _vm.prevPageHandler()}}},[_vm._v(" "+_vm._s(_vm.$t('common_Previous.localization_value.value'))+" ")]):_vm._e()],1),_c('div',{staticClass:"order-create__footer-btn ml-auto"},[(_vm.activeStep === _vm.progressStepsSetting.length || _vm.isAdmin)?_c('MainButton',{staticClass:"order-create__footer-btn-i secondary",class:{'disabled-btn' : _vm.$store.getters.getConsolidationBtn},attrs:{"value":_vm.$t('common_SaveInDrafts.localization_value.value'),"tooltip":true},nativeOn:{"click":function($event){return _vm.$emit('prepareSaveConsolidation', {draft: true, edit: true})}}},[_c('template',{slot:"tooltip"},[_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('common_drafts.localization_value.value')))])]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('common_savingInDrafts.localization_value.value'))+" ")])])],2):_vm._e(),(_vm.activeStep !== _vm.progressStepsSetting.length && !_vm.isAdmin)?_c('MainButton',{staticClass:"order-create__footer-btn-i",attrs:{"value":_vm.$t('common_Next.localization_value.value')},nativeOn:{"click":function($event){return _vm.nextPageHandler()}}}):_vm._e(),((_vm.activeStep === _vm.progressStepsSetting.length || _vm.isAdmin) &&
                      (_vm.isFromAPI && (_vm.isFromYourCity &&
                        (Number(_vm.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id) === Number(_vm.consolidation.getConsolidationDestination().id) ||
                        Number(_vm.CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY.id) === Number(_vm.consolidation.getConsolidationDestination().id))
                      )) &&
                      !_vm.hasCreateLabel &&
                      !_vm.consolidation.getLabelFileUUID())?_c('MainButton',{staticClass:"order-create__footer-btn-i",class:{'disabled-btn' : _vm.$store.getters.getConsolidationBtn},attrs:{"value":_vm.$t('express_BuyLabel.localization_value.value')},nativeOn:{"click":function($event){return _vm.getLabelPriceFromCard.apply(null, arguments)}}}):_vm._e(),((_vm.activeStep === _vm.progressStepsSetting.length || _vm.isAdmin) && _vm.isFromAPI && _vm.hasCreateLabel &&
                          (Object.keys(_vm.labelPrice).length > 0 && parseFloat(_vm.labelPrice.price) !== 0) && !_vm.consolidation.getLabelFileUUID())?_c('div',{staticClass:"order-create__footer-btn-i",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()},staticStyle:{"position":"relative"}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                    'consolidations_buyLabelError',
                  ])}}}),_c('MainButton',{staticClass:"order-create__footer-btn-i",class:{'disabled-btn' : _vm.$store.getters.getConsolidationBtn || _vm.blockBuyLabelConsolidation},attrs:{"value":_vm.$t('express_CreateLabel.localization_value.value')},nativeOn:{"click":function($event){return _vm.$emit('createLabel', _vm.$route.params.id)}}}),(_vm.blockBuyLabelConsolidation)?_c('span',{staticClass:"default-input-wrap__error error-field error-field--btn"},[_vm._v(" "+_vm._s(_vm.$t('consolidations_buyLabelError.localization_value.value'))+" ")]):_vm._e()],1):_vm._e(),((_vm.activeStep === _vm.progressStepsSetting.length || _vm.isAdmin) &&
                    (!_vm.isFromAPI || (_vm.isFromYourCity &&
                      (Number(_vm.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id) !== Number(_vm.consolidation.getConsolidationDestination().id) &&
                      Number(_vm.CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY.id) !== Number(_vm.consolidation.getConsolidationDestination().id))
                    ) ||
                    (_vm.isFromYourCity && _vm.consolidation.getLabelFileUUID())))?_c('MainButton',{staticClass:"order-create__footer-btn-i",class:{'disabled-btn' : _vm.$store.getters.getConsolidationBtn},attrs:{"value":_vm.$t('common_save.localization_value.value')},nativeOn:{"click":function($event){return _vm.$emit('prepareSaveConsolidation', {draft: false, edit: true})}}}):_vm._e()],1)])])],2)],1)]),(_vm.isModalBuyLabelPopup)?_c('BuyLabelPopup',{on:{"close":function($event){return _vm.changeBuyLabelPopup(false)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }