<template>
  <AccordBlock>
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['consolidations_shippingDetails'])"></div>
        {{$t('consolidations_shippingDetails.localization_value.value')}}
      </div>
    </template>

    <template slot="body">

      <div class="custom-row">
        <template v-if="!isFromAPI">
          <div class="custom-col custom-col--33 custom-col--md-50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['consolidations_deliveryService'])"></div>
            <DefaultSelect
                :options="deliveryServices"
                :label="$t('consolidations_deliveryService.localization_value.value')"
                v-bind:class="{'ui-no-valid': consolidation.validation.deliveryService}"
                :errorTxt="$t(`${consolidation.validationTranslate.deliveryService}.localization_value.value`)"
                :error="consolidation.validation.deliveryService"
                :optionsLabel="'name'"
                @change="changeDeliveryService"
                :selected="consolidation.data.deliveryService"
            />
          </div>
        </template>

        <div class="custom-col custom-col--33 custom-col--md-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['consolidations_trackNumIncCons'])"></div>
          <DefaultInput
              :label="$t('consolidations_trackNumIncCons.localization_value.value')"
              v-model="consolidation.data.systemTrackingNumber"
              :disabled="true"
          />
        </div>
        <div class="custom-col custom-col--33 custom-col--md-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['consolidations_expFromWarehouse'])"></div>
          <DefaultInput
              :label="$t('consolidations_expFromWarehouse.localization_value.value')"
              :value="getOldDeliveryServiceName()"
              :disabled="true"
          />
        </div>
        <div class="custom-col custom-col--33 custom-col--md-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['consolidations_trackingNumber'])"></div>
          <DefaultInput
              :label="$t('consolidations_trackingNumber.localization_value.value')"
              v-model="consolidation.data.trackingNumber"
          />
        </div>


        <div class="custom-col custom-col--33 custom-col--md-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['consolidations_consolidation'])"></div>
          <DefaultSelect
              :label="$t('consolidations_consolidation.localization_value.value')"
              :options="consolidationUnions"
              :otherValue="'consolidationUnion'"
              v-bind:class="{'ui-no-valid': consolidation.validation.consolidationUnion}"
              :errorTxt="$t(`${consolidation.validationTranslate.consolidationUnion}.localization_value.value`)"
              :error="consolidation.validation.consolidationUnion"
              @change="(val) => {consolidation.setConsolidationUnion(val)}"
              :selected="consolidation.data.consolidationUnion"
          />
        </div>
<!--        <div class="custom-col custom-col&#45;&#45;16 custom-col&#45;&#45;xl-33 custom-col&#45;&#45;md-50">-->
<!--          <DefaultInput-->
<!--                  :label="'Q-ty'"-->
<!--                  :placeholder="'100'"-->
<!--                  v-model="sum04"-->
<!--                  :disabled="true"-->
<!--          />-->
<!--        </div>-->
        <div class="custom-col custom-col--33 custom-col--md-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['consolidations_status'])"></div>
          <DefaultSelect
              class="w-100"
              :options="CONSOLIDATION_STATUSES_ARRAY"
              :label="$t('consolidations_status.localization_value.value')"
              :otherValue="'translation'"
              v-bind:class="{'ui-no-valid': consolidation.validation.status}"
              :errorTxt="$t(`${consolidation.validationTranslate.status}.localization_value.value`)"
              :error="consolidation.validation.status"
              @change="(val) => {consolidation.setStatus(val)}"
              :selected="consolidation.data.status"
          />
        </div>

<!--        <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-50"-->
<!--             v-if="isFromYourCity"-->
<!--             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--          <div class="admin-edit" @click="editTranslate(['consolidations_status'])"></div>-->
<!--          <DefaultSelect-->
<!--              class="w-100"-->
<!--              :options="CONSOLIDATION_SYSTEM_STATUSES_ARRAY"-->
<!--              :label="$t('consolidations_status.localization_value.value')"-->
<!--              :optionsLabel="'name'"-->
<!--              :clearable="false"-->
<!--              @change="(val) => {consolidation.setSystemStatus(val)}"-->
<!--              :selected="consolidation.data.systemStatus"-->
<!--          />-->
<!--        </div>-->

        <div class="custom-col custom-col--66 custom-col--md-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_comment'])"></div>
          <TextareaDefault
              :label="$t('common_comment.localization_value.value')"
              v-bind:class="{'ui-no-valid': consolidation.validation.comment}"
              :error="consolidation.validation.comment"
              :errorTxt="$t(`${consolidation.validationTranslate.comment}.localization_value.value`)"
              v-model="consolidation.data.comment"
          />
        </div>


      </div>

    </template>
  </AccordBlock>
</template>

<script>
  import AccordBlock from "../../../../../../../coreComponents/AccordBlock/AccordBlock";
  import {mixinDetictingMobile} from "../../../../../../../../mixins/mobileFunctions";
  import DefaultInput from "../../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DefaultSelect from "../../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import TextareaDefault from "../../../../../../../UI/textarea/TextareaDefault/TextareaDefault";
  import {consolidationMixin} from "../../../../../../../../mixins/consolidationMixins/consolidationMixin";
  import {
    CONSOLIDATION_STATUSES_ARRAY,
    CONSOLIDATION_SYSTEM_STATUSES_ARRAY
  } from "../../../../../../../../staticData/staticVariables";

  export default {
    name: "ShippingDetails",
    components: {TextareaDefault, DefaultSelect, DefaultInput, AccordBlock},

    mixins: [mixinDetictingMobile, consolidationMixin],

    props: {
      deliveryServices: Array,
      consolidationUnions: Array,
      consolidation: Object,
    },

    data() {
      return {
        CONSOLIDATION_STATUSES_ARRAY: CONSOLIDATION_STATUSES_ARRAY,
        CONSOLIDATION_SYSTEM_STATUSES_ARRAY: CONSOLIDATION_SYSTEM_STATUSES_ARRAY,
      }
    },

    methods: {
      changeDeliveryService(val) {
        this.consolidation.setDeliveryService(val)
      },

      getOldDeliveryServiceName() {
        return this.consolidation.data.oldDeliveryService?.name || ''
      },
    }
  }
</script>

<style scoped lang="scss">
  .toggle-block__details {
    min-height: 300px;
  }

  @media (max-width: 1120px){
    .toggle-block__details {
      background: #F8F4EE;
      border: 1px solid #BBAD9C;
      min-height: initial;
    }
  }
</style>
