<template>
  <div class="fragment">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'consolidations_status',
          'consolidations_inProgressStatus',
          'consolidations_departedStatus',
          'consolidations_receivedStatus',
          'consolidations_countedStatus',
          ])"></div>
      {{$t('consolidations_status.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100">
        <DefaultSelect
            class="w-100"
            :options="CONSOLIDATION_STATUSES_ARRAY"
            :label="$t('consolidations_status.localization_value.value')"
            :otherValue="'translation'"
            v-bind:class="{'ui-no-valid': consolidation.validation.status}"
            :errorTxt="$t(`${consolidation.validationTranslate.status}.localization_value.value`)"
            :error="consolidation.validation.status"
            @change="(val) => {consolidation.setStatus(val)}"
            :selected="consolidation.data.status"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
import {CONSOLIDATION_STATUSES_ARRAY} from "../../../../../../../staticData/staticVariables";

export default {
  name: "StatusesBlock",

  components: {
    DefaultSelect
  },

  props: {
    consolidation: Object,
  },

  data() {
    return {
      CONSOLIDATION_STATUSES_ARRAY: CONSOLIDATION_STATUSES_ARRAY,
    }
  }
}
</script>

<style scoped>

</style>