<template>
  <AccordBlock>
    <template slot="header">
      {{$t('consolidations_shipping.localization_value.value')}}
    </template>

    <template slot="body">

      <ShippingPartner
          v-show="!isTNT"
          :destinationData="destinationData"
          :consolidation="consolidation"
          :adminEdit="true"
      />

      <ShippingDate
          :consolidation="consolidation"
      />

      <PickupDetails
          v-if="isTNT"
          :consolidation="consolidation"
      />

      <StepD
          v-if="isFromYourCity && !isOwnCarrier && consolidation.getId()"
          :consolidation="consolidation"
      />

    </template>
  </AccordBlock>
</template>

<script>
  import AccordBlock from "../../../../../../../coreComponents/AccordBlock/AccordBlock";
  import {mixinDetictingMobile} from "../../../../../../../../mixins/mobileFunctions";
  import ShippingPartner from "../../../../chunks/chunks/ShippingPartner/ShippingPartner";
  import {consolidationMixin} from "../../../../../../../../mixins/consolidationMixins/consolidationMixin";
  import ShippingDate from "../../../../chunks/chunks/ShippingDate/ShippingDate";
  import PickupDetails from "../../../../chunks/chunks/PickupDetails/PickupDetails";
  import StepD from "../../../../chunks/ConsolidationUserBlocks/StepD/StepD";

  export default {
    name: "Destination",
    components: {
      StepD,
      PickupDetails,
      ShippingDate,
      ShippingPartner,
      AccordBlock
    },

    mixins: [
      mixinDetictingMobile,
      consolidationMixin
    ],

    props: {
      consolidation: Object,
      destinationData: Array,
    },

    data() {
      return {
        date: '',

        product:{
          sku: '',
          skuOption: [{}],
          categoryOption: [{}],
          categorySelected: '',
          nameUa: '',
          nameEn: '',
          forUa: '',
          forEn: '',
          materialUa: '',
          materialEn: '',
          subMaterialUa: '',
          subMaterialEn: '',
          valueQuantity: '',
          valueSum: '',
        }
      }
    },
  }
</script>

<style scoped lang="scss">
  .toggle-block__details {
    min-height: 300px;
  }

  @media (max-width: 1120px){
    .toggle-block__details {
      background: #F8F4EE;
      border: 1px solid #BBAD9C;
      min-height: initial;
    }
  }
</style>
