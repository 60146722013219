<template>
  <div class="order-create__section">

    <SelectUserBlock
        v-if="isAdmin"
        :consolidation="consolidation"
        @userChanged="$emit('userChanged')"
    />

    <SendFromBlockConso
        :consolidation="consolidation"
        :isAdminEdit="false"
        :isEdit="isEdit"
    />

    <ShippingPartner
        v-show="!isTNT"
        :destinationData="destinationData"
        :consolidation="consolidation"
        :loadingFedexWarehouses="loadingFedexWarehouses"
    />

    <ShippingDate
        :consolidation="consolidation"
    />

    <ShippingCompany
        v-if="!isFromAPI && !isFromYourCity"
        :deliveryServices="deliveryServices"
        :consolidation="consolidation"
    />

    <div v-else-if="(isFromAPI || isFromYourCity) && !isTNT" class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'consolidations_trackingNumber',
          ])"></div>
        <DefaultInput
            :label="$t('consolidations_trackingNumber.localization_value.value')"
            :disabled="consolidation.getConsolidationDestination().id !== CONSOLIDATION_TYPE_OWN_CARRIER.id"
            v-model="consolidation.data.trackingNumber"
        />
      </div>
    </div>

    <PickupDetails
        v-if="isTNT"
        :consolidation="consolidation"
    />

    <StatusesBlock
        v-if="isAdmin"
        :consolidation="consolidation"
    />

    <DimensionsBlock
        v-if="isTNT"
        :consolidation="consolidation"
    />
    <CommentBlock
        v-if="isTNT"
        :consolidation="consolidation"
    />
    <OrdersFBMBlock
        v-if="isTNT"
        :consolidation="consolidation"
        :orderUserId="consolidation.data.User.userId"
    />

    <DocumentsBlock
      v-if="(isFedex || isDHL_USA) && isFromAPI"
      :consolidation="consolidation"
    />

  </div>
</template>

<script>
import {CONSOLIDATION_TYPES_ARRAY} from "../../../../../../../staticData/staticVariables";
import ShippingPartner from "../../chunks/ShippingPartner/ShippingPartner";
import ShippingDate from "../../chunks/ShippingDate/ShippingDate";
import ShippingCompany from "../../chunks/ShippingCompany/ShippingCompany";
import {consolidationMixin} from "../../../../../../../mixins/consolidationMixins/consolidationMixin";
// import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
import DimensionsBlock from "../../chunks/DimensionsBlock/DimensionsBlock";
import CommentBlock from "../../chunks/CommentBlock/CommentBlock";
import DocumentsBlock from "../../chunks/DocumentsBlock/DocumentsBlock";
import OrdersFBMBlock from "../../chunks/OrdersFBMBlock/OrdersFBMBlock";
import SelectUserBlock from "../../ConsolidationAdminBlocks/SelectUserBlock/SelectUserBlock";
import PickupDetails from "../../chunks/PickupDetails/PickupDetails";
import StatusesBlock from "../../ConsolidationAdminBlocks/StatusesBlock/StatusesBlock";
import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
import {CONSOLIDATION_TYPE_OWN_CARRIER} from "../../../../../../../staticData/staticVariables";
import SendFromBlockConso
  from "@/components/modules/ConsolidationModule/components/chunks/chunks/SendFromBlockConso/SendFromBlockConso";

export default {
  name: "StepA",

  components: {
    SendFromBlockConso,
    DefaultInput,
    StatusesBlock,
    PickupDetails,
    SelectUserBlock,
    OrdersFBMBlock,
    DocumentsBlock,
    CommentBlock,
    DimensionsBlock,
    // DefaultInput,
    ShippingDate,
    ShippingPartner,
    ShippingCompany,
  },

  mixins: [
      consolidationMixin
  ],

  props: {
    destinationData: Array,
    deliveryServices: Array,
    consolidation: Object,
    loadingFedexWarehouses: Boolean,
    isEdit: Boolean,
  },

  data(){
    return {
      isModalSelectOrdersToAdd: false,
      tooltipAddProductActive: true,
      CONSOLIDATION_TYPES_ARRAY: CONSOLIDATION_TYPES_ARRAY,
      CONSOLIDATION_TYPE_OWN_CARRIER: CONSOLIDATION_TYPE_OWN_CARRIER,
    }
  },

  methods: {
    changeSelectOrdersToAdd(val){
      this.isModalSelectOrdersToAdd = val
    },


  },
}
</script>

<style lang="scss" scoped>
  .toggle-block.small-size .toggle-block__details{
    max-width: 305px;
  }
</style>