import {proformMixins} from "@/mixins/proformMixins/proformMixins";
import {ProformProduct} from "@/components/globalModels/ProformProduct";
import {ProductForCell} from "@/components/globalModels/ProductForCell";

export const addProductForCellMixin = {

  mixins: [proformMixins],

  methods: {

    async getProductFromWarehouse(User, findCode, byStorageId) {
      let localProductsForCellArray = []
      let newProformModel = new ProformProduct()
      this.initializeProductProformArray({product: newProformModel}, 'product')
      let productForCell = new ProductForCell(false, newProformModel)
      localProductsForCellArray.push(productForCell)

      return this.getProductWithCells(User.getUserId(), findCode, byStorageId).then(product => {
        return {product, localProductsForCellArray}
      })
    },

    async getProductWithCells(userId, findCode, byStorageId = null) {
      const query = new this.Query();
      let url = '?';
      let myQuery = query
        .for('posts')

      if(!userId || !findCode) return

      myQuery.where('ProductUpcCode', findCode)
      if(byStorageId) {
        myQuery.where('byStorageId', byStorageId)
      }

      url = url + myQuery.limit(1).page(parseInt(0)).url().split('?')[1]

      let typeFetch = 'fetchProductsWithCells'
      url = url + `&user_id=${userId}`

      return this.$store.dispatch(typeFetch, url).then((response) => {
        return this._.first(this.getRespData(response)) || false
      })
    },

    addProductToArray(localProductForCell, productsForCellArray){
      let valid = true
      if(!localProductForCell.data.ProformProduct.productProformValidation({
        productName: true,
        proformCategory: true,
        itemsQuantity: true,
        price: true,
      })) valid = false

      if(!valid) {
        this.openNotify('error', 'common_notificationUndefinedError')
        return false
      }

      localProductForCell.setProductCount(1)
      productsForCellArray.push(localProductForCell)

      return true
    },

  }

}