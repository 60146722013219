<template>
  <div class="fragment order-admin-products">

    <div class="custom-row noPrintable">
      <div class="custom-col" v-if="consolidation.data.productsForCellArray.length > 0">

        <div class="site-table-wrap table-small"
             v-if="!isMobileFunc()"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['productForCell_scannedCount','fbm_ProductName',
          'common_PricePerItem', 'consolidations_nowInCells', 'fba_Cells', 'consolidations_scanCell'])"></div>
          <table class="site-table">
            <thead>
            <tr>
              <th style="width: 20px;"></th>
              <th style="width: 100px;">{{$t('productForCell_scannedCount.localization_value.value')}}</th>
              <th>{{ $t('fbm_ProductName.localization_value.value') }}</th>
              <th v-if="isEUProduct">{{ $t('common_PricePerItem.localization_value.value') }}</th>
              <th style="width: 150px;">{{ $t('consolidations_nowInCells.localization_value.value') }}</th>
              <th style="width: 150px;">{{ $t('fba_Cells.localization_value.value') }}</th>
<!--              <th class="noPrintable" style="width: 120px;">{{ $t('consolidations_scanCell.localization_value.value') }}</th>-->
              <th class="noPrintable" style="width: 120px;"></th>
            </tr>
            </thead>
            <tbody>

            <tr
                v-for="(item, index) in consolidation.data.productsForCellArray"
                :key="index"
            >
              <td>
                <ProductCheckedBlock
                    :item="item"
                    @changeProductChecked="(val) => prepareChangeProductChecked(val, item)"
                />
              </td>
              <td v-bind:class="{
                'red-color' : item.data.scannedCount !== item.data.productCount,
                'green-color' : item.data.scannedCount === item.data.productCount
              }">
                {{item.data.scannedCount}} / {{item.data.productCount}}
              </td>
              <td>
                <div class="table-row ellipsis max-200">
                  <ValueHelper
                      :value="item.data.ProformProduct"
                      :deep="'product.productObject.name_for_fbm'"
                  />
                </div>
              </td>
              <td v-if="isEUProduct">
                <ValueHelper
                    :value="item.data.ProformProduct"
                    :deep="'product.price'"
                    :costType="true"
                    :costCurrencyType="'dollar'"
                />
              </td>

              <td>
                <template v-for="(cellItem, cellIndex) in getItemsWithCells(item)">
                  <div :key="cellIndex">
                    {{cellItem.cell.combo_name}} ({{cellItem.quantity}})
                    <template v-if="getItemsWithCells(item).length > 1 && cellIndex < getItemsWithCells(item).length - 1">,</template>
                  </div>
                </template>
              </td>

              <td>
                <span class="noPrintable">
                  <template v-if="cells = getCellsInTable(item)">
                    {{cells.text}}
                    <span v-if="cells.count > 0" class="site-link cursor-initial">+ {{cells.count}}</span>
                  </template>
                </span>
                <span class="cellsForPrint">
                  <template v-if="cells = getCellsInTable(item, true)">
                    <div v-for="(itemText, indexText) in cells.text" :key="indexText">
                      {{itemText}}
                    </div>
                  </template>
                </span>
              </td>
<!--              <td class="noPrintable">-->
<!--                <DefaultInput-->
<!--                    :type="'text'"-->
<!--                    :resetAfter="true"-->
<!--                    @change="(val) => {changeScanner(val, item)}"-->
<!--                />-->
<!--              </td>-->
              <td class="noPrintable">
                <div class="manage-block">
                  <LinkButton
                      :value="$t('common_edit.localization_value.value')"
                      :type="'edit'"
                      class="mr-3"
                      @click.native="openProductForCellPopup(index)"
                  />
                  <LinkButton
                      :value="$t('common_delete.localization_value.value')"
                      :type="'delete'"
                      @click.native="removeProductForCells(consolidation.data.productsForCellArray, index)"
                  />
                </div>

              </td>
            </tr>

            </tbody>
          </table>
        </div>

        <div class="site-table-mobile"
             v-if="isMobileFunc()"
        >
          <div class="site-table-mobile__inner custom-row">
            <div class="custom-col custom-col--50 custom-col--sm-100"
                 v-for="(item, index) in consolidation.data.productsForCellArray"
                 :key="index"
            >
              <div class="site-table-mobile__item bg--main-bg">
                <div class="site-table-mobile__head">
                  <ProductCheckedBlock
                      :item="item"
                      @changeProductChecked="(val) => prepareChangeProductChecked(val, item)"
                  />
                  <ValueHelper
                      :value="item.data.ProformProduct"
                      :deep="'product.productObject.name_for_fba'"
                  />
                </div>
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col">
                    {{$t('productForCell_scannedCount.localization_value.value')}}
                    <span v-bind:class="{
                      'red-color' : item.data.scannedCount !== item.data.productCount,
                      'green-color' : item.data.scannedCount === item.data.productCount
                    }">
                      {{item.data.scannedCount}} / {{item.data.productCount}}
                    </span>
                  </div>
                </div>
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col">
                    {{ $t('fbm_ProductName.localization_value.value') }}
                    <span v-bind:class="{
                      'red-color' : item.data.scannedCount !== item.data.productCount,
                      'green-color' : item.data.scannedCount === item.data.productCount
                    }">
                      <ValueHelper
                          :value="item.data.ProformProduct"
                          :deep="'product.productObject.name_for_fbm'"
                      />
                    </span>
                  </div>
                </div>
                <div v-if="isEUProduct" class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col">
                    {{ $t('common_PricePerItem.localization_value.value') }}
                    <span v-bind:class="{
                      'red-color' : item.data.scannedCount !== item.data.productCount,
                      'green-color' : item.data.scannedCount === item.data.productCount
                    }">
                      <ValueHelper
                          :value="item.data.ProformProduct"
                          :deep="'product.price'"
                          :costType="true"
                          :costCurrencyType="'dollar'"
                      />
                    </span>
                  </div>
                </div>
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col">
                    {{ $t('consolidations_nowInCells.localization_value.value') }}
                    <span>
                      <template v-for="(cellItem, cellIndex) in getItemsWithCells(item)">
                        <div :key="cellIndex">
                          {{cellItem.cell.combo_name}} ({{cellItem.quantity}})
                          <template v-if="getItemsWithCells(item).length > 1 && cellIndex < getItemsWithCells(item).length - 1">,</template>
                        </div>
                      </template>
                    </span>
                  </div>
                </div>
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col">
                    {{ $t('fba_Cells.localization_value.value') }}
                    <span class="noPrintable">
                      <template v-if="cells = getCellsInTable(item)">
                        {{cells.text}}
                        <span v-if="cells.count > 0" class="site-link cursor-initial">+ {{cells.count}}</span>
                      </template>
                    </span>
                    <span class="cellsForPrint">
                      <template v-if="cells = getCellsInTable(item, true)">
                        <div v-for="(itemText, indexText) in cells.text" :key="indexText">
                          {{itemText}}
                        </div>
                      </template>
                    </span>
                  </div>
                </div>
<!--                <div class="site-table-mobile__row custom-row noPrintable">-->
<!--                  <div class="site-table-mobile__piece custom-col">-->
<!--                    {{ $t('consolidations_scanCell.localization_value.value') }}-->
<!--                    <DefaultInput-->
<!--                        :type="'text'"-->
<!--                        style="width: 150px;"-->
<!--                        :resetAfter="true"-->
<!--                        @change="(val) => {changeScanner(val, item)}"-->
<!--                    />-->
<!--                  </div>-->
<!--                </div>-->
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col d-flex align-items-center">
                    {{ $t('common_manage.localization_value.value') }}
                    <div class="ml-2 d-flex">
                      <LinkButton
                          :value="$t('common_edit.localization_value.value')"
                          :type="'edit'"
                          class="mr-3"
                          @click.native="openProductForCellPopup(index)"
                      />
                      <LinkButton
                          :value="$t('common_delete.localization_value.value')"
                          :type="'delete'"
                          @click.native="removeProductForCells(consolidation.data.productsForCellArray, index)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="for-print-table">
      <div class="custom-row">
        <div class="custom-col" v-if="consolidation.data.productsForCellArray.length > 0">

          <div class="site-table-wrap table-small"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['productForCell_scannedCount','fbm_ProductName','common_PricePerItem','fba_Cells'])"></div>
            <table class="site-table">
              <thead>
              <tr>
                <th style="width: 20px;"></th>
                <th style="width: 100px;">{{$t('productForCell_scannedCount.localization_value.value')}}</th>
                <th>{{ $t('fbm_ProductName.localization_value.value') }}</th>
                <th v-if="isEUProduct">{{ $t('common_PricePerItem.localization_value.value') }}</th>
                <th style="width: 150px;">{{ $t('consolidations_nowInCells.localization_value.value') }}</th>
                <th style="width: 150px;">{{ $t('fba_Cells.localization_value.value') }}</th>
              </tr>
              </thead>
              <tbody>

              <tr
                  v-for="(item, index) in consolidation.data.productsForCellArray"
                  :key="index"
              >
                <td>
                  <ProductCheckedBlock
                      :item="item"
                      @changeProductChecked="(val) => prepareChangeProductChecked(val, item)"
                  />
                </td>
                <td v-bind:class="{
                'red-color' : item.data.scannedCount !== item.data.productCount,
                'green-color' : item.data.scannedCount === item.data.productCount
              }">
                  {{item.data.scannedCount}} / {{item.data.productCount}}
                </td>
                <td>
                  <div class="table-row ellipsis max-200">
                    <ValueHelper
                        :value="item.data.ProformProduct"
                        :deep="'product.productObject.name_for_fbm'"
                    />
                  </div>
                </td>
                <td v-if="isEUProduct">
                  <ValueHelper
                      :value="item.data.ProformProduct"
                      :deep="'product.price'"
                      :costType="true"
                      :costCurrencyType="'dollar'"
                  />
                </td>

                <td>
                  <template v-for="(cellItem, cellIndex) in getItemsWithCells(item)">
                    <div :key="cellIndex">
                      {{cellItem.cell.combo_name}} ({{cellItem.quantity}})
                      <template v-if="getItemsWithCells(item).length > 1 && cellIndex < getItemsWithCells(item).length - 1">,</template>
                    </div>
                  </template>
                </td>

                <td>
                  <template v-if="cells = getCellsInTable(item, true)">
                    <div v-for="(itemText, indexText) in cells.text" :key="indexText">
                      {{itemText}}
                    </div>
                  </template>
                </td>
              </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="order-create__row custom-row noPrintable">
      <div class="order-create__col custom-col"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_AddProduct'])"></div>
        <MainButton class="wfc secondary"
                    :value="$t('common_AddProduct.localization_value.value')"
                    :ico="'ico'"
                    @click.native="openProductPopup"
        >
          <template slot="ico">
            <PlusAccentIconSVG class="btn-icon-plus"/>
          </template>
        </MainButton>
      </div>
    </div>

    <!-- ****  ADD NEW PRODUCT **** -->
    <ProductsPopup
        v-if="isModalProductsPopup"
        :consolidation="consolidation"
        :user="user"
        :productsDefault="consolidation.data.productsDefault"
        @close="changeProductsPopup(false)"
    />

    <!-- **** MANAGE CELLS **** -->
    <ProductForCellPopup
        v-if="isModalProductForCellPopup"
        :user="user"
        :orderType="'inbound'"
        :productForCell="consolidation.data.ProductForCell"
        :productForCellItem="consolidation.data.productsForCellArray[setIndex]"
        :productsForCellArray="consolidation.data.productsForCellArray"
        :arrayIndex="setIndex"
        :damaged="true"
        @close="changeProductForCellPopup(false)"
        @saveChanges="recalculateVatCost"
    />
  </div>
</template>

<script>
import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
import {productForCellMixin} from "../../../../../../../mixins/productMixins/productForCellMixin";
import {consolidationMixin} from "../../../../../../../mixins/consolidationMixins/consolidationMixin";
import ProductCheckedBlock
  from "../../../../../../coreComponents/ProductCellsManageForOrders/ProductCheckedBlock/ProductCheckedBlock";
import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
import PlusAccentIconSVG from "../../../../../../../../public/img/UI-group/plus-accent-icon.svg?inline"
import ProductForCellPopup from "../../../../../../coreComponents/Popups/ProductForCellPopup/ProductForCellPopup";
import ProductsPopup from "../../../../popups/ProductsPopup/ProductsPopup";
import _ from "lodash";
// import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";

export default {
  name: "ProductsAdminBlock",

  components: {
    // DefaultInput,
    ProductsPopup,
    ProductForCellPopup,
    MainButton,
    LinkButton,
    ValueHelper,
    ProductCheckedBlock,
    PlusAccentIconSVG,
  },

  mixins: [
    consolidationMixin,
    mixinDetictingMobile,
    productForCellMixin,
  ],

  props: {
    consolidation: Object,
    user: Object,
  },

  data(){
    return{
      isModalProductsPopup: false,
      isModalProductForCellPopup: false,

      setIndex: -1,
    }
  },

  methods: {

    recalculateVatCost() {
      if(this.consolidation.vatAmountAdminChanged === 1 || this.isFedex || this.isDHL_USA_BY_DESTINATION_TYPE || this.isFromYourCity) return
      this.consolidation.setVatAmount(this.getVatCost(true))
    },

    changeProductsPopup(open){
      this.isModalProductsPopup = open
    },

    changeProductForCellPopup(open){
      this.isModalProductForCellPopup = open
      if(!open){
        this.$emit('recalculateFees')
        this.changeConsolidationProductInCells()
      }
      this.$emit('changeProductForCellPopup', open)
    },

    openProductPopup(){
      this.changeProductsPopup(true)
    },

    prepareChangeProductChecked(checked, item, updateProductInCells = true) {
      this.changeProductChecked(checked, item)

      if(updateProductInCells) {
        this.changeConsolidationProductInCells()
      }
    },

    changeProductChecked(checked, item) {
      // let firstProductsForCell = this._.first(item.data.UserProductCellArray)
      // let firstProductsMixedForCell = this._.first(item.data.UserMixedProductCellArray)
      // let firstUserCellArray = this._.find(item.data.UserCellArray, {data: {cellSelected: {mixed: false}}}) || this._.first(item.data.UserCellArray)
      //let firstFreeCell = this._.first(item.data.FreeCellArray)
      // let firstMixedForCell = this._.first(item.data.MixedCellArray)

      if(checked){
        item.allScannedProduct()

        // if(firstProductsForCell){
        //   item.data.withoutCellEndCounts = 0
        //   return firstProductsForCell.data.cellCount = item.data.productCount
        // }
        //
        // if(firstProductsMixedForCell){
        //   item.data.withoutCellEndCounts = 0
        //   return firstProductsMixedForCell.data.cellCount = item.data.productCount
        // }
        //
        // if(firstUserCellArray){
        //   item.data.withoutCellEndCounts = 0
        //   return firstUserCellArray.data.cellCount = item.data.productCount
        // }

        let foundProductsForCell = _.find(item.data.UserProductCellArray, function (item) {
          return Number(item.data.cellSelected?.row_name) > 20
        })

        if(foundProductsForCell) {
          foundProductsForCell.data.cellCount = item.data.productCount
          return true
        }

        return item.data.withoutCellEndCounts = item.data.productCount

        // if(parseInt(item.data.withoutCellDeclaredCounts) !== 0) {
        //   return item.data.withoutCellEndCounts = item.data.productCount
        // }


        // if (firstFreeCell) {
        //   return firstFreeCell.data.cellCount = item.data.productCount
        // }
        //
        // if (firstMixedForCell) {
        //   return firstMixedForCell.data.cellCount = item.data.productCount
        // }
        //
        // return item.data.withoutCellEndCounts = item.data.productCount

      } else {
        item.removeAllScannedProduct()
        if(item.data.UserProductCellArray?.length > 0){
          item.data.UserProductCellArray.forEach(userProductCellItem => {
            userProductCellItem.data.cellCount = 0
          })
        }
        if (item.data.UserMixedProductCellArray?.length > 0) {
          item.data.UserMixedProductCellArray.forEach(userMixedProductCellItem => {
            userMixedProductCellItem.data.cellCount = 0
          })
        }
        // if(firstUserCellArray){
        //   firstUserCellArray.data.cellCount = 0
        // }
        if(item.data.UserCellArray?.length > 0){
          item.data.UserCellArray.forEach(userCellItem => {
            userCellItem.data.cellCount = 0
          })
        }
        if(item.data.FreeCellArray?.length > 0){
          item.data.FreeCellArray.forEach(freeCellItem => {
            freeCellItem.data.cellCount = 0
          })
        }
        if(item.data.MixedCellArray?.length > 0){
          item.data.MixedCellArray.forEach(mixedCellItem => {
            mixedCellItem.data.cellCount = 0
          })
        }
        if(parseInt(item.data.withoutCellEndCounts) !== 0) {
          item.data.withoutCellEndCounts = 0
        }

        return

      }
    },

    openProductForCellPopup(index = false){

      if(index >= 0){
        this.setIndex = index
      } else {
        this.setIndex = -1
      }

      this.changeProductForCellPopup(true)
    },

    removeProductForCells(array, index){
      this.consolidation.removeProductForCells(array, index)
    },

    getCellsInTable(item, forPrint = false) {
      let text = ''
      if (forPrint) {
        text = []
      }
      let count = -1

      let UserProductCellArray = item.data.UserProductCellArray,
          UserMixedProductCellArray = item.data.UserMixedProductCellArray

      let UserCellArray = item.data.UserCellArray
      let FreeCellArray = item.data.FreeCellArray
      let MixedCellArray = item.data.MixedCellArray

      let comboCellArraysArray = [
        UserProductCellArray,
        UserMixedProductCellArray,
        UserCellArray,
        FreeCellArray,
        MixedCellArray
      ]

      comboCellArraysArray.forEach(cellArray => {
        cellArray.map(cellItem => {
          if (cellItem.data.cellCount > 0) {
            let cellItemSelected = cellItem.data.cellSelected
            if (forPrint) {
              text.push(cellItemSelected?.combo_name)
            }
            else {
              count === -1 ? text = cellItemSelected?.combo_name : null
            }
            count ++
          }
        })
      })

      // console.log(UserProductCellArray, UserMixedProductCellArray, UserCellArray, FreeCellArray, MixedCellArray);
      //
      // UserProductCellArray.map(userCell => {
      //   let userCellSelected = userCell.data.cellSelected
      //   count === -1 ? text = userCellSelected?.combo_name : null
      //   count ++
      // })
      // UserMixedProductCellArray.map(mixedCell => {
      //   let mixedCellSelected = mixedCell.data.cellSelected
      //   count === -1 ? text = mixedCellSelected?.combo_name : null
      //   count ++
      // })
      //
      // UserCellArray.map(cellItem => {
      //   if (cellItem.data.cellCount > 0) {
      //     let cellItemSelected = cellItem.data.cellSelected
      //     count === -1 ? text = cellItemSelected?.combo_name : null
      //     count ++
      //   }
      // })
      //
      // FreeCellArray.map(cellItem => {
      //   if (cellItem.data.cellCount > 0) {
      //     let cellItemSelected = cellItem.data.cellSelected
      //     count === -1 ? text = cellItemSelected?.combo_name : null
      //     count ++
      //   }
      // })
      //
      // MixedCellArray.map(cellItem => {
      //   if (cellItem.data.cellCount > 0) {
      //     let cellItemSelected = cellItem.data.cellSelected
      //     count === -1 ? text = cellItemSelected?.combo_name : null
      //     count ++
      //   }
      // })

      return {text, count}
    },

    changeScanner(val, item){

      if (!(val.length > 0 && val.indexOf('-') > 0 && val.toLowerCase().indexOf('cell') === 0)) {
        return
      }

      let cellId = val.split('-')[1]
      cellId = parseInt(cellId)
      console.log(cellId);

      let
          userProductCellArray = item.data.UserProductCellArray,
          userMixedProductCellArray = item.data.UserMixedProductCellArray,
          userCellArray = item.data.UserCellArray,
          mixedCellArray = item.data.MixedCellArray,
          freeCellArray = item.data.FreeCellArray,
          foundFlag = false,
          foundItemId = false

      userProductCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id === +cellId) {
          cellItem.data.cellCount = item.data.scannedCount
          foundFlag = true
          foundItemId = cellItem.data.cellSelected.id
        }
      })

      if (foundItemId) {
        userProductCellArray = userProductCellArray.sort((a,b) => {
          return a.data?.cellSelected?.id === foundItemId ? -1 : b.data?.cellSelected?.id === foundItemId ? 1 : 0
        })
        foundItemId = false
      }

      userMixedProductCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id === +cellId) {
          cellItem.data.cellCount = item.data.scannedCount
          console.log('cell count:', cellItem.data.cellCount);
          foundFlag = true
          foundItemId = cellItem.data.cellSelected.id
        }
      })

      if (foundItemId) {
        userMixedProductCellArray = userMixedProductCellArray.sort((a,b) => {
          return a.data?.cellSelected?.id === foundItemId ? -1 : b.data?.cellSelected?.id === foundItemId ? 1 : 0
        })
        foundItemId = false
      }

      userCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id === +cellId) {
          cellItem.data.cellCount = item.data.scannedCount
          foundFlag = true
          foundItemId = cellItem.data.cellSelected.id
        }
      })

      if (foundItemId) {
        userCellArray = userCellArray.sort((a,b) => {
          return a.data?.cellSelected?.id === foundItemId ? -1 : b.data?.cellSelected?.id === foundItemId ? 1 : 0
        })
        foundItemId = false
      }

      mixedCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id === +cellId) {
          cellItem.data.cellCount = item.data.scannedCount
          foundFlag = true
          foundItemId = cellItem.data.cellSelected.id
        }
      })

      if (foundItemId) {
        mixedCellArray = mixedCellArray.sort((a,b) => {
          return a.data?.cellSelected?.id === foundItemId ? -1 : b.data?.cellSelected?.id === foundItemId ? 1 : 0
        })
        foundItemId = false
      }

      freeCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id === +cellId) {
          cellItem.data.cellCount = item.data.scannedCount
          foundFlag = true
          foundItemId = cellItem.data.cellSelected.id
        }
      })

      if (foundItemId) {
        freeCellArray = freeCellArray.sort((a,b) => {
          return a.data?.cellSelected?.id === foundItemId ? -1 : b.data?.cellSelected?.id === foundItemId ? 1 : 0
        })
        foundItemId = false
      }

      //if cell not found - exit
      if (!foundFlag) return

      //if cell found - reset all other cells count
      userProductCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id !== +cellId) {
          cellItem.data.cellCount = 0
        }
      })
      userMixedProductCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id !== +cellId) {
          cellItem.data.cellCount = 0
        }
      })
      userCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id !== +cellId) {
          cellItem.data.cellCount = 0
        }
      })
      mixedCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id !== +cellId) {
          cellItem.data.cellCount = 0
        }
      })
      freeCellArray.forEach(cellItem => {
        if (cellItem.data?.cellSelected?.id && +cellItem.data.cellSelected.id !== +cellId) {
          cellItem.data.cellCount = 0
        }
      })
    },

    getItemsWithCells(item) {
      let array = []

      let itemsInStorage = item.data?.ProformProduct?.product?.productObject?.items_in_storage || item.data?.ProformProduct?.product?.productObject?.product_in_storage || []

      itemsInStorage.map(storageItem => {
        if(storageItem.cell && +storageItem.quantity > 0)
          array.push(storageItem)
      })
      return array
    },

  }
}
</script>

<style scoped lang="scss">
@import "../../../../../../../scss/colors";

.order-admin-products{
  .red-color{
    color: $red
  }
  .green-color{
    color: $green
  }
}

.cellsForPrint {
  display: none;
}

.for-print-table {
  display: none;
}

</style>