<template>
  <div class="">
    <div class="user-info-admin">
      <div class="user-info-admin__inner">
        <div class="user-info-admin__caption">
          {{$t('consolidations_Id.localization_value.value')}}
        </div>
        <div class="user-info-admin__large">
          {{consolidation.data.id}}
        </div>
        <div class="user-info-admin__desk">
          {{ consolidation.data.createdAt | moment("DD MMM, YYYY") }}
        </div>
        <div class="user-info-admin__history noPrintable">
          <div v-if="consolidation.data.historyOfChanged"
               class="mb-3"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>
            <div class="site-link text-decoration-underline btn-style brown"
                 @click="historyOfChangesFunc('consolidation', consolidation.data.historyOfChanged)">
              {{$t('common_CheckHistory.localization_value.value')}}
            </div>
          </div>
        </div>

        <template v-if="consolidation.data.status">
          <div class="user-info-admin__status noPrintable"
               :class="{ 'in-progress' : consolidation.data.status.value === 'in_progress'}"
          >
            {{ $t(`${consolidation.data.status.translation}.localization_value.value`) }}
          </div>
        </template>

        <div class="user-info-admin__bio noPrintable">
          <router-link :to="$store.getters.GET_PATHS.mainSettingsUsers + `?userId=${user.user.id}`" class="user-info-admin__photo"
                       v-if="user.userAvatar.id && user.userAvatar.base64"
          >
            <img :src="`data:${user.userAvatar.mimeType};base64,${user.userAvatar.base64}`"
                 alt="img" width="32" height="32">
          </router-link>
          <router-link :to="$store.getters.GET_PATHS.mainSettingsUsers + `?userId=${user.user.id}`"
                       class="user-info-admin__photo background-gray border-light-brown--2px"
                       v-else>
            <img src="/img/profile-group/profile-photo-default.svg" alt="img" width="32" height="32">
          </router-link>
          <router-link :to="$store.getters.GET_PATHS.mainSettingsUsers + `?userId=${user.user.id}`"
                       class="user-info-admin__name"
                       v-if="user.user.user_personal_contact"
          >
            {{user.user.user_personal_contact['user_full_name']}}
          </router-link>

          <div
              v-if="getCountry"
              class="mr-1 ml-2 d-flex align-items-center"
              style="font-size: 12px;"
          >
            {{getCountry.code_iso_2}}
            <span class="flag-small"
                  v-bind:class="{
                    'flag-small--ua': isUkraineCountryByUserData(getCountry.id),
                    'flag-small--gb': isUnitedKingdomCountryByUserData(getCountry.id),
                    'flag-small--pl': isPolandCountryByUserData(getCountry.id),
                    'flag-small--lv': isLatviaCountryByUserData(getCountry.id),
                  }"
            ></span>
          </div>

        </div>

        <div v-if="isFromAPI && consolidation.getConsolidationDestination().id !== CONSOLIDATION_TYPE_OWN_CARRIER.id" class="user-card__body noPrintable">
          <div class="block-table">
            <div class="block-table__row mb-0">
              <div class="block-table__col mb-3">
                <TitleHorizontal
                    class="black mb-0"
                    :value="$t('consolidations_personalInfo.localization_value.value')"
                    :rightBtn="true"
                    :rightBtnType="'edit'"
                    :rightBtnText="$t('common_edit.localization_value.value')"
                    @rightBtnClick="changeInformationDataPopup()"
                />
              </div>
              <div class="block-table__col mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['consolidations_customerName'])"></div>
                  {{$t('consolidations_customerName.localization_value.value')}}
                </div>

                <div class="block-table__content">
                  <ValueHelper
                      :value="consolidation.data.senderContactName"
                  />
                </div>
              </div>
              <div class="block-table__col mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['consolidations_phoneNum'])"></div>
                  {{$t('consolidations_phoneNum.localization_value.value')}}
                </div>

                <div class="block-table__content">
                  <ValueHelper
                      :value="consolidation.data.User"
                      :deep="'user.user_personal_contact.phone'"
                  />
                </div>
              </div>

              <div class="block-table__col mb-3"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['consolidations_address'])"></div>
                <TitleHorizontal
                    class="black mb-0"
                    :value="$t('consolidations_address.localization_value.value')"
                />
              </div>

              <div class="block-table__col block-table__col--50 mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_city'])"></div>
                  {{$t('common_city.localization_value.value')}}
                </div>

                <div class="block-table__content">
                  <ValueHelper
                      :value="consolidation.data.User.user.user_personal_contact.city"
                  />
                </div>
              </div>
              <div class="block-table__col block-table__col--50 mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_region'])"></div>
                  {{$t('common_region.localization_value.value')}}
                </div>

                <div class="block-table__content">
                  <ValueHelper
                      :value="consolidation.data.User.user.user_personal_contact.region.name"
                  />
                </div>
              </div>
              <div class="block-table__col block-table__col--50 mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_country'])"></div>
                  {{$t('common_country.localization_value.value')}}
                </div>

                <div class="block-table__content">
                  <ValueHelper
                      :value="consolidation.data.User.user.user_personal_contact.country.name"
                  />
                </div>
              </div>
              <div class="block-table__col block-table__col--50 mb-3">
                <div class="block-table__label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_zipCode'])"></div>
                  {{$t('common_zipCode.localization_value.value')}}
                </div>

                <div class="block-table__content">
                  <ValueHelper
                      :value="consolidation.data.User.user.user_personal_contact.zip"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <HistoryOfChanges
          v-if="isModalHistoryOfChanges"
          @close="changeHistoryOfChanges(false)"
      />

      <ChangePersonalDataPopup
          :consolidation="consolidation"
          v-if="isModalChangePersonalDataPopup"
          @close="isModalChangePersonalDataPopup = false"
      />
    </div>
  </div>

</template>

<script>
  import HistoryOfChanges from "../../../../../popups/HistoryOfChanges/HistoryOfChanges";
  import TitleHorizontal from "../../../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import {consolidationMixinHelper} from "../../../../../../../../mixins/consolidationMixins/consolidationMixinHelper";
  import ChangePersonalDataPopup from "../../../../../popups/ChangePersonalDataPopup/ChangePersonalDataPopup";
  import {CONSOLIDATION_TYPE_OWN_CARRIER} from "../../../../../../../../staticData/staticVariables";

  export default {
    name: "UserInfo",
    components: {
      ChangePersonalDataPopup,
      ValueHelper,
      TitleHorizontal,
      HistoryOfChanges,
    },

    props: {
      consolidation: Object,
      user: Object,
    },

    mixins: [
      consolidationMixinHelper
    ],

    computed: {
      getCountry() {
        return this.getUserContactWithCheckAlternative(this.consolidation.data.User.user)?.country //this.item?.user?.user_personal_contact?.country
      },
    },

    data(){
      return{
        isModalHistoryOfChanges: false,
        isModalChangePersonalDataPopup: false,
        CONSOLIDATION_TYPE_OWN_CARRIER: CONSOLIDATION_TYPE_OWN_CARRIER,
      }
    },

    methods:{
      changeHistoryOfChanges(val){
        this.isModalHistoryOfChanges= val
      },

      changeInformationDataPopup() {
        this.isModalChangePersonalDataPopup = true
      },
    }
  }
</script>

<style scoped lang="scss">
.flag-small {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: relative;
  top: -1px;
  margin-left: 3px;

  &--ua {
    background: url("../../../../../../../../assets/img/flags/ukraine.svg") center center no-repeat;
  }

  &--gb {
    background: url("../../../../../../../../assets/img/flags/united-kingdom.svg") center center no-repeat;
  }

  &--pl {
    background: url("../../../../../../../../assets/img/flags/poland.svg") center center no-repeat;
  }

  &--lv {
    background: url("../../../../../../../../assets/img/flags/latvia.svg") center center no-repeat;
  }
}
</style>
