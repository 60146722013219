<template>
	<div class="price-card-big"
       v-bind:class="{'price-card-big__error-type': typeError}"
  >
    <div class="price-card-big__error-img" v-if="typeError">

    </div>
		<div class="price-card-big__title">
			{{value}}
		</div>
		<div class="price-card-big__txt">
			{{text}}
		</div>
	</div>
</template>

<script>
	export default {
		name: "PriceCardBig",

		// props: [
		// 	'value',
		// 	'text'
		// ],
    //
    props: {
      typeError: {
        type: Boolean,
        default: false,
      },
      value: {
        type: [String, Number],
        default: '',
      },
      text: {
        type: [String, Number],
        default: '',
      },
    },
	}
</script>

<style lang="scss">
	@import "../../../scss/colors";


	.price-card-big {
		display: flex;
		flex-direction: column;
		justify-content: center;
		background: $mainBg;
		border: 1px solid $borderBrown;
		box-sizing: border-box;
		border-radius: 5px;
		padding: 10px 15px;
		transition: .3s;
		height: 100%;
		/*cursor: pointer;*/

		&.big{
			padding: 30px 15px;
		}

    &__error-type{
      border: 1px solid $red;
    }

    &__error-img{
      width: 40px;
      height: 40px;
      background: url("../../../assets/img/common/error-mark-red.svg");
      background-size: cover;
      margin: 0 auto 15px;
    }

		/*&:hover{
			background: $borderBrown;

			.price-card-big__title{
				color: white;
			}
		}*/



		&.small &__title{
			font-size: 40px;
			line-height: 47px;
		}

		&__title {
			display: flex;
			justify-content: center;
			text-align: center;
			font-weight: bold;
			font-size: 64px;
			line-height: 75px;
			color: $borderBrown;
			margin-bottom: 5px;
			transition: .3s;
		}

		&__txt {
			display: flex;
			justify-content: center;
			text-align: center;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			color: $black;
		}
	}

</style>
