<template>
  <AccordBlock>
    <template slot="header">
      {{$t('consolidations_products.localization_value.value')}}
    </template>

    <template slot="body">
      <div v-if="!isEUProduct" class="section-label"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'consolidations_productsForConsolidation',
          ])"></div>
        {{ $t('consolidations_productsForConsolidation.localization_value.value') }}
      </div>

      <div v-if="!isEUProduct" class="custom-row noPrintable">
        <div class="custom-col">
          <div class="site-table-wrap small-table products-table"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
          'consolidations_Quantity',
          'consolidations_perItem',
          ])"></div>
            <div class="site-table-wrap table-small" v-if="!isMobileFunc()">
              <table class="site-table"
                     v-if="consolidation.data.proformProductsForTable.length > 0"
              >
                <thead>
                <tr>
                  <th>Укр</th>
                  <th>En</th>
                  <th>Матеріал</th>
                  <th>Material</th>
                  <th>Для</th>
                  <th>For</th>
                  <th class="text-lg-right white-space-line">
                    {{ $t('consolidations_Quantity.localization_value.value') }}
                  </th>
                  <th class="text-lg-right white-space-line">
                    {{ $t('consolidations_perItem.localization_value.value') }}
                  </th>
                  <th></th>
                </tr>
                </thead>
                <tbody>

                <tr
                    v-for="(item, index) in consolidation.data.proformProductsForTable"
                    :key="index"
                >
                  <td>
                    <ValueHelper
                        :value="item"
                        :deep="'product.proformData.proformTypes.1.typeValue.value.translationStorage.ua.name'"
                    />
<!--                    {{-->
<!--                      item.product.proformData.proformTypes[1].typeValue !== '' ?-->
<!--                          item.product.proformData.proformTypes[1].typeValue.value.translationStorage.ua.name : ''-->
<!--                    }}-->
                  </td>
                  <td>
                    <ValueHelper
                        :value="item"
                        :deep="'product.proformData.proformTypes.1.typeValue.value.translationStorage.en.name'"
                    />
<!--                    {{-->
<!--                      item.product.proformData.proformTypes[1].typeValue !== '' ?-->
<!--                          item.product.proformData.proformTypes[1].typeValue.value.translationStorage.en.name : ''-->
<!--                    }}-->
                  </td>
                  <td>
                    <ValueHelper
                        :value="item"
                        :deep="'product.proformData.proformTypes.2.typeValue.value.translationStorage.ua.name'"
                    />
<!--                    {{-->
<!--                      item.product.proformData.proformTypes[2].typeValue !== '' ?-->
<!--                          item.product.proformData.proformTypes[2].typeValue.value.translationStorage.ua.name : ''-->
<!--                    }}-->
                  </td>
                  <td>
                    <ValueHelper
                        :value="item"
                        :deep="'product.proformData.proformTypes.2.typeValue.value.translationStorage.en.name'"
                    />
<!--                    {{-->
<!--                      item.product.proformData.proformTypes[2].typeValue !== '' ?-->
<!--                          item.product.proformData.proformTypes[2].typeValue.value.translationStorage.en.name : ''-->
<!--                    }}-->
                  </td>
                  <td>
                    <ValueHelper
                        :value="item"
                        :deep="'product.proformData.proformTypes.5.typeValue.value.translationStorage.ua.name'"
                    />
<!--                    {{-->
<!--                      item.product.proformData.proformTypes[5].typeValue !== '' ?-->
<!--                          item.product.proformData.proformTypes[5].typeValue.value.translationStorage.ua.name : ''-->
<!--                    }}-->
                  </td>
                  <td>
                    <ValueHelper
                        :value="item"
                        :deep="'product.proformData.proformTypes.5.typeValue.value.translationStorage.en.name'"
                    />
<!--                    {{-->
<!--                      item.product.proformData.proformTypes[5].typeValue !== '' ?-->
<!--                          item.product.proformData.proformTypes[5].typeValue.value.translationStorage.en.name : ''-->
<!--                    }}-->
                  </td>
                  <td align="right">
                    <div class="table-row table-row--input">
                      <DefaultInput
                          class="right"
                          v-model="item.product.itemsQuantity"
                      />
                    </div>
                  </td>
                  <td align="right">
                    <div class="table-row table-row--input">
                      <DefaultInput
                          class="right"
                          v-model="item.product.price"
                      />
                    </div>
                  </td>
                  <td class="manage-row">
                    <div class="table-right table-manage-list justify-content-end">

                      <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) "
                                     :key="$store.getters.getGlobalUpdateManagerButton">
                        <template slot="item">
                          <LinkButton
                              :value="$t('common_delete.localization_value.value')"
                              :type="'delete'"
                              @click.native="removeItem(index)"
                          />
                        </template>
                      </ManagerButton>
                    </div>
                  </td>
                </tr>

                </tbody>
              </table>
            </div>

            <div class="site-table-mobile"
                 v-if="isMobileFunc()"
            >
              <div class="site-table-mobile__inner custom-row">
                <div class="custom-col custom-col--50 custom-col--sm-100"
                     v-for="(item, index) in consolidation.data.proformProductsForTable"
                     :key="index"
                >
                  <div class="site-table-mobile__item">
                    <div class="site-table-mobile__head">
                      <ValueHelper
                          :value="item"
                          :deep="'product.proformData.proformTypes.1.typeValue.value.translationStorage.ua.name'"
                      />
<!--                      {{-->
<!--                        item.product.proformData.proformTypes[1].typeValue !== '' ?-->
<!--                            item.product.proformData.proformTypes[1].typeValue.value.translationStorage.ua.name : ''-->
<!--                      }}-->
                    </div>
                    <div class="site-table-mobile__row custom-row mb-0">
                      <div class="site-table-mobile__piece custom-col custom-col--50">
                        En
                        <span>
                    <ValueHelper
                        :value="item"
                        :deep="'product.proformData.proformTypes.1.typeValue.value.translationStorage.en.name'"
                    />
<!--											{{-->
<!--                            item.product.proformData.proformTypes[1].typeValue !== '' ?-->
<!--                                item.product.proformData.proformTypes[1].typeValue.value.translationStorage.en.name : ''-->
<!--                          }}-->
											</span>
                      </div>
                      <div class="site-table-mobile__piece custom-col custom-col--50">
                        Матеріал
                        <span>
                    <ValueHelper
                        :value="item"
                        :deep="'product.proformData.proformTypes.2.typeValue.value.translationStorage.ua.name'"
                    />
<!--											{{-->
<!--                            item.product.proformData.proformTypes[2].typeValue !== '' ?-->
<!--                                item.product.proformData.proformTypes[2].typeValue.value.translationStorage.ua.name : ''-->
<!--                          }}-->
											</span>
                      </div>
                      <div class="site-table-mobile__piece custom-col custom-col--50">
                        Material
                        <span>
                    <ValueHelper
                        :value="item"
                        :deep="'product.proformData.proformTypes.2.typeValue.value.translationStorage.en.name'"
                    />
<!--											{{-->
<!--                            item.product.proformData.proformTypes[2].typeValue !== '' ?-->
<!--                                item.product.proformData.proformTypes[2].typeValue.value.translationStorage.en.name : ''-->
<!--                          }}-->
											</span>
                      </div>
                      <div class="site-table-mobile__piece custom-col custom-col--50">
                        Для
                        <span>
                    <ValueHelper
                        :value="item"
                        :deep="'product.proformData.proformTypes.5.typeValue.value.translationStorage.ua.name'"
                    />
<!--											{{-->
<!--                            item.product.proformData.proformTypes[5].typeValue !== '' ?-->
<!--                                item.product.proformData.proformTypes[5].typeValue.value.translationStorage.ua.name : ''-->
<!--                          }}-->
											</span>
                      </div>
                      <div class="site-table-mobile__piece custom-col custom-col--50">
                        For
                        <span>
                    <ValueHelper
                        :value="item"
                        :deep="'product.proformData.proformTypes.5.typeValue.value.translationStorage.en.name'"
                    />
<!--											{{-->
<!--                            item.product.proformData.proformTypes[5].typeValue !== '' ?-->
<!--                                item.product.proformData.proformTypes[5].typeValue.value.translationStorage.en.name : ''-->
<!--                          }}-->
											</span>
                      </div>
                    </div>
                    <div class="site-table-mobile__row custom-row">
                      <div class="site-table-mobile__piece custom-col custom-col--50">
                        {{ $t('consolidations_Quantity.localization_value.value') }}
                        <span>
																<DefaultInput
                                    v-model="item.product.itemsQuantity"
                                />
											</span>
                      </div>
                      <div class="site-table-mobile__piece custom-col custom-col--50">
                        {{ $t('consolidations_perItem.localization_value.value') }}
                        <span>
																<DefaultInput
                                    v-model="item.product.price"
                                />
											</span>
                      </div>
                    </div>
                    <div class="site-table-mobile__piece custom-col">
                      <LinkButton
                          :value="$t('common_delete.localization_value.value')"
                          :type="'delete'"
                          @click.native="removeItem(index)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="block-table__total site-line-total mb-2"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
          'consolidations_totallyPerOrder',
          ])"></div>
              {{ $t('consolidations_totallyPerOrder.localization_value.value') }}
              <span>${{ getTotalPerOrder }}</span>
            </div>
          </div>
        </div>
      </div>


      <ScannerInKiev
          v-if="!isDPD"
          class="mb-5 noPrintable"
          :key="'2' + changeProductForCellPopup"
          :consolidation="consolidation"
      />

      <div class="fragment"
           v-if="!isDPD"
           v-bind:class="{'disabled-btn' : $store.getters.getOrdersInboundBtn}"
      >
        <div class="section-label noPrintable"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['consolidations_scanner'])"></div>
          {{ $t('consolidations_scanner.localization_value.value') }}
        </div>
        <div class="custom-row">
          <div class="custom-col custom-col--50 custom-col--md-100 noPrintable">
            <div class="section-label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['fba_scannerHere'])"></div>
              <DefaultInput
                  :label="$t('fba_scannerHere.localization_value.value')"
                  :type="'text'"
                  v-model="scan"
                  @change="changeScanner"
              />
            </div>
          </div>

          <div class="custom-row" v-if="_.has(consolidation.data.orderWarehouse, 'name')">
            <div class="custom-col">
              <div class="d-flex align-center" style="height: 42px;">
                <RadioDefault
                    class="white-space-line width-initial mr-3"
                    :label="consolidation.data.orderWarehouse.name"
                    :name="'warehouse'"
                    :value="true"
                    :disabled="true"
                />
              </div>
            </div>
          </div>
        </div>

        <ProductsAdminBlock
            :consolidation="consolidation"
            :user="consolidation.data.User"
            @changeProductForCellPopup="(open) => changeProductForCellPopup = open"
        />
      </div>



    </template>
  </AccordBlock>
</template>

<script>
  import DefaultInput from "../../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import AccordBlock from "../../../../../../../coreComponents/AccordBlock/AccordBlock";
  import {mixinDetictingMobile} from "../../../../../../../../mixins/mobileFunctions";
  import ProductsAdminBlock from "../../../../chunks/ConsolidationAdminBlocks/ProductsAdminBlock/ProductsAdminBlock";
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import ManagerButton from "../../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import RadioDefault from "@/components/UI/radiobuttons/RadioDefault/RadioDefault";
  import ScannerInKiev
    from "@/components/modules/ConsolidationModule/components/ConsolidationEditing/ConsolidationEditingAdmin/components/ScannerInKiev/ScannerInKiev";
  import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
  import {addProductForCellMixin} from "@/mixins/productMixins/addProductForCellMixin";
  import {consolidationMixin} from "@/mixins/consolidationMixins/consolidationMixin";

  export default {
    name: "Scanner",
    components: {
      ValueHelper,
      ScannerInKiev,
      RadioDefault,
      ManagerButton,
      LinkButton,
      ProductsAdminBlock,
      DefaultInput,
      AccordBlock
    },

    mixins: [mixinDetictingMobile, consolidationMixin, addProductForCellMixin],

    props: {
      consolidation: Object,
    },

    computed: {
      getTotalPerOrder: function () {
        let totalPrice = 0
        this.consolidation.data.proformProductsForTable.forEach(item => {
          totalPrice += (item.product.itemsQuantity * item.product.price) || 0
        })

        return totalPrice.toFixed(2)
      },
    },

    data() {
      return {
        scan: '',

        warehouses: [],

        changeProductForCellPopup: false,
      }
    },

    mounted() {
      this.$store.dispatch('fetchWarehouseStorage').then((response) => {
        this.warehouses = this.getRespPaginateData(response)
      })
    },

    methods: {
      changeScanner(val){
        let added = false
        let foundIndex = null

        val = val.replaceAll(' ', '')

        if(val.length > 0){
          this.consolidation.data.productsForCellArray.map((item, index) => {
            if(added) return //|| item.data.scannedCount === item.data.productCount
            if(item.data.ProformProduct.product.productObject.upc_code === val){
              item.addScannedProductInbound()
              added = true
              foundIndex = index
            }
          })
        }

        if(!added) {
          this.getProductFromWarehouse(this.consolidation.data.User, val, this.consolidation.getOrderWarehouse()?.id).then(data => {
            let {product, localProductsForCellArray} = data
            let localProductForCell = this._.first(localProductsForCellArray)
            if(product) {
              this.changeProductObject(localProductForCell.data.ProformProduct, product, localProductForCell, true).then(() => {
                setTimeout(() => {
                  let checkAdded = this.addProductToArray(localProductForCell, this.consolidation.data.productsForCellArray)

                  if(!checkAdded) return

                  let addedProduct = this._.last(this.consolidation.data.productsForCellArray)
                  addedProduct.addScannedProductInbound()
                  this.openNotify('success', 'common_notificationRecordChanged')

                  let lastIndex = this.consolidation.data.productsForCellArray.length - 1
                  this.upScannedProductInArray(lastIndex)
                }, 1)
              })
            }
          })
        }

        if(foundIndex !== null) {
          this.upScannedProductInArray(foundIndex)
        }

        //check for '-' so we don't run this code if it's not a cell name
        if (val.length > 0 && val.indexOf('-') > 0 && val.toLowerCase().indexOf('cell') === 0) {
          let idValue = val.split('-')[1]
          idValue = parseInt(idValue)

          let firstProduct = this._.first(this.consolidation.data.productsForCellArray)
          firstProduct.addScannedProductToCell(idValue)

          // this.consolidation.data.productsForCellArray.forEach(item => {
          //   item.addScannedProductToCell(idValue)
          // })
        }

        this.scan = ''
      },

      removeItem(index) {
        this.consolidation.removeProformProductsForTableItem(index)
      },

      upScannedProductInArray(foundIndex) {
        let copyProductForCell = this._.clone(this.consolidation.data.productsForCellArray[foundIndex])
        this.consolidation.data.productsForCellArray.splice(foundIndex, 1)
        this.consolidation.data.productsForCellArray.unshift(copyProductForCell)

        this.changeConsolidationProductInCells()
      }
    }
  }
</script>

<style scoped lang="scss">
  .max-200{
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  .manage-block{
    min-width: 150px;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 1700px) {
      min-width: auto;
    }
  }

  .site-table-mobile__item{
    background: #f8f4ee;
  }

  .manager-btn {
    max-width: 160px;
  }
</style>
