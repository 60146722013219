<template>
  <AccordBlock>
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['consolidations_prices'])"></div>
          {{$t('consolidations_prices.localization_value.value')}}
      </div>
    </template>

    <template slot="body">

      <div class="custom-row mt-3">
        <div class="custom-col custom-col--16 custom-col--xl-25 custom-col--md-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['consolidations_shippingCostByAdmin'])"></div>
          <DefaultInput
              :label="$t('consolidations_shippingCostByAdmin.localization_value.value')"
              :type="'text'"
              :placeholder="'0'"
              v-model="consolidation.data.adminOrderAmount"
          />
          <div class="tooltip-block-wrap"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                'consolidations_setByAdmin',
                'consolidations_setByAdminTooltip',
                'consolidations_shippingCostByAdmin',
                ])"></div>
            <v-popover
                style="min-width: 24px;"
                class="site-tooltip mt--1"
                :disabled="!tooltipSetByAdmin"
                offset="5"
                placement="top"
                trigger="hover"

            >
              <span>
                <TooltipBtn/>
              </span>
              <template slot="popover">
                <p>
                  <b>{{$t('consolidations_shippingCostByAdmin.localization_value.value')}}</b>
                </p>
                <p>
                  {{$t('consolidations_setByAdminTooltip.localization_value.value')}}
                </p>
              </template>
            </v-popover>
            <b class="fsz12" style="text-align: right">{{$t('consolidations_setByAdmin.localization_value.value')}}</b>
          </div>
<!--          <InputSum-->
<!--                  :label="$t('consolidations_shippingCostByAdmin.localization_value.value')"-->
<!--                  :placeholder="'0'"-->
<!--                  :icoType="'dollar'"-->
<!--                  v-model="consolidation.data.adminOrderAmount"-->
<!--          />-->
        </div>
        <div class="custom-col custom-col--16 custom-col--xl-25 custom-col--md-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['consolidations_shippingCost'])"></div>
          <DefaultInput
              :label="$t('consolidations_shippingCost.localization_value.value')"
              :type="'text'"
              :placeholder="'e.g. 10'"
              v-model="consolidation.data.shippingCost"
              :disabled="true"
          />
<!--          <InputSum-->
<!--                  :label="$t('consolidations_shippingCost.localization_value.value')"-->
<!--                  :placeholder="'0'"-->
<!--                  :icoType="'dollar'"-->
<!--                  v-model="consolidation.data.orderAmount"-->
<!--                  :disabled="true"-->
<!--          />-->
        </div>
        <div class="custom-col custom-col--16 custom-col--xl-25 custom-col--md-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['consolidations_packaging'])"></div>
          <InputSum
                  :label="$t('consolidations_packaging.localization_value.value')"
                  :placeholder="'0'"
                  :icoType="'dollar'"
                  v-model="consolidation.data.packagingAmount"
          />
        </div>

        <div class="custom-col custom-col--16 custom-col--xl-25 custom-col--md-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['consolidations_orderProcessingFee'])"></div>
          <DefaultSelect
              :options="orderFees"
              :label="$t('consolidations_orderProcessingFee.localization_value.value')"
              v-bind:class="{'ui-no-valid': consolidation.validation.orderProcessFee}"
              :errorTxt="$t(`${consolidation.validationTranslate.orderProcessFee}.localization_value.value`)"
              :error="consolidation.validation.orderProcessFee"
              :optionsLabel="'cost'"
              @change="changeOrderAmount"
              :selected="consolidation.data.orderProcessFee"
          />
        </div>

        <div class="custom-col custom-col--16 custom-col--xl-25 custom-col--md-50"
             v-if="!isFromYourCity"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['consolidations_vatCost'])"></div>
          <InputSum
              :label="$t('consolidations_vatCost.localization_value.value')"
              :placeholder="'0'"
              :icoType="'dollar'"
              v-model="consolidation.data.vatAmount"
          />
        </div>
      </div>

<!--          v-if="(isFedex || isDHL_USA) && isSkladLodz"-->
      <PromoCode
          v-if="!isFromYourCity && showPromoFields"
          :consolidation="consolidation"
      />

    </template>
  </AccordBlock>
</template>

<script>
  import AccordBlock from "../../../../../../../coreComponents/AccordBlock/AccordBlock";
  import {mixinDetictingMobile} from "../../../../../../../../mixins/mobileFunctions";
  import InputSum from "../../../../../../../UI/inputs/InputSum/InputSum";
  import DefaultSelect from "../../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import DefaultInput from "../../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import TooltipBtn from "../../../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  import {consolidationMixinHelper} from "../../../../../../../../mixins/consolidationMixins/consolidationMixinHelper";
  import PromoCode from "@/components/modules/ConsolidationModule/components/chunks/chunks/PromoCode/PromoCode";

  export default {
    name: "Prices",
    components: {PromoCode, TooltipBtn, DefaultInput, DefaultSelect, InputSum, AccordBlock},

    mixins: [mixinDetictingMobile, consolidationMixinHelper],

    props: {
      consolidation: Object,
      orderFees: Array,
      showPromoFields: Boolean,
    },

    data() {
      return {
        tooltipSetByAdmin: true,
      }
    },

    methods: {
      changeOrderAmount(val) {
        this.consolidation.setOrderProcessFee(val)
      },
    }
  }
</script>

<style scoped lang="scss">
  .toggle-block__details {
    min-height: 300px;
  }

  .tooltip-block-wrap {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  @media (max-width: 1120px){
    .toggle-block__details {
      background: #F8F4EE;
      border: 1px solid #BBAD9C;
      min-height: initial;
    }
  }
</style>
