<template>
  <DefaultCheckbox
          :value="checkboxValue"
          @input="(val) => $emit('changeProductChecked', val)"
  />
</template>

<script>
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  export default {
    name: "ProductCheckedBlock",
    components: {DefaultCheckbox},

    props: {
      item: Object,
      type: {
        type: String,
        default: 'scannedCount'
      }
    },

    data() {
      return {
        checkboxValue: false,
      }
    },

    computed: {
      scanned() {
        return this.item.data.scannedCount
      },

      scannedInKievCount() {
        return this.item.data.scannedInKievCount
      },
    },

    watch: {
      scanned(scannedCount) {
        if(this.type !== 'scannedCount') return
        this.checkboxValue = this.item.data.productCount === scannedCount
      },
      scannedInKievCount(scannedInKievCount) {
        if(this.type !== 'scannedInKievCount') return
        this.checkboxValue = this.item.data.productCount === scannedInKievCount
      }
    },

    mounted() {
      if(this.type === 'scannedInKievCount') {
        this.checkboxValue = this.item.data.productCount === this.item.data.scannedInKievCount
      }
      if(this.type === 'scannedCount') {
        this.checkboxValue = this.item.data.productCount === this.item.data.scannedCount
      }
    }

  }
</script>

<style scoped>

</style>
