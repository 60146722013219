<template>
  <modal
      @close="$emit('close')"
      class="custom-popup buy-label medium-size"
  >
    <template slot="header">
      <div
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'consolidations_selectOrdersToAdd',
          'consolidations_selectOrder',
          'consolidations_ordersSelected',
          'common_close',
          ])"></div>
      {{$t('consolidations_selectOrdersToAdd.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-row">
        <div class="custom-col flex-column mb-5">
          <MultiSelect
              class="white mb-3"
              :options="orders"
              :label="$t('consolidations_selectOrder.localization_value.value')"
              :otherValue="'consolidationOrdersFBM'"
              :selected="consolidation.data.relatedOrders"
              :searchable="true"
              @change="changeSelectedOrders"
          />

          <span class="soft-text font-weight-normal">
            <b class="font-weight-bold">{{consolidation.data.relatedOrders.length}}</b> {{$t('consolidations_ordersSelected.localization_value.value')}}
          </span>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="buy-label__btn d-flex align-items-center">

        <MainButton
            :value="$t('common_close.localization_value.value')"
            class="buy-label__btn-i"
            @click.native="$emit('close')"
        />
      </div>
    </template>
  </modal>

</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
  import MultiSelect from "../../../../UI/selectiones/MultiSelect/MultiSelect";
  import {proformMixins} from "../../../../../mixins/proformMixins/proformMixins";

  export default {
    name: "SelectOrdersToAdd",

    components: {
      Modal,
      MainButton,
      MultiSelect,
    },

    mixins: [proformMixins],

    props: {
      orders: Array,
      consolidation: Object,
    },

    data(){
      return{

      }
    },

    methods: {
      changeSelectedOrders(val) {

          this.consolidation.setRelatedOrders(val, this)


      }
    }

  }

</script>

<style lang="scss" scoped>

</style>
