
export const userAvatarMixin = {
  methods: {
    setUserAvatar(userModel, withBigFile = false) {
      console.log('check avatar data: ',userModel);
      if(userModel.user?.avatar_files && userModel.user?.avatar_files.length > 0){
        let avatarFile = this._.find(userModel.user?.avatar_files, {type: 'small_image'})

        this.$store.dispatch('getFileFromServer', avatarFile['id'])
          .then((response) => {
            userModel.setUserAvatar({
              id: avatarFile['id'],
              base64: response[avatarFile.id],
              mimeType: avatarFile['mime_type'],
              uuid: avatarFile['uuid']
            })
          }).catch(error => this.createErrorLog(error))

        if(withBigFile){
          let avatarBigFile = this._.find(userModel.user?.avatar_files, {type: 'big_image'})

          this.$store.dispatch('getFileFromServer', avatarBigFile['id'])
            .then((response) => {
              userModel.setUserBigAvatar({
                id: avatarBigFile['id'],
                base64: response[avatarBigFile.id],
                mimeType: avatarBigFile['mime_type'],
                uuid: avatarBigFile['uuid']
              })
            }).catch(error => this.createErrorLog(error))
        }
      }
    }
  }
}
