<template>
  <div class="custom-row">
    <div class="custom-col custom-col--50 custom-col-md--100"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([selectName])"></div>
      <DefaultSelect
              class="w-100"
              :options="getOptions"
              :label="$t(`${selectName}.localization_value.value`)"
              :optionsLabel="'combo_name'"
              :selected="itemProductCell.data.cellSelected"
              v-bind:class="{'ui-no-valid': itemProductCell.validation.cellSelected}"
              :errorTxt="$t(`${itemProductCell.validationTranslate.cellSelected}.localization_value.value`)"
              :error="itemProductCell.validation.cellSelected"
              :disabled="disabledSelect"
              :clearable="false"
              @change="val => itemProductCell.setCellSelected(val)"
      />
      <!--:disabled="itemProductCell.data.cellOptions.length === 0"-->


    </div>
    <div class="custom-col custom-col--25 custom-col-md--100"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([inCellProductCountName])"></div>
      <DefaultInput
              :label="$t(`${inCellProductCountName}.localization_value.value`)"
              v-model="itemProductCell.data.storageCount"
              v-bind:class="{'ui-no-valid': itemProductCell.validation.storageCount}"
              :errorTxt="$t(`${itemProductCell.validationTranslate.storageCount}.localization_value.value`)"
              :error="itemProductCell.validation.storageCount"
              :disabled="true"
      />
    </div>
    <div class="custom-col custom-col--25 custom-col-md--100"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([sendProductCountName])"></div>
      <DefaultInput
              :label="$t(`${sendProductCountName}.localization_value.value`)"
              v-model="itemProductCell.data.cellCount"
              v-bind:class="{'ui-no-valid': itemProductCell.validation.cellCount}"
              :error="itemProductCell.validation.cellCount"
      />
      <!--:errorTxt="$t(`${itemProductCell.validationTranslate.cellCount}.localization_value.value`)"-->
    </div>

  </div>
</template>

<script>
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  export default {
    name: "ProductForCellFields",
    components: {DefaultSelect, DefaultInput},

    props: {
      itemProductCell: Object,
      selectName: String,
      inCellProductCountName: String,
      sendProductCountName: String,
      disabledSelect: {
        type: Boolean,
        default: false
      },
      mix: {
        type: Boolean,
        default: null
      }
    },

    computed: {
      getOptions() {
        if(this.mix !== null) {
          return this.itemProductCell.data.cellOptions.filter(item => {return item?.mixed === this.mix})
        }

        return this.itemProductCell.data.cellOptions
      },
    }

  }
</script>

<style scoped>

</style>
