var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm._.has(_vm.$store.getters.getProformProductInitialize, 'proformCategories'))?_c('div',{staticClass:"order-create__product custom-row"},[_c('div',{staticClass:"order-create__product-col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100",class:{
          'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate(),
          'custom-order-second': _vm.altVersion,
        }},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'proform_category',
                'product_IncludesProducts',
              ])}}}),_c('DefaultSelect',{class:{'ui-no-valid': _vm.productItem.validation.proformCategory},attrs:{"options":_vm.$store.getters.getProformProductInitialize.proformCategories,"label":_vm.$t('proform_category.localization_value.value'),"caption":_vm.categoryCaption ? _vm.$t('product_IncludesProducts.localization_value.value') : null,"error":_vm.productItem.validation.proformCategory,"errorTxt":_vm.serverError ? _vm.productItem.validationTxt.proformCategory : _vm.$t(`${_vm.productItem.validationTranslate.proformCategory}.localization_value.value`),"otherValue":'proformData',"selected":_vm.productItem.product.proformData.proformCategory},on:{"change":_vm.changeCategory}})],1),(_vm.productItem.product.proformData.proformCategory && _vm.productItem.product.proformData.proformCategory.id === _vm.PROFORM_CATEGORY_SAVED)?_c('div',{staticClass:"order-create__product-col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'product_ProformFiles',
              ])}}}),_c('DefaultSelect',{class:{'ui-no-valid': _vm.productItem.validation.proformNaming},attrs:{"options":_vm.$store.getters.getSavedProform,"label":_vm.$t('product_ProformFiles.localization_value.value'),"otherValue":'savedProforms',"error":_vm.productItem.validation.proformNaming,"errorTxt":_vm.$t(`${_vm.productItem.validationTranslate.proformNaming}.localization_value.value`)},on:{"change":_vm.changeSavedProform}})],1):_vm._e(),(_vm.productItem.product.proformData.proformCategory && _vm.productItem.product.proformData.proformCategory.id === _vm.PROFORM_CATEGORY_SEARCH)?_c('div',{staticClass:"order-create__product-col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'common_Search',
              ])}}}),_c('SearchSelect',{class:{'ui-no-valid': _vm.productItem.validation.proformNaming},attrs:{"options":_vm.options,"label":_vm.$t('common_Search.localization_value.value'),"typeSelect":'proformSearch',"functionSearch":_vm.functionSearchProform,"error":_vm.productItem.validation.proformNaming,"errorTxt":_vm.$t(`${_vm.productItem.validationTranslate.proformNaming}.localization_value.value`)},on:{"change":_vm.changeSearchProform}})],1):_vm._e(),(_vm._.has(_vm.productItem.product.proformData, 'proformTypes') &&
            Object.keys(_vm.productItem.product.proformData.proformTypes).length > 0)?[(_vm.localProformTypes = _vm.productItem.product.proformData.proformTypes)?[_vm._l((_vm.$store.getters.getProformProductInitialize.proformTypes),function(itemType,index){return [_c('div',{key:index,staticClass:"order-create__product-col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100",class:{
                  'custom-order-first' : (itemType.id === _vm.PROFORM_TYPE_NAMING && _vm.altVersion) || (itemType.id === _vm.PROFORM_TYPE_MATERIAL && _vm.altVersion)
               }},[((((itemType.id === _vm.PROFORM_TYPE_NAMING && _vm.productItem.product.proformData.proformCategory) || itemType.id !== _vm.PROFORM_TYPE_NAMING)
                && _vm.altVersion) || !_vm.altVersion)?_c('DefaultSelect',{class:{
                      'disable-click' : !_vm.productItem.product.proformData.proformCategory,
                      'ui-no-valid': _vm.productItem.validation.proformMaterial && itemType.id === _vm.PROFORM_TYPE_MATERIAL && _vm.altVersion
                    },attrs:{"options":_vm.localProformTypes[itemType.id].typeOptions,"otherValue":'proformDataProduct',"label":_vm.getProformTypeLabel(_vm.localProformTypes, itemType),"disabled":Object.keys(_vm.productItem.product.proformData.proformCategory).length > 0 &&
                      !_vm._.has(_vm.localProformTypes[itemType.id].typeOptions[0], 'proform_attachment_id'),"selected":_vm.getSelectedProformItem(_vm.localProformTypes, itemType),"clearable":itemType.id === _vm.PROFORM_TYPE_SUBMATERIAL,"error":itemType.id === _vm.PROFORM_TYPE_MATERIAL && _vm.altVersion ? _vm.productItem.validation.proformMaterial : false,"errorTxt":itemType.id === _vm.PROFORM_TYPE_MATERIAL && _vm.altVersion ?
                    _vm.$t(`${_vm.productItem.validationTranslate.proformMaterial}.localization_value.value`) : false},on:{"change":(val) => _vm.changeSelect(val, itemType.id)}}):(_vm.altVersion && itemType.id === _vm.PROFORM_TYPE_NAMING && !_vm.productItem.product.proformData.proformCategory)?_c('SearchSelect',{class:{
                  'fix-big-label': itemType.id === _vm.PROFORM_TYPE_NAMING && _vm.altVersion
                },attrs:{"label":_vm.getProformTypeLabel(_vm.localProformTypes, itemType),"typeSelect":'productNamingSearch',"options":_vm.namingSearchOptions,"functionSearch":_vm.functionSearchProformNaming},on:{"change":_vm.changeNamingProform}}):_vm._e()],1),(!_vm.isLatvian && !_vm.isUnitedKingdomCountry)?_c('div',{key:index+'1',staticClass:"order-create__product-col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100",class:{
                  'custom-order-second' : (itemType.id === _vm.PROFORM_TYPE_NAMING && _vm.altVersion) || (itemType.id === _vm.PROFORM_TYPE_MATERIAL && _vm.altVersion),
                  'custom-order-third' : itemType.id !== _vm.PROFORM_TYPE_NAMING && itemType.id !== _vm.PROFORM_TYPE_MATERIAL && _vm.altVersion,
               }},[_c('DefaultInput',{attrs:{"label":_vm.localProformTypes[itemType.id].typeName[itemType.translationStorage.en.name + '_En'].name + '(Eng)',"type":'text',"value":_vm.localProformTypes[itemType.id].typeValue === '' ?
                      _vm.localProformTypes[itemType.id].typeValue :
                      Object.keys(_vm.localProformTypes[itemType.id].typeValue.value).length > 0 ?
                        _vm.localProformTypes[itemType.id].typeValue.value.translationStorage.en.name : '',"disabled":true}})],1):_vm._e()]})]:_vm._e()]:_vm._e(),_c('div',{staticClass:"order-create__product-col custom-col custom-col--md-100 custom-col--sm-100 mb-0 position-relative",class:{
           'custom-col--66' : _vm.visibleHSCode,
           'custom-col--33' : !_vm.visibleHSCode,
         }},[_c('div',{staticClass:"order-create__product-row custom-row"},[(_vm.visibleHSCode)?_c('div',{staticClass:"order-create__product-col custom-col custom-col--50 custom-col--sm-100"},[_c('div',{staticClass:"order-create__product-input",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'common_ItemsQty',
              ])}}}),_c('DefaultInput',{class:{'ui-no-valid': _vm.productItem.validation.HSCode},attrs:{"label":_vm.$t('common_HSCode.localization_value.value'),"disabled":!_vm.canChangeHSCode,"error":_vm.productItem.validation.HSCode,"errorTxt":_vm.$t(`${_vm.productItem.validationTranslate.HSCode}.localization_value.value`)},model:{value:(_vm.productItem.product.HSCode),callback:function ($$v) {_vm.$set(_vm.productItem.product, "HSCode", $$v)},expression:"productItem.product.HSCode"}})],1)]):_vm._e(),_c('div',{staticClass:"order-create__product-col custom-col custom-col--25 custom-col--sm-50",class:{
               'custom-col--25' : _vm.visibleHSCode,
               'custom-col--50' : !_vm.visibleHSCode,
             }},[_c('div',{staticClass:"order-create__product-input",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'common_ItemsQty',
              ])}}}),_c('InputQuantity',{class:{'ui-no-valid': _vm.productItem.validation.itemsQuantity},attrs:{"value":_vm.productItem.product.itemsQuantity,"label":_vm.$t('common_ItemsQty.localization_value.value'),"error":_vm.productItem.validation.itemsQuantity,"errorTxt":_vm.$t(`${_vm.productItem.validationTranslate.itemsQuantity}.localization_value.value`)},on:{"change":_vm.changeValueQuantity}})],1)]),_c('div',{staticClass:"order-create__product-col custom-col custom-col--sm-50",class:{
               'custom-col--25' : _vm.visibleHSCode,
               'custom-col--50' : !_vm.visibleHSCode,
             }},[_c('div',{staticClass:"order-create__product-input",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'common_PricePerItem',
              ])}}}),_c('InputSum',{class:{'ui-no-valid': _vm.productItem.validation.price},attrs:{"value":_vm.setPriceValue(_vm.productItem.product.price),"label":_vm.$t('common_PricePerItem.localization_value.value'),"icoType":'dollar',"placeholder":'0',"error":_vm.productItem.validation.price,"errorTxt":_vm.$t(`common_MoreThanZero.localization_value.value`)},on:{"change":_vm.changeValueSum}})],1),(!_vm.isMobileFunc() &&
               _vm.productItem.product.proformData.proformCategory !== '' &&
               !_vm.isAdmin &&
               !_vm.hideChangeProformProduct)?_c('div',{staticClass:"order-create__product-add-to-profile",on:{"click":_vm.changeProformProduct}},[[_c('v-popover',{staticClass:"site-tooltip",attrs:{"offset":"5","placement":"top","trigger":"hover"}},[_c('span',[_c('AddToProfileIco')],1),_c('template',{slot:"popover"},[_c('p',[_vm._v(_vm._s(_vm.$t('common_ChangeProduct.localization_value.value')))])])],2)]],2):_vm._e()])])]),(_vm.nameForLabel)?_c('div',{staticClass:"order-create__product-col custom-col custom-col--50"},[_c('div',{staticClass:"order-create__product-input",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                'common_nameForLabel',
              ])}}}),_c('DefaultInput',{attrs:{"label":_vm.$t('common_nameForLabel.localization_value.value')},model:{value:(_vm.productItem.product.nameForLabel),callback:function ($$v) {_vm.$set(_vm.productItem.product, "nameForLabel", $$v)},expression:"productItem.product.nameForLabel"}})],1)]):_vm._e(),(
         _vm.productItem.product.proformData.proformCategory !== '' &&
         !_vm.isAdmin &&
         !_vm.hideAddProfile)?_c('div',{staticClass:"custom-col",on:{"click":_vm.addToProfile}},[_c('div',{staticClass:"order-create__product-add-to-profile position-static"},[[_c('div',{staticClass:"order-create__product-add-to-profile position-static"},[_c('span',{staticClass:"order-create__product-add-to-profile-mobile"},[_c('AddToProfileIco'),_c('div',{staticClass:"order-create__product-add-to-profile-mobile--text site-btn"},[_vm._v(_vm._s(_vm.$t('common_AddProfile.localization_value.value')))])],1)])]],2)]):_vm._e(),(_vm.isMobileFunc() &&
         _vm.productItem.product.proformData.proformCategory !== '' &&
         !_vm.isAdmin &&
         !_vm.hideChangeProformProduct)?_c('div',{staticClass:"custom-col",on:{"click":_vm.changeProformProduct}},[_c('div',{staticClass:"order-create__product-add-to-profile"},[[_c('div',{staticClass:"order-create__product-add-to-profile"},[_c('span',{staticClass:"order-create__product-add-to-profile-mobile"},[_c('AddToProfileIco'),_c('div',{staticClass:"order-create__product-add-to-profile-mobile--text site-btn"},[_vm._v(_vm._s(_vm.$t('common_ChangeProduct.localization_value.value')))])],1)])]],2)]):_vm._e(),_c('div',{staticClass:"order-create__product-col custom-col",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                          'product_Handmade',
                        ])}}}),_c('DefaultCheckbox',{staticClass:"wfc",attrs:{"label":_vm.$t('product_Handmade.localization_value.value')},model:{value:(_vm.productItem.product.handMade),callback:function ($$v) {_vm.$set(_vm.productItem.product, "handMade", $$v)},expression:"productItem.product.handMade"}})],1),_c('div',{staticClass:"order-create__product-col custom-col position-relative mb-4",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                          'product_UpdateProform',
                        ])}}}),(_vm.product.product.proformArchived || _vm.productItem.product.proformArchived)?_c('MainButton',{staticClass:"buy-label__btn-i wfc mb-4",class:{'disabled-btn' : _vm.$store.getters.getProformDataBtn},attrs:{"value":_vm.$t('product_UpdateProform.localization_value.value')},nativeOn:{"click":function($event){return _vm.updateArchivedProform.apply(null, arguments)}}}):_vm._e(),(_vm.productItem.validation.proformArchived)?_c('span',{staticClass:"textarea-default__error error-field",staticStyle:{"right":"auto","margin-left":"17px","margin-top":"5px","font-size":"14px"}},[_vm._v(_vm._s(_vm.$t(`${_vm.productItem.validationTranslate.proformArchived}.localization_value.value`))+" ")]):_vm._e()],1)],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }