<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup history-of-changes-popup"
  >
    <template slot="header">
      History of Changes
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="soft-text mb-5">
          to the product
        </div>
        <div class="site-table-wrap table-small">
          <div class="history-table__wrap">
            <div class="history-table">
            <div class="history-table__head">
              <div class="history-table__row">
                <div class="history-table__col">
                  Date
                </div>
                <div class="history-table__col">
                  Users
                </div>
                <div class="history-table__col">
                  Action
                </div>
                <div class="history-table__col"></div>
              </div>
            </div>
            <div class="history-table__body">
              <div class="history-table__row"
                  v-for="(item, i) in list"
                  :key="i"
              >
                <div class="history-table__col">
                  {{item.date}}
                </div>
                <div class="history-table__col">
                  {{item.users}}
                </div>
                <div class="history-table__col">
                  {{item.action}}
                </div>
                <div class="history-table__col">
                  <span  v-if="item.show !== undefined" class="history-table__arrow"
                         @click="item.show = !item.show"
                         :class="{'show' : item.show}"
                  ></span>
                </div>

                <div class="history-table__col history-table__col--100 p-0"
                     v-if="item.sublist"
                >
                  <div class="history-table__sub"
                      v-if="item.show"
                  >
                    <div class="history-table__sub-head">
                      <div class="history-table__sub-row">
                        <div class="history-table__sub-col">
                          Fields
                        </div>
                        <div class="history-table__sub-col">
                          Was
                        </div>
                        <div class="history-table__sub-col">
                          Became
                        </div>
                        <div class="history-table__sub-col">

                        </div>
                      </div>
                    </div>

                    <div class="history-table__sub-body">
                      <div class="history-table__sub-row"
                           v-for="(subItem, j) in item.sublist"
                           :key="j"
                      >
                        <div class="history-table__sub-col">
                          {{subItem.fields}}
                        </div>
                        <div class="history-table__sub-col">
                          {{subItem.was}}
                        </div>
                        <div class="history-table__sub-col">
                          {{subItem.became}}
                        </div>
                        <div class="history-table__sub-col"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";

  export default {
    name: "HistoryOfChanges",
    components: {
      Modal,
    },

    data() {
      return {
        list: [
          {
            date: '24 Aug, 2020',
            users: 'koval Vladislav',
            action: 'Create',
            show: false,

            sublist: [
              {
                fields: 'Tracking Number Tracking Number',
                was: '3423feuyu3trt487r 3423feuyu3trt487r',
                became: 'u9r3478yhrf4ut376rf u9r3478yhrf4ut376rf',
              },
            ],
          },
          {
            date: '24 Aug, 2020',
            users: 'koval Vladislav',
            action: 'Editing',
          },
          {
            date: '24 Aug, 2020',
            users: 'koval Vladislav',
            action: 'Editing',
            show: false,

            sublist: [
              {
                fields: 'Tracking Number Tracking Number',
                was: '3423feuyu3trt487r 3423feuyu3trt487r',
                became: 'u9r3478yhrf4ut376rf u9r3478yhrf4ut376rf',
              },
            ],
          },
        ]
      }
    },

    methods: {

    }
  }
</script>

<style lang="scss">
  @import "../../../../../scss/mixins/mixins";
  @import "../../../../../scss/colors";

  .history-of-changes-popup {

    .modal-component__inner {
      max-width: 649px;
    }
  }

  .history-table{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    //overflow-x: auto;

    &__wrap{
      min-width: 560px;

      @include for-720{
        min-width: auto;
        border: 2px solid $borderBrownOpacity04;
        border-radius: 5px;
      }
    }

    &__head{
      background: $borderBrown;

      .history-table__col{
        font-size: 14px;
        line-height: 16px;
        color: white;
      }

      @include for-720{
        display: none;
      }
    }
    &__row{
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid $borderBrownOpacity04;

      @include for-720{
        flex-wrap: wrap;
        position: relative;
        padding-top: 8px;
      }
    }
    &__col{
      font-size: 14px;
      line-height: 16px;
      color: $black;
      display: flex;
      align-items: center;
      padding: 15px;

      @include for-720{
        width: 100%;
        padding: 5px 15px;
      }

      @include from-720 {
        box-sizing: content-box;

        &:nth-child(1) {
          min-width: 120px;
          max-width: 120px;
        }

        &:nth-child(2) {
          min-width: 170px;
          max-width: 170px;
        }

        &:nth-child(3) {
          min-width: 100px;
          max-width: 150px;
        }

        &:nth-child(4) {
          min-width: 40px;
          margin-left: auto;
          box-sizing: border-box;
        }
      }
    }

    &__body{
      display: flex;
      flex-direction: column;
    }
    &__arrow{
      width: 20px;
      height: 12px;
      position: relative;
      cursor: pointer;
      background: url("../../../../../assets/img/common/arr-brown.svg") center/12px no-repeat;

      &.show{
        transform: rotate(-180deg);
      }

      &:before{
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 40px;
        height: 40px;
        transform: translate(-50%, -50%);
      }

      @include for-720{
        position: absolute;
        right: 5px;
        top: 5px;
        height: 35px;
        width: 35px;
      }
    }
    &__col--100{
      width: 100%;

      @include for-720{
        overflow: hidden;
      }
    }
    &__sub{

      @include for-720{
        overflow-x: auto;
      }
    }
    &__sub-head{

      .history-table__sub-col{
        padding-top: 10px;
        padding-bottom: 10px;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: $black;
      }

      .history-table__sub-col{
        background: #DBD4CB;
      }
    }
    &__sub-row{
      display: flex;

    }

    &__sub-col{
      font-size: 12px;
      line-height: 14px;
      color: $black;
      background: $borderBrownOpacity02;

      @include for-720{
        box-sizing: border-box;
      }

      &:nth-child(1){
        min-width: 120px;
        max-width: 120px;
      }

      &:nth-child(2){
        min-width: 170px;
        max-width: 170px;

        @include for-720{
          min-width: 120px;
          max-width: 120px;
        }
      }

      &:nth-child(3){
        min-width: 120px;
      }

      &:nth-child(4){
        width: 100%;
      }
    }
    &__sub-body{

    }
    &__sub-row{

    }
    &__sub-col{
      display: flex;
      align-items: center;
      padding: 15px;
      box-sizing: content-box;
    }
  }

</style>
