<template>
  <div class="fragment">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'consolidations_addFromWarehouse',
          ])"></div>
      {{$t('consolidations_addFromWarehouse.localization_value.value')}}
    </div>

    <div class="custom-row">
      <div class="custom-col">
        <div class="d-flex">
          <RadioDefault
              class="white-space-line width-initial mr-3"
              v-for="(item, index) in warehouses"
              :key="index"
              :label="item.name"
              :name="'warehouse'"
              :value="consolidation.data.orderWarehouse && item.id === consolidation.data.orderWarehouse.id"
              @input="changeWarehouse(item)"
          />
        </div>
      </div>
    </div>

    <template v-for="(productItem, index) in products">
      <template>
        <div class="order-create__title-horizontal"
             :key="index + '1'"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
						'product_Product',
						'common_DeleteProduct',
					])"></div>
          <TitleHorizontal
              :value="$t('product_Product.localization_value.value') + ' #' + parseInt(index + 1)"
              :rightBtn="true"
              :rightBtnType="'delete'"
              :rightBtnText="$t('common_DeleteProduct.localization_value.value')"
              @rightBtnClick="removeProformProductByLocalType(products, index)"
          />
          <div class="custom-row">
            <div class="custom-col"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate([
                  'product_ProductName',
                ])"></span>
              <DefaultSelect
                  class="w-100"
                  :label="$t('product_ProductName.localization_value.value')"
                  :otherValue="'product'"
                  :optionsLabel="'name_for_fbm'"
                  :options="optionsProducts"
                  v-bind:class="{'ui-no-valid': productItem.data.ProformProduct.validation.productName ||
                  (products.length > 1 && productItem.data.ProformProduct.validation.productObject)}"
                  :errorTxt="serverError ? productItem.data.ProformProduct.validationTxt.productName : $t(`${productItem.data.ProformProduct.validationTranslate.productName}.localization_value.value`)"
                  :error="productItem.data.ProformProduct.validation.productName"
                  :selected="productItem.data.ProformProduct.product.productObject"
                  @change="(val) => changeProductObjectByLocal(productItem.data.ProformProduct, val, productItem)"
              />
            </div>
          </div>
        </div>

        <ProductItemCreation
            v-if="productItem.data.ProformProduct.product.productName"
            :key="index"
            :product="productItem.data.ProformProduct"
            :serverError="serverError"
            :categoryCaption="false"
            :hideAddProfile="true"
            :canChangeHSCode="true"
            :altVersion="true"
            :hideChangeProformProduct="false"
            @changeCategory="({val, nameVal}) => changeCategoryProformByLocalType({val, nameVal, item: products[index]})"
            @changeProformSelectItem="changeProformSelectItem(products[index].data.ProformProduct)"
        />
      </template>

<!--      @changeSomethingField="changeSomethingField(item)"-->
    </template>

    <div class="order-create__product-btn add-product-btn-wrap"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['common_AddProduct'])"></div>

      <span v-if="!localProducts"
            class="order-create__product-btn-i add-product-btn site-link"
            @click="addProductForCellsConsolidation">
          + {{$t('common_AddProduct.localization_value.value')}}
        </span>

      <span v-else
            class="order-create__product-btn-i add-product-btn site-link"
            @click="$emit('addLocalProduct')">
          + {{$t('common_AddProduct.localization_value.value')}}
        </span>
    </div>

    <div class="site-line-total"
         v-if="isDPD || isEUProduct"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
						'common_TotalShippingCost',
					])"></div>
      {{ $t('consolidations_vatCost.localization_value.value') }}

      <span>${{ getVatCost() }}</span>
    </div>

  </div>
</template>

<script>
import {consolidationMixin} from "../../../../../../../mixins/consolidationMixins/consolidationMixin";
import ProductItemCreation from "../../../../../../coreComponents/ProductItemCreation/ProductItemCreation";
import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
import TitleHorizontal from "../../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
import {proformMixins} from "../../../../../../../mixins/proformMixins/proformMixins";
import {productForCellMixin} from "../../../../../../../mixins/productMixins/productForCellMixin";
import RadioDefault from "@/components/UI/radiobuttons/RadioDefault/RadioDefault";

export default {
  name: "AddProductFromWarehouse",

  components: {
    RadioDefault,
    TitleHorizontal,
    DefaultSelect,
    ProductItemCreation,
  },

  mixins: [
    productForCellMixin,
    consolidationMixin,
    proformMixins
  ],

  watch: {
    orderUserId: function (id) {
      this.getProductsByUserId(id)
    },

  },

  props:{
    consolidation: Object,
    user: Object,
    products: Array,
    orderUserId: {
      type: [Number, String]
    },
    localProducts: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      optionsProducts: [],
      serverError: false,

      warehouses: [],
    }
  },

  mounted() {

    this.$store.dispatch('fetchWarehouseStorage').then((response) => {
      let data = this.getRespPaginateData(response)
      if(data.length === 0) return

      if(this.isFedex || this.isDHL_USA || this.isFromYourCity) {
        this.warehouses = [this._.find(data, {id: 1})]
      } else if(this.isDPD) {
        this.warehouses = [this._.find(data, {id: 2})]
      }
      else if (this.isEUProduct) {
        this.warehouses = data.filter(item => {
          //когда создаем, то подставляется с гет параметра (в initConsolidationCreate()), когда эдитим, то нам он приходит.
          return item.id === +this.consolidation.getOrderWarehouse()?.id
        })
      }

      if(this.isEUProduct && this.$route.query.initStorage) {
        this.consolidation.setOrderWarehouse(this.warehouses.find(item => {
          return item.id === +this.$route.query.initStorage
        }))
      }

          // this.warehouses = this.getRespPaginateData(response)

      if(!this.consolidation.data.orderWarehouse) {
        this.consolidation.data.orderWarehouse = this._.first(this.warehouses)
      }

      this.getProductsByUserId(this.user.getUserId())
    })

  },

  methods: {

    changeWarehouse(val) {
      this.consolidation.data.orderWarehouse = val
      this.getProductsByUserId(this.user.getUserId()).then(() => {
        this.products.map((item, index) => {
          let product = this._.find(this.optionsProducts, {id: item.data.ProformProduct.product.productObject?.id})
          if(product) {
            this.products[index].data.ProformProduct.setProductObject(product)
          }
        })
      })
    },

    async getProductsByUserId(id) {
      const query = new this.Query();
      let url = '?';
      let myQuery = query
          .for('posts')

      if (this.isAdmin && !id) return

      if(this.isAdmin){
        if(!this.localProducts) {
          myQuery.where('ProductUserId', id)
        }
      }

      if (this.isAdmin && this.$route.query.initStorage && !this.consolidation.data.orderWarehouse?.id) {
        this.consolidation.setOrderWarehouse({id: +this.$route.query.initStorage})

        this.$store.dispatch('fetchWarehouseStorage').then((response) => {
          let data = this.getRespPaginateData(response)
          if(data.length === 0) return

          this.warehouses = data.filter(item => {
            //когда создаем, то подставляется с гет параметра (в initConsolidationCreate()), когда эдитим, то нам он приходит.
            return item.id === +this.consolidation.getOrderWarehouse()?.id
          })

          this.consolidation.setOrderWarehouse(this.warehouses.find(item => {
            return item.id === +this.$route.query.initStorage
          }))

          if(!this.consolidation.data.orderWarehouse) {
            this.consolidation.data.orderWarehouse = this._.first(this.warehouses)
          }

          this.getProductsByUserId(this.user.getUserId())
        })
      }

      myQuery.where('ProductInDraft', '0')

      myQuery.where('byStorageId', this.consolidation.data.orderWarehouse?.id)

      url = url + myQuery.limit(100000).page(parseInt(0)).url().split('?')[1]

      let typeFetch
      if(this.localProducts) {
        typeFetch = 'fetchProductsWithCells'
        url = url + `&user_id=${id}`
      } else {
        typeFetch = 'fetchProducts'
      }

      return this.$store.dispatch(typeFetch, url).then((response) => {
        if(this.localProducts) {
          this.optionsProducts = this.getRespData(response)
        } else {
          this.optionsProducts = this.getRespPaginateData(response)
        }
      })
    },

    getProductByLocalType(productItem){
      if(this.localProducts){
        return productItem.data.ProformProduct
      }

      return productItem.data.ProformProduct
    },

    changeCategoryProformByLocalType({val, nameVal, item}){
      // let product = item
      // if(this.localProducts){
      let product = item.data.ProformProduct
      // }

      this.changeCategoryProform({val, nameVal, item: product}, null, true)
    },

    removeProformProductByLocalType(products, index){
      if(this.localProducts){
        this.$emit('removeLocalProduct', index)
      } else {
        this.consolidation.removeProductForCells(products, index)
      }
    },

    changeProductObjectByLocal(item, val, productForCell) {
      if(this.localProducts){
        this.changeProductObject(item, val, productForCell)
      } else {
        this.changeProductObject(item, val)
      }
    },

    addProductForCellsConsolidation() {
      let newItemIndex = this.consolidation.addProductForCells()
      //init proform for cells
      this.initializeProductProformArray(this.consolidation.data.productsForCellArray, newItemIndex)
    }
  }
}
</script>

<style scoped>

</style>