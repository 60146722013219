

export const productMixins = {

  methods: {

    toggleArchive(productId, toArchive) {
      console.log(toArchive);
      let text = {
        title: toArchive ? 'common_toArchive' : 'common_fromArchive',
        txt: '',
        yes: 'common_yes',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        this.$store.dispatch('archiveProduct', productId).then((response) => {
          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.$emit('reload')
              this.openNotify('success', 'common_notificationRecordChanged')
              break
            }
            /**
             * Validation Error
             */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
            /**
             * Undefined Error
             */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },

    getProductsByUserId(userId){
      const query = new this.Query();
      let url = '?';
      let myQuery = query
          .for('posts')

      if(this.isAdmin){
        myQuery.where('ProductUserId', userId)
      }

      url = url + myQuery.limit(100000).page(parseInt(0)).url().split('?')[1]

      this.$store.dispatch('fetchProducts', url)
    },


    updateProduct(draft, editInAnotherModule = false) {
      if (this.isAdmin) {
        if (!this.product.productCreateFirstValidationUser()) return
        if (!this.product.productCreateFirstValidation()) return
        if (!this.product.productCreateSecondValidation()) return
      }

      //
      let data = this.product.prepareProduct(
        draft,
        this.$store.getters.getIsAdminRights === 'user' ?
          this.$store.getters.GET_COMMON_AUTHORIZED.user.id : '',
        this.isAdmin
      )
      this.$store.dispatch('updateProducts', {id: this.product.product.productObject?.id, data: data}).then((response) => {


        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            if(editInAnotherModule){
              this.openNotify('success', 'common_notificationRecordChanged')
              return
            }
            console.log(this.product.product);
            if (this.product.product.Files.data.files.length > 0) {
              this.updateFiles(this.$route.params.id)
            } else {
              if (this.$store.getters.getProductsFilter.length > 0) {
                this.$router.push(this.$store.getters.GET_PATHS.products + this.$store.getters.getProductsFilter)
              } else {
                this.$router.push(this.$store.getters.GET_PATHS.products)
              }
            }
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }

        // if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
        //   // this.openNotify('success', 'common_notificationRecordCreated')
        //
        //   if(editInAnotherModule){
        //     this.openNotify('success', 'common_notificationRecordChanged')
        //     return
        //   }
        //   console.log(this.product.product);
        //   if (this.product.product.Files.data.files.length > 0) {
        //     this.updateFiles(this.$route.params.id)
        //   } else {
        //     if (this.$store.getters.getProductsFilter.length > 0) {
        //       this.$router.push(this.$store.getters.GET_PATHS.products + this.$store.getters.getProductsFilter)
        //     } else {
        //       this.$router.push(this.$store.getters.GET_PATHS.products)
        //     }
        //   }
        //
        // } else if (response.data.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
        //   alert('novalid')
        // } else {
        //   this.openNotify('error', 'common_notificationUndefinedError')
        // }


      })
    },

    updateFiles(productId) {
      // console.log(productId);
      // let data = {}
      let reader = new FormData();
      this.product.product.Files.data.files.map((item, index) => {
        if (!this._.has(item, 'maxCount') && !item.maxSizeError) {
          reader.append("file" + (index + 1), item.file);
        }
      })
      reader.append("product_id", productId);

      this.$store.dispatch('addPhotoToProduct', reader).then((response) => {

        if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
          this.openNotify('success', 'common_notificationRecordCreated')

          if (this.$store.getters.getProductsFilter.length > 0) {
            this.$router.push(this.$store.getters.GET_PATHS.products + this.$store.getters.getProductsFilter)
          } else {
            this.$router.push(this.$store.getters.GET_PATHS.products)
          }

        } else if (response.response.data.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
          alert('novalid')
        } else {
          this.openNotify('error', 'common_notificationUndefinedError')
        }

        console.log(response);
      })
    },
  }
}
