<template>
  <div>
    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
           v-if="checkExtraChargeData && labelPrice.out_of_delivery.extra_charge && labelPrice.out_of_delivery.extra_charge > 0"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['express_RemoteAreaDeliverySurcharge'])"></div>
        <PriceCardBig
                v-bind:class="{big: bigCards, 'small-size': !bigCards}"
                :value="$options.filters.currency(labelPrice.out_of_delivery.extra_charge)"
                :text="$t('express_RemoteAreaDeliverySurcharge.localization_value.value')"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
           v-if="checkExtraChargeData && labelPrice.out_of_delivery.extra_charge"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['express_CostDelivery'])"></div>
        <PriceCardBig
                v-bind:class="{big: bigCards, 'small-size': !bigCards}"
                :value="$options.filters.currency(labelPrice.initial_price)"
                :text="$t('express_CostDelivery.localization_value.value')"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
           v-if="checkExtraChargeData && labelPrice.out_of_delivery.shipping_cost"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['express_CostDelivery'])"></div>
        <PriceCardBig
                v-bind:class="{big: bigCards, 'small-size': !bigCards}"
                :value="$options.filters.currency(labelPrice.out_of_delivery.shipping_cost)"
                :text="$t('express_CostDelivery.localization_value.value')"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
           v-if="labelPrice.additional_handling_surcharge > 0"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['express_AdditionalHandlingSurchargeWeight'])"></div>
        <PriceCardBig
            v-bind:class="{big: bigCards, 'small-size': !bigCards}"
            :value="$options.filters.currency(labelPrice.additional_handling_surcharge)"
            :text="$t('express_AdditionalHandlingSurchargeWeight.localization_value.value')"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
           v-if="labelPrice.insurance"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
										'express_InsurancePrice',
									])"></div>
        <PriceCardBig
                v-bind:class="{big: bigCards, 'small-size': !bigCards}"
                :value="$options.filters.currency(labelPrice.insurance)"
                :text="$t('express_InsurancePrice.localization_value.value')"
        />
      </div>

      <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
           v-if="labelPrice.orderFee"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
										'express_OrderProcessing',
									])"></div>
        <PriceCardBig
                v-bind:class="{big: bigCards, 'small-size': !bigCards}"
                :value="$options.filters.currency(labelPrice.orderFee)"
                :text="$t('express_OrderProcessing.localization_value.value')"
        />
      </div>

      <template v-if="labelPrice.price && !checkExtraChargeData">
<!--        IF HAS VARIANT EXPRESS PRICE-->
        <template v-if="labelPrice.price_variant && labelPrice.price_variant.length > 0 && withVariantPrice">
          <div class="order-create__col custom-col custom-col--33 custom-col--sm-100">
            <RadioDefault
                :label="labelPrice.label"
                :name="'variant'"
                class="radio-style"
                :value="currentVariant === null || currentVariant === labelPrice.delivery_service_type"
                @change.native="$emit('changeVariant', {delivery_service_type: labelPrice.delivery_service_type ? labelPrice.delivery_service_type : null})"
            />
            <PriceCardBig
                v-bind:class="{big: bigCards, 'small-size': !bigCards, 'gray-card' : currentVariant !== null && currentVariant !== labelPrice.delivery_service_type}"
                :value="$options.filters.currency(labelPrice.price)"
                :text="$t('express_CostDelivery.localization_value.value')"
            />
          </div>

          <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
               v-for="(variant, index) in labelPrice.price_variant"
               :key="index">
            <RadioDefault
                :label="variant.label"
                :name="'variant'"
                class="radio-style"
                :value="currentVariant === variant.delivery_service_type"
                @change.native="$emit('changeVariant', variant)"
            />
            <PriceCardBig
                v-bind:class="{big: bigCards, 'small-size': !bigCards, 'gray-card' : currentVariant !== variant.delivery_service_type}"
                :value="$options.filters.currency(variant.price)"
                :text="$t('express_CostDelivery.localization_value.value')"
            />
          </div>
        </template>

        <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
             v-else
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['express_CostDelivery'])"></div>
          <PriceCardBig
              v-bind:class="{big: bigCards, 'small-size': !bigCards}"
              :value="$options.filters.currency(labelPrice.price)"
              :text="$t('express_CostDelivery.localization_value.value')"
          />
        </div>
      </template>


      <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
           v-if="labelPrice.oversize_charge && labelPrice.oversize_charge !== 0"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['express_oversizeCharge'])"></div>
        <PriceCardBig
                v-bind:class="{big: bigCards, 'small-size': !bigCards}"
                :value="$options.filters.currency(labelPrice.oversize_charge)"
                :text="$t('express_oversizeCharge.localization_value.value')"
        />
      </div>


      <!--   FOR FMB FIELDS   -->
      <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
           v-if="labelPrice.giftMassage && !checkExtraChargeData"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_GiftMessage'])"></div>
        <PriceCardBig
                v-bind:class="{big: bigCards, 'small-size': !bigCards}"
                :value="$options.filters.currency(labelPrice.giftMassage)"
                :text="$t('fbm_GiftMessage.localization_value.value')"
        />
      </div>

      <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
           v-if="labelPrice.insuranceFromDeliveryCompany && !checkExtraChargeData && Number(labelPrice.insuranceFromDeliveryCompany) !== 0"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_InsuranceExtended'])"></div>
        <PriceCardBig
                v-bind:class="{big: bigCards, 'small-size': !bigCards}"
                :value="$options.filters.currency(labelPrice.insuranceFromDeliveryCompany)"
                :text="$t('fbm_InsuranceExtended.localization_value.value')"
        />
      </div>

      <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
           v-if="labelPrice.orderProcessFee && !checkExtraChargeData"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_OrderPrice'])"></div>
        <PriceCardBig
                v-bind:class="{big: bigCards, 'small-size': !bigCards}"
                :value="$options.filters.currency(labelPrice.orderProcessFee)"
                :text="$t('fbm_OrderPrice.localization_value.value')"
        />
      </div>

      <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
           v-if="labelPrice.orderPackingPrice && !checkExtraChargeData"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_Packaging'])"></div>
        <PriceCardBig
                v-bind:class="{big: bigCards, 'small-size': !bigCards}"
                :value="$options.filters.currency(labelPrice.orderPackingPrice)"
                :text="$t('fbm_Packaging.localization_value.value')"
        />
      </div>

      <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
           v-if="labelPrice.orderGiftPackingPrice && !checkExtraChargeData"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_giftPackaging'])"></div>
        <PriceCardBig
            v-bind:class="{big: bigCards, 'small-size': !bigCards}"
            :value="$options.filters.currency(labelPrice.orderGiftPackingPrice)"
            :text="$t('fbm_giftPackaging.localization_value.value')"
        />
      </div>

      <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
           v-if="labelPrice.insurancePrice && !checkExtraChargeData"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_insurancePrice'])"></div>
        <PriceCardBig
                v-bind:class="{big: bigCards, 'small-size': !bigCards}"
                :value="$options.filters.currency(labelPrice.insurancePrice)"
                :text="$t('fbm_insurancePrice.localization_value.value')"
        />
      </div>

      <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
           v-if="labelPrice.skladUSAFeePrice && !checkExtraChargeData"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_SkladUsaFee'])"></div>
        <PriceCardBig
            v-bind:class="{big: bigCards, 'small-size': !bigCards}"
            :value="$options.filters.currency(labelPrice.skladUSAFeePrice)"
            :text="$t('fbm_SkladUsaFee.localization_value.value')"
        />
      </div>

      <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
           v-if="labelPrice.engraving"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_EngravingFile'])"></div>
        <PriceCardBig
            v-bind:class="{big: bigCards, 'small-size': !bigCards}"
            :value="$options.filters.currency(labelPrice.engraving)"
            :text="$t('fbm_EngravingFile.localization_value.value')"
        />
      </div>

      <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
           v-if="labelPrice.prepayError && !checkExtraChargeData"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_NoPricesAvailable'])"></div>
        <PriceCardBig
            :typeError="true"
            v-bind:class="{big: bigCards, 'small-size': !bigCards}"
            :value="''"
            :text="labelPrice.prepayError"
        />
      </div>

    </div>

    <div class="order-create__row custom-row"
         v-if="dearCustomer"
    >
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['express_DearCustomer','express_DearCustomerPlease', 'express_DearCustomerByAgreeing'])"></div>
        <div class="order-create__col custom-col">
          <div class="buy-label-notification"
               v-html="$t('express_DearCustomer.localization_value.value') +
                         $t('express_DearCustomerPlease.localization_value.value') + $t('express_DearCustomerByAgreeing.localization_value.value')"></div>
        </div>
      </div>
    </div>

    <div class="order-create__total"
         v-if="totalPrice"
         v-bind:class="{
          'small': !bigCards,
          'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()
         }">
      <div class="admin-edit" @click="editTranslate([totalShippingCostTranslate,])"></div>
      {{$t(`${totalShippingCostTranslate}.localization_value.value`)}}
      <span>
				{{ labelPrice.total_price | currency }}
			</span>
    </div>
  </div>
</template>

<script>
  import PriceCardBig from "../PriceCardBig/PriceCardBig";
  import RadioDefault from "@/components/UI/radiobuttons/RadioDefault/RadioDefault";

  export default {
    name: "LabelPrice",
    components: {RadioDefault, PriceCardBig},

    props: {
      labelPrice: Object,
      bigCards: {
        type: Boolean,
        default: false,
      },
      dearCustomer: {
        type: Boolean,
        default: true,
      },
      totalPrice: {
        type: Boolean,
        default: true,
      },
      currentVariant: {
        type: String,
        default: null,
      },
      withVariantPrice: {
        type: Boolean,
        default: true,
      },
      totalShippingCostTranslate: {
        type: String,
        default: 'common_TotalShippingCost',
      },
    },

    computed: {
      getTotalPrice(){
        return (
          (this.labelPrice.insurance ? parseFloat(this.labelPrice.insurance) : 0) +
          (this.labelPrice.orderFee ? parseFloat(this.labelPrice.orderFee) : 0) +
          (this.labelPrice.price ? parseFloat(this.labelPrice.price) : 0)
        )
      },

      checkExtraChargeData(){
        if(!Object.hasOwnProperty.call(this.labelPrice, 'out_of_delivery'))
          return false

        if(Object.hasOwnProperty.call(this.labelPrice, 'out_of_delivery') &&
            (!this.labelPrice.out_of_delivery.extra_charge || Number(this.labelPrice.out_of_delivery.extra_charge) === 0)){
          return false
        }
        return true
      }
    },

  }
</script>

<style scoped lang="scss">

  /deep/ .price-card-big.small-size .price-card-big__title{
    font-size: 46px;
  }

  .order-create__total.small span{
    font-size: 24px;
    line-height: 28px;
  }

  .gray-card {
    background: #e0e0e0;
  }

  .radio-style {
    margin-bottom: -31px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }

</style>
