<template>
  <div class="fragment" v-if="consolidation.data.User.userId">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'consolidations_personalInfo',
          ])"></div>
      {{$t('consolidations_personalInfo.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'consolidations_customerName',
          ])"></div>
        <DefaultInput
            :label="$t('consolidations_customerName.localization_value.value')"
            :type="'text'"
            v-bind:class="{'ui-no-valid': consolidation.validation.senderContactName}"
            :errorTxt="$t(`${consolidation.validationTranslate.senderContactName}.localization_value.value`)"
            :error="consolidation.validation.senderContactName"
            v-model="consolidation.data.senderContactName"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'consolidations_phoneNum',
          ])"></div>
        <DefaultInput
            :label="$t('consolidations_phoneNum.localization_value.value')"
            :type="'text'"
            :value="consolidation.data.User.user.user_personal_contact.phone"
            :disabled="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";

export default {
  name: "PersonalInformation",

  components: {
    DefaultInput
  },

  props: {
    consolidation: Object,
  },

  mounted() {
    if (this.consolidation.getSenderContactName() === '') {
      this.consolidation.setSenderContactName(this.consolidation.data.User.user.user_personal_contact?.user_full_name)
    }
  }
}
</script>

<style scoped>

</style>