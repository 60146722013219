<template>
  <div class="fragment">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'consolidations_deliveryTime',
          'consolidations_shippingDate',
          'consolidations_received',
          ])"></div>
      {{$t('consolidations_deliveryTime.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100">
        <DatePickerDefault
            :label="$t('consolidations_shippingDate.localization_value.value')"
            :placeholder="$t('common_dateYYYY_MM_DD.localization_value.value')"
            v-bind:class="{'ui-no-valid': consolidation.validation.shippedAt}"
            :errorTxt="$t(`${consolidation.validationTranslate.shippedAt}.localization_value.value`)"
            :error="consolidation.validation.shippedAt"
            v-model="consolidation.data.shippedAt"
        >
          <template slot="body">
            <date-picker
                v-model="consolidation.data.shippedAt"
                ref="datePicker"
                valueType="format"
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'YYYY-MM-DD'"
                :placeholder="$t('common_dateYYYY_MM_DD.localization_value.value')"
                :disabled-date="(date) => getRangeDeliveryDate(date)"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div v-if="isAdmin" class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100">
        <DatePickerDefault
            :label="$t('consolidations_received.localization_value.value')"
            :placeholder="$t('common_dateYYYY_MM_DD.localization_value.value')"
            v-bind:class="{'ui-no-valid': consolidation.validation.receivedAt}"
            :errorTxt="$t(`${consolidation.validationTranslate.receivedAt}.localization_value.value`)"
            :error="consolidation.validation.receivedAt"
            v-model="consolidation.data.receivedAt"
        >
          <template slot="body">
            <date-picker
                v-model="consolidation.data.receivedAt"
                ref="datePicker"
                valueType="format"
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'YYYY-MM-DD'"
                :placeholder="$t('common_dateYYYY_MM_DD.localization_value.value')"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>
    </div>
  </div>
</template>

<script>
import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
import DatePicker from "vue2-datepicker";
import {CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY, UKRAINIAN_COUNTRY_ID} from "../../../../../../../staticData/staticVariables";
import {userDataMixin} from "../../../../../../../mixins/globalMixins/userDataMixin";

export default {
  name: "ShippingDate",

  components: {
    DatePickerDefault,
    DatePicker
  },

  props: {
    consolidation: Object
  },

  mixins: [userDataMixin],

  data() {
    return {
      date: '',
    }
  },

  methods: {
    getRangeDeliveryDate(date) {

      let userContacts = this.getUserContactDataFromUser(this.consolidation.data.User.user)

      if(this.getUserAlternativeContact(this.consolidation.data.User.user)) {
        userContacts = this.getUserAlternativeContact(this.consolidation.data.User.user)
      }
      if(this.consolidation.getConsolidationDestination()?.id === CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id &&
          (this.consolidation.data.sendType === this.consolidation.data.sendTypes.warehouse && userContacts?.country?.id === UKRAINIAN_COUNTRY_ID ||
              this.consolidation.data.sendType === this.consolidation.data.sendTypes.sender && userContacts?.country?.id === UKRAINIAN_COUNTRY_ID)
      ){
        return (date < new Date().setDate(new Date().getDate() + 3) &&
            date > new Date().setDate(new Date().getDate() - 1000))
      }
      return false
    },
  }
}
</script>

<style scoped>

</style>