<template>
  <div class="fragment">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'consolidations_shippingPartner',
          'consolidations_shippingPartnerText',
          ])"></div>
      {{$t('consolidations_shippingPartner.localization_value.value')}}

      <v-popover
          class="site-tooltip"
          :disabled="!tooltipAddProductActive"
          offset="5"
          placement="top"
          trigger="hover"

      >

        <TooltipBtn/>

        <template slot="popover">
          <p>
            <b>{{$t('consolidations_shippingPartner.localization_value.value')}}</b>
          </p>
          <p>
            {{$t('consolidations_shippingPartnerText.localization_value.value')}}
          </p>

        </template>
      </v-popover>
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col">
        <div class="order-create__toggle-block toggle-block small-size">
          <div class="toggle-block__type">
            <div class="toggle-block__type-list">
              <template v-for="(item, index) in destinationData">
                <div :key="index" class="toggle-block__type-item mb-3">
                    <RadioDefault
                        v-bind:class="{
                          'order-create__head-title destination': adminEdit
                        }"
                        :label="item.currentTranslate.name"
                        :value="checkActiveDestination(item)"
                        :disabled="(isFromYourCity && consolidation.getLabelFileUUID() !== '') ||
                            (CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY.id === item.id && (
                                (!isUseAlternativeContact && isUkrainian) || isUserAlternativeContactUkraine))"
                        name="'destination'"
                        @input="changeDestination(item)"
                    >
                      <template slot="card">
                        <!--                        {{consolidation.getOrderWarehouse()}}-->
                        <!--                        {{(isEUProduct && consolidation.getOrderWarehouse() && consolidation.getOrderWarehouse().name ? ' (' + consolidation.getOrderWarehouse().name + ')' : '')}}-->
                        <span v-if="isEUProduct && consolidation.getOrderWarehouse() && consolidation.getOrderWarehouse().name"
                              v-bind:class="{
                                'cambridge-style' : consolidation.getOrderWarehouse().name === 'Cambridge' && adminEdit,
                                'blaine-style' : consolidation.getOrderWarehouse().name === 'Blaine' && adminEdit,
                                'lodz-style' : consolidation.getOrderWarehouse().name === 'Lodz' && adminEdit,
                              }"
                        >
                          ({{ consolidation.getOrderWarehouse().name }})
                        </span>
                        <span v-if="isDPD && adminEdit"
                              v-bind:class="{
                                'blaine-style' : isDPD,
                              }"
                        >
                          (Wroclaw)
                        </span>
                      </template>
                    </RadioDefault>
                </div>
              </template>
            </div>
          </div>
          <div
              class="toggle-block__details"
              v-bind:class="{'ui-no-valid': consolidation.validation.warehouse}"
              v-if="getWarehouses && getWarehouses.length > 0 && loadingFedexWarehouses && !adminEdit && !isFromYourCity"
          >
            <div class="toggle-block__details-body"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate([
                'common_city',
                ])"></div>
              <div class="section-label mb-2">
                {{$t('common_city.localization_value.value')}}
              </div>
              <div class="custom-row">
                <template v-for="(warehouse, indexWarehouse) in getWarehouses">
                  <div :key="indexWarehouse" class="custom-col mb-2">
                    <RadioDefault
                        :label="warehouse.currentTranslate.name"
                        :value="checkActiveWarehouse(warehouse)"
                        :errorTxt="$t(`${consolidation.validationTranslate.warehouse}.localization_value.value`)"
                        :error="consolidation.validation.warehouse"
                        name="'warehouse'"
                        @input="changeWarehouse(warehouse)"
                    >
                    </RadioDefault>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RadioDefault from "../../../../../../UI/radiobuttons/RadioDefault/RadioDefault";
import TooltipBtn from "../../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
import {consolidationMixinHelper} from "../../../../../../../mixins/consolidationMixins/consolidationMixinHelper";
import {CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY} from "@/staticData/staticVariables";

export default {
  name: "ShippingPartner",

  components: {
    TooltipBtn,
    RadioDefault
  },

  mixins: [consolidationMixinHelper],

  props: {
    destinationData: Array,
    consolidation: Object,
    loadingFedexWarehouses: {
      type: Boolean,
      default: true,
    },
    adminEdit: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    // getting warehouses data based on destination
    getWarehouses() {

      // If fedexWarehouses is not empty => return fedexWarehouses
      if(this.consolidation.data.fedexWarehouses.length > 0) {
        return this.consolidation.data.fedexWarehouses
      }

      //return the default warehouse if in certain country group
      let warehouseCode = ''
      if((this.isBelarus && !this.isAdmin) ||
          (this.isAdmin && this.isBelarusByUserData(this.consolidation.data.User?.user?.user_personal_contact?.country_group))) {
        warehouseCode = 'MINSK01'
      }
      if((this.isRussiaCountry && !this.isAdmin) ||
          (this.isAdmin && this.isRussiaCountryByUserData(this.consolidation.data.User?.user))) {
        warehouseCode = 'MOSCOW01'
      }
      if((this.isBaltic && !this.isAdmin) ||
          (this.isAdmin && this.isBalticByUserData(this.consolidation.data.User?.user?.user_personal_contact?.country_group))) {
        warehouseCode = 'Riga1'
      }

      if (warehouseCode !== '' && this.consolidation.getConsolidationDestination().warehouses &&
          this.consolidation.getConsolidationDestination().warehouses.length > 0) {
        return this.consolidation.getConsolidationDestination().warehouses.filter(item => {
          return item.code === warehouseCode
        })
      }
      if (warehouseCode === '' && this.consolidation.getConsolidationDestination().warehouses &&
          this.consolidation.getConsolidationDestination().warehouses.length > 0) {
        let filteredWarehouses = ['MINSK01', 'MOSCOW01', 'Riga1']
        return this.consolidation.getConsolidationDestination().warehouses.filter(item => {
          return !filteredWarehouses.includes(item.code)
        })
      }

      return this.consolidation.getConsolidationDestination().warehouses
    }
  },

  data() {
    return {
      tooltipAddProductActive: true,
      CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY: CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY,
    }
  },

  methods: {
    changeDestination(item) {
      this.consolidation.setConsolidationDestination(item)
    },

    checkActiveDestination(item) {
      return this.consolidation.getConsolidationDestination()?.id === item.id
    },

    changeWarehouse(item) {
      this.consolidation.setWarehouse(item)
    },

    checkActiveWarehouse(item) {
      if(this.isBelarus || this.isRussiaCountry || this.isBaltic ||
          (this.isAdmin && this.isBelarusByUserData(this.consolidation.data.User?.user?.user_personal_contact?.country_group)) ||
          (this.isAdmin && this.isRussiaCountryByUserData(this.consolidation.data.User?.user)) ||
          (this.isAdmin && this.isBalticByUserData(this.consolidation.data.User?.user?.user_personal_contact?.country_group))) {
        return true
      }

      return this.consolidation.getWarehouse()?.id === item.id
    },

  }
}
</script>

<style scoped>
  .toggle-block__details.ui-no-valid {
    border: 1px solid #F2994A;
  }

  .destination >>> .radio-default__label {
    font-size: 20px;
    color: #4D148C;
  }

  .cambridge-style {
    color: #f44336;
  }

  .blaine-style {
    color: #03a9f4;
  }

  .lodz-style {
    color: #4caf50;
  }

</style>