<template>
  <div class="quantity-input"
       v-bind:class="{
          'disabled' : disabled == true
         }"
  >
    <div class="quantity-input__btn quantity-input__btn--minus"
        @click="minusHandler"
    >
      <MinusIco/>
    </div>
    <label class="quantity-input__label">
      <span> {{ label }} </span>
      <input type="text"
             class="quantity-input__input"
             :value="valueQuantity"
             :disabled="disabled === true"
             @input="quantityChange"
      >
    </label>
    <div class="quantity-input__btn quantity-input__btn--plus"
         @click="plusHandler"
    >
      <PlusIco/>
    </div>
    <span class="default-input-wrap__error error-field" v-if="error && errorTxt">{{errorTxt}}</span>
  </div>
</template>

<script>
  import MinusIco from '../../../../../public/img/UI-group/q-ty-minus.svg?inline'
  import PlusIco from '../../../../../public/img/UI-group/q-ty-plus.svg?inline'


  export default {
    name: "InputQuantity",
    components: {
      MinusIco,
      PlusIco,
    },

    props: [
      'value',
      'error',
      'errorTxt',
      'label',
      'disabled',
    ],

    watch: {
      value: function(newVal){
        this.valueQuantity = newVal
      },
    },

    data(){
      return {
        valueQuantity: '',
      }
    },

    mounted(){
      this.valueQuantity = this.value;
    },

    methods: {
      minusHandler(){
        this.valueQuantity  > 0 ? this.valueQuantity = (this.valueQuantity - 1) : false;
        this.quantityValueHandler(this.valueQuantity)
      },

      plusHandler(){
        this.valueQuantity = this.valueQuantity + 1;
        this.quantityValueHandler(this.valueQuantity)
      },

      quantityChange(el){
        let val
        if(this.$options.filters.number(el.target.value, '0,0') === 'NaN') {
          val = this.value
        } else {
          val = parseInt(this.$options.filters.number(el.target.value, '0,0').replace(',',''))
        }
        this.$emit('change', val);
      },

      quantityValueHandler(val){
        if(this.$options.filters.number(val, '0,0') === 'NaN') {
          val = this.value
        } else {
          val = parseInt(this.$options.filters.number(val, '0,0').replace(',',''))
        }
        this.$emit('change', val);
      }
    }

  }
</script>

<style lang="scss">
  @import "../../../../scss/colors";
  @import "../../../../scss/mixins/mixins";

  .quantity-input{
    position: relative;
    height: 42px;
    border: 1px solid $borderBrown;
    border-radius: 5px;
    display: flex;
    max-width: 137px;
    width: 100%;
    background: white;

    &.disabled {
      .quantity-input__btn{
        opacity: 0.5;
        color: #000000;
        pointer-events: none;
      }
    }

    &.ui-no-valid {
      border: 2px solid $orange;
    }

    .error-field{
      margin-top: 2px;
    }

    @include for-680{
      margin-top: 15px;
    }

    &__btn{
      height: 100%;
      width: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer
    }

    &__label{

      span{
        position: absolute;
        font-size: 10px;
        line-height: 12px;
        left: 0;
        bottom: calc(100% + 4px);
        color: $borderBrown;
      }
    }

    &__input{
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
      width: 45px;
      text-align: center;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0;
      background: $mainBg;
      color: $black;

      &:disabled {
        background: $brownOpacity02;
        color: #000000;
      }
    }

  }

</style>
