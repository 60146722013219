var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[_c('div',{staticClass:"order-create__section-label section-label",class:{'admin-edit-item': _vm.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
      'consolidations_documents',
    ])}}}),_vm._v(" "+_vm._s(_vm.$t('consolidations_documents.localization_value.value'))+" ")]),_c('div',{staticClass:"order-create__row custom-row"},[_c('div',{staticClass:"order-create__col custom-col mb-3",class:{'admin-edit-item': _vm.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'consolidations_toxicLabel',
        'consolidations_toxicLabelHelp',
        'express_DropBallTest',
      ])}}}),_c('DefaultCheckbox',{attrs:{"label":_vm.$t('consolidations_toxicLabel.localization_value.value')},model:{value:(_vm.consolidation.data.toxicSubstance),callback:function ($$v) {_vm.$set(_vm.consolidation.data, "toxicSubstance", $$v)},expression:"consolidation.data.toxicSubstance"}},[_c('template',{slot:"content"},[_c('v-popover',{staticClass:"site-tooltip mt--1",attrs:{"disabled":!_vm.tooltipAddProductActive,"offset":"5","placement":"top","trigger":"hover"}},[_c('span',[_c('TooltipBtn')],1),_c('template',{slot:"popover"},[_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('consolidations_toxicLabel.localization_value.value')))])]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('consolidations_toxicLabelHelp.localization_value.value'))+" ")])])],2)],1)],2)],1),_c('div',{staticClass:"order-create__col custom-col mb-3"},[_c('DefaultCheckbox',{attrs:{"label":_vm.$t('express_DropBallTest.localization_value.value')},model:{value:(_vm.consolidation.data.dropCertification),callback:function ($$v) {_vm.$set(_vm.consolidation.data, "dropCertification", $$v)},expression:"consolidation.data.dropCertification"}},[_c('template',{slot:"content"},[_c('v-popover',{staticClass:"site-tooltip mt--1",attrs:{"disabled":!_vm.tooltipAddProductActive,"offset":"5","placement":"top","trigger":"hover"}},[_c('span',[_c('TooltipBtn')],1),_c('template',{slot:"popover"},[_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('express_DropBallTest.localization_value.value')))])]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('consolidations_toxicLabelHelp.localization_value.value'))+" ")])])],2)],1)],2)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }