var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[_c('div',{staticClass:"section-label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['consolidations_scannerInKiev'])}}}),_vm._v(" "+_vm._s(_vm.$t('consolidations_scannerInKiev.localization_value.value'))+" ")]),_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-col custom-col--50 custom-col--md-100"},[_c('div',{staticClass:"section-label"},[_c('DefaultInput',{attrs:{"label":_vm.$t('fba_scannerHere.localization_value.value'),"type":'text'},on:{"change":_vm.changeScanner},model:{value:(_vm.scan),callback:function ($$v) {_vm.scan=$$v},expression:"scan"}})],1)])]),_c('div',{staticClass:"custom-row order-admin-products"},[(_vm.consolidation.data.productsForCellArray.length > 0)?_c('div',{staticClass:"custom-col"},[(!_vm.isMobileFunc())?_c('div',{staticClass:"site-table-wrap table-small",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['productForCell_scannedCount','fbm_ProductName','fba_Cells'])}}}),_c('table',{staticClass:"site-table"},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"20px"}}),_c('th',{staticStyle:{"width":"100px"}},[_vm._v(_vm._s(_vm.$t('productForCell_scannedCount.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('fbm_ProductName.localization_value.value')))])])]),_c('tbody',_vm._l((_vm.consolidation.data.productsForCellArray),function(item,index){return _c('tr',{key:index},[_c('td',[_c('ProductCheckedBlock',{attrs:{"item":item,"type":'scannedInKievCount'},on:{"changeProductChecked":(val) => _vm.changeProductChecked(val, item)}})],1),_c('td',{class:{
              'red-color' : item.data.scannedInKievCount !== item.data.productCount,
              'green-color' : item.data.scannedInKievCount === item.data.productCount
            }},[_vm._v(" "+_vm._s(item.data.scannedInKievCount)+" / "+_vm._s(item.data.productCount)+" ")]),_c('td',[_c('div',{staticClass:"table-row ellipsis max-200"},[_c('ValueHelper',{attrs:{"value":item.data.ProformProduct,"deep":'product.productObject.name_for_fbm'}})],1)])])}),0)])]):_vm._e(),(_vm.isMobileFunc())?_c('div',{staticClass:"site-table-mobile"},[_c('div',{staticClass:"site-table-mobile__inner custom-row"},_vm._l((_vm.consolidation.data.productsForCellArray),function(item,index){return _c('div',{key:index,staticClass:"custom-col custom-col--50 custom-col--sm-100"},[_c('div',{staticClass:"site-table-mobile__item bg--main-bg"},[_c('div',{staticClass:"site-table-mobile__head"},[_c('ProductCheckedBlock',{attrs:{"item":item},on:{"changeProductChecked":(val) => _vm.changeProductChecked(val, item)}}),_c('ValueHelper',{attrs:{"value":item.data.ProformProduct,"deep":'product.productObject.name_for_fba'}})],1),_c('div',{staticClass:"site-table-mobile__row custom-row"},[_c('div',{staticClass:"site-table-mobile__piece custom-col"},[_vm._v(" "+_vm._s(_vm.$t('productForCell_scannedCount.localization_value.value'))+" "),_c('span',{class:{
                    'red-color' : item.data.scannedInKievCount !== item.data.productCount,
                    'green-color' : item.data.scannedInKievCount === item.data.productCount
                  }},[_vm._v(" "+_vm._s(item.data.scannedInKievCount)+" / "+_vm._s(item.data.productCount)+" ")])])]),_c('div',{staticClass:"site-table-mobile__row custom-row"},[_c('div',{staticClass:"site-table-mobile__piece custom-col"},[_vm._v(" "+_vm._s(_vm.$t('fbm_ProductName.localization_value.value'))+" "),_c('span',{class:{
                    'red-color' : item.data.scannedInKievCount !== item.data.productCount,
                    'green-color' : item.data.scannedInKievCount === item.data.productCount
                  }},[_c('ValueHelper',{attrs:{"value":item.data.ProformProduct,"deep":'product.productObject.name_for_fbm'}})],1)])])])])}),0)]):_vm._e()]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }