import {checkValid, phoneReplace} from "./functions/ModelsValidate";
const _ = require('lodash');

export function PersonalInformation() {

  this.checkValid = checkValid
  this.phoneReplace = phoneReplace

  this.information = {
    personalName: '',
    personalSurName: '',
    personalPhone: '',
    personalEmail: '',
    personalCompany: '',
  }

  this.validation = {
    personalName: false,
    personalSurName: false,
    personalPhone: false,
    personalEmail: false,
    personalCompany: false,
  }

  this.validationTranslate = {
    personalName: '',
    personalSurName: '',
    personalPhone: '',
    personalEmail: '',
    personalCompany: '',
  }

  this.validationTxt = {
    personalName: false,
    personalSurName: false,
    personalPhone: false,
    personalEmail: false,
    personalCompany: false,
  }

}


/*****************   SETTERS   ******************/
PersonalInformation.prototype.setPersonalName = function (val) {
  this.information.personalName = val;
}

PersonalInformation.prototype.setPersonalSurName = function (val) {
  this.information.personalSurName = val;
}

PersonalInformation.prototype.setPersonalPhone = function (val) {
  this.information.personalPhone = val;
}

PersonalInformation.prototype.setPersonalEmail = function (val) {
  this.information.personalEmail = val;
}

PersonalInformation.prototype.setPersonalCompany = function (val) {
  this.information.personalCompany = val;
}

PersonalInformation.prototype.setPersonalItem = function (val) {
  this.information.personalName = val.personalName ? val.personalName : '';
  // this.information.personalPhone = this.phoneReplace(val.personalPhone);
  this.information.personalPhone = val.personalPhone;
  this.information.personalEmail = val.personalEmail ? val.personalEmail : '';
  this.information.personalCompany = val.personalCompany ? val.personalCompany : '';
}


/*****************   GETTERS   ******************/
PersonalInformation.prototype.getPersonalName = function () {
  return this.information.personalName;
}

PersonalInformation.prototype.getPersonalSurName = function () {
  return this.information.personalSurName;
}

PersonalInformation.prototype.getPersonalPhone = function () {
  return this.information.personalPhone;
}

PersonalInformation.prototype.getPersonalEmail = function () {
  return this.information.personalEmail;
}

PersonalInformation.prototype.getPersonalCompany = function () {
  return this.information.personalCompany;
}

PersonalInformation.prototype.getPersonalCompany = function () {
  return this.information.personalCompany;
}


/*****************   VALIDATION   ******************/

PersonalInformation.prototype.personalInfoValidate = function () {

  let validationItems = {
    personalName: this.information.personalName,
    personalPhone: this.information.personalPhone,
    personalEmail: this.information.personalEmail,
  }

  let validationOptions = {
    personalName: {type: ['latin', 'empty']},
    // personalPhone: {type: ['phone', 'empty']},
    personalPhone: {type: ['empty']},
    personalEmail: {type: ['email']},
  }

  return this.checkValid(validationItems, validationOptions)
}

PersonalInformation.prototype.personalInfoPartsValidate = function (
    {
      personalName,
      personalSurName,
      personalPhone,
      personalCompany,
      personalEmail,

      phoneEmpty,
      phoneNumber,
      phoneOptional,
      emailOptional,
      nameNoLatin
    }) {

  let validationItems = {
    personalName: this.information.personalName,
    personalSurName: this.information.personalSurName,
    personalPhone: this.information.personalPhone,
    personalCompany: this.information.personalCompany,
    personalEmail: this.information.personalEmail,
  }

  let validationOptions = {
    personalName: {type: personalName ? ['latin', 'empty'] : []},
    personalSurName: {type: personalSurName ? ['latin', 'empty'] : []},
    personalPhone: {type: personalPhone ? ['empty'] : []},
    personalCompany: {type: personalCompany ? ['latin', 'empty'] : []},
    personalEmail: {type: personalEmail ? ['email', 'empty'] : []},
  }


  if (phoneEmpty){
    validationOptions.personalPhone.type.push('empty')
  }

  if (phoneNumber){
    validationOptions.personalPhone.type.push('numeric')
  }

  if (phoneOptional){
    validationOptions.personalPhone.type.push('phone')
  }

  if (emailOptional){
    validationOptions.personalEmail.type.push('email')
  }

  if (nameNoLatin){
    _.remove(validationOptions.personalName.type,
      function (n) {
        return n === 'latin'
    })
  }

  return this.checkValid(validationItems, validationOptions)
}


PersonalInformation.prototype.preparePersonalInformation = function (){
  return {
    personalName: this.information.personalName,
    personalSurName: this.information.personalSurName,
    personalPhone: this.phoneReplace(this.information.personalPhone),
    personalCompany: this.information.personalCompany,
    personalEmail: this.information.personalEmail,
  }
}
