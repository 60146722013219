<template>
  <div class="fragment">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
        'consolidations_documents',
      ])"></div>
      {{$t('consolidations_documents.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col mb-3"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'consolidations_toxicLabel',
          'consolidations_toxicLabelHelp',
          'express_DropBallTest',
        ])"></div>
        <DefaultCheckbox
            :label="$t('consolidations_toxicLabel.localization_value.value')"
            v-model="consolidation.data.toxicSubstance"
        >
          <template slot="content">
            <v-popover
                class="site-tooltip mt--1"
                :disabled="!tooltipAddProductActive"
                offset="5"
                placement="top"
                trigger="hover"

            >
              <span>
                <TooltipBtn/>
              </span>
              <template slot="popover">
                <p>
                  <b>{{$t('consolidations_toxicLabel.localization_value.value')}}</b>
                </p>
                <p>
                  {{$t('consolidations_toxicLabelHelp.localization_value.value')}}
                </p>
              </template>
            </v-popover>
          </template>
        </DefaultCheckbox>
      </div>
      <div class="order-create__col custom-col mb-3">
        <DefaultCheckbox
            :label="$t('express_DropBallTest.localization_value.value')"
            v-model="consolidation.data.dropCertification"
        >
          <template slot="content">
            <v-popover
                class="site-tooltip mt--1"
                :disabled="!tooltipAddProductActive"
                offset="5"
                placement="top"
                trigger="hover"

            >
                          <span>
                            <TooltipBtn/>
                          </span>
              <template slot="popover">
                <p>
                  <b>{{$t('express_DropBallTest.localization_value.value')}}</b>
                </p>
                <p>
                  {{$t('consolidations_toxicLabelHelp.localization_value.value')}}
                </p>
              </template>
            </v-popover>
          </template>
        </DefaultCheckbox>
      </div>

    </div>
  </div>
</template>

<script>
import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import TooltipBtn from "../../../../../../UI/tooltips/TooltipBtn/TooltipBtn";

export default {
  name: "DocumentsBlock",

  components: {
    TooltipBtn,
    DefaultCheckbox
  },

  props: {
    consolidation: Object,
  },

  data() {
    return {
      tooltipAddProductActive: true,
    }
  }
}
</script>

<style scoped>

</style>