import {PAYMENT_TYPE} from "../../staticData/staticVariables";
// import {fieldNumbers} from "../../services/validation";
import {mapActions, mapGetters} from "vuex";

export const paymentBlockFunc = {

  computed: {
    ...mapGetters([
      'GET_ERRORS',
      'getUserSettings',
    ])
  },

  methods: {
    ...mapActions([
      'newPaymentPayPall',
      'newPaymentAuthorize',
    ]),

    createInternalPayment() {
      if (this.bankFeeds === 0) {
        this.validation.sumToPay = true
        return
      } else {
        this.validation.sumToPay = false
      }

      if (this.payType === PAYMENT_TYPE.payPall) {
        this.payPayPall()
      }

      if (this.payType === PAYMENT_TYPE.authorize) {
        this.changeAuthorizePaymentPopup(true)
      }

      if (this.payType === PAYMENT_TYPE.braintree) {
        this.changeBraintreePaymentPopup(true)
      }
    },

    payPayPall() {
      let data = {
        "from_dashboard": "1",
        "items": {
          "0": {
            "price": this.bankFeeds
          },
        }
      }
      this.newPaymentPayPall(data).then(response => {
        if (this._.has(response, 'data') && response.status === this.GET_ERRORS.SUCCESS_CODE) {
          if (response.data.data.status === true) {
            window.open(response.data.data.approvalLink, '_blank');
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        }
      })
    },

    payAuthorize(data) {

      let postData = {
        card: {
          "number": data.card,
          "expiryMonth": data.month,
          "expiryYear": data.year,
          "code": data.cardCode,
        },
        "contact": {
          "firstName": data.firstName,
          "lastName": data.lastName,
          "phone": data.phone,
          "city": data.city,
          "zip": data.zipCode,
          "email": "ssdsd@sadsad.sad2",
          "address": data.street,
          "state": data.state,
          "country": data.selectedCountry
        },
        "from_dashboard": 1,
        "amount": this.bankFeeds
      }

      // Only for order prepay
      if(data?.balanceAmount && data.balanceAmount > 0) {
        postData['balance_amount'] = data.balanceAmount
      }

      this.serverError = false

      return this.newPaymentAuthorize(postData).then(response => {
        if (this._.has(response, 'data') && response.status === this.GET_ERRORS.SUCCESS_CODE) {
          if (response.data.data.status === true) {

            this.authorizePaymentPopup = false
            setTimeout(() => {
              this.changeThankYouPopupPopup(true)
            }, 200)

          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        } else if (response.response.status === this.GET_ERRORS.VALIDATION_ERROR_CODE) {
          let errors = response.response.data.errors

          this.serverError = true
          this.authorizeError = errors

        } else {
          this.openNotify('error', 'common_notificationUndefinedError')
        }
      })

    },

    payAuthorizeInvoice({data, invoiceId}) {

      let postData = {
        "card": {
          "number": data.card,
          "expiryMonth": data.month,
          "expiryYear": data.year,
          "code": data.cardCode,
        },
        "contact": {
          "firstName": data.firstName,
          "lastName": data.lastName,
          "phone": data.phone,
          "city": data.city,
          "zip": data.zipCode,
          "email": "ssdsd@sadsad.sad2",
          "address": data.street,
          "state": data.state,
          "country": data.selectedCountry
        },
        "invoice_id": invoiceId,
      }

      this.serverError = false

      return new Promise((resolve) => {

        this.$store.dispatch('newPaymentAuthorizeInvoice', postData).then(response => {
          if (this._.has(response, 'data') && response.status === this.GET_ERRORS.SUCCESS_CODE) {
            if (response.data.data.status === true) {

              resolve(true)
              return

            } else {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          } else if (response.response.status === this.GET_ERRORS.VALIDATION_ERROR_CODE) {
            let errors = response.response.data.errors

            this.serverError = true
            this.authorizeError = errors

            resolve(false)

          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
          }

          resolve(false)
        })
      })

    },

    changeAuthorizePaymentPopup(val) {
      this.authorizePaymentPopup = val
    },

    changeBraintreePaymentPopup(val) {
      this.braintreePaymentPopup = val
    },

    changeThankYouPopupPopup(val) {
      this.thankYouPopupPopup = val
    },

    changeInput(value) {
      if(parseFloat(value) === 0){
        this.sumToPay = ''
      } else {
        this.sumToPay = value
      }

      // if (!fieldNumbers(value)) {
      //   this.sumToPay = value.replace(/[^+\d]/g, '')
      // } else if (value > 0) {
      //   this.sumToPay = parseInt(value)
      // } else {
      //   this.sumToPay = ''
      // }

      switch (this.payType) {
        case PAYMENT_TYPE.payPall : {
          let paypall = this.getUserSettings.payments.paypal
          let authorize = this.getUserSettings.payments.authorize

          if (paypall.fromUSA) {
            this.recountBankFeeds(paypall.paypal_no_usa_fee.value, parseFloat(authorize.authorize_per_transaction.value) + 0.01)
          } else {
            this.recountBankFeeds(paypall.paypal_usa_fee.value, parseFloat(authorize.authorize_per_transaction.value) + 0.01)
          }

          break
        }

        case PAYMENT_TYPE.authorize : {
          let authorize = this.getUserSettings.payments.authorize
          this.recountBankFeeds(authorize.default_authorize_commission.value, authorize.authorize_per_transaction.value)
          break
        }

        case PAYMENT_TYPE.braintree : {
          // this.bankFeeds = this.sumToPay
          let commission = this.getUserSettings.payments.authorize
          this.recountBankFeeds(commission.default_authorize_commission.value, commission.authorize_per_transaction.value)
          break
        }
      }
    },

    recountBankFeeds(paymentSystemFee, additionalPercent) {
      paymentSystemFee = paymentSystemFee.replace(',', '.')

      if (this.sumToPay === '') {
        this.bankFeeds = 0
      } else {
        // на прикладі 90
        // (((90 + 0.3) * 4.20) / 100) + (90 + 0.3)
        // this.bankFeeds = (
        //   (
        //     ((parseFloat(this.sumToPay) + parseFloat(additionalPercent)) * parseFloat(paymentSystemFee)) / 100) +
        //   (parseFloat(this.sumToPay) + parseFloat(additionalPercent))
        // ).toFixed(2)

        let sum = Number(this.sumToPay) // user amount
        let paymentFee = Number(paymentSystemFee) // 4,20
        let systemPercent = Number(additionalPercent) // 0.3

        this.bankFeeds = (
          (sum + systemPercent) * (100 / (100 - paymentFee))
        ).toFixed(2)
        // console.log(this.bankFeeds);
      }


      // if (additionalPercent) {
      //   this.bankFeeds = (parseFloat(this.bankFeeds) + parseFloat(additionalPercent)).toFixed(2)
      // }

    },

    changeRadio(val) {
      this.payType = val
      this.changeInput(this.sumToPay)
    },

  }
}
