export const tabsMixins = {
  methods: {
    stepChange (data){
      this.activeStep = data + 1;
    },

    prevPageHandler(){
      if ( this.activeStep > 1) this.activeStep = this.activeStep - 1;
    },
  }
}
