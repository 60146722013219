<template>
  <div class="large-switch">

      <label class="large-switch__label"
             :for="'large-switch1-' + id"
      >
        <input class="large-switch__input"
               type="radio"
               :name="name ? name : 'name'"
               :value="activeValue"
               :id="'large-switch1-' + id"
               @change="radioChange"
               :checked="checkedItem === activeValue"
        >
        <div class="large-switch__btn">
          <span>
            <span class="large-switch__ico">
              <CheckIco/>
            </span>

            {{ labelActiveValue }}
          </span>
        </div>
      </label>

      <label class="large-switch__label"
             :for="'large-switch2-' + id"
      >
        <input class="large-switch__input"
               type="radio"
               :name="name ? name : 'name'"
               :value="inactiveValue"
               :id="'large-switch2-' + id"
               @change="radioChange"
               :checked="checkedItem === inactiveValue"
        >
        <div class="large-switch__btn">
          <span>
            <span class="large-switch__ico">
              <CheckIco/>
            </span>
            {{ labelInactiveValue }}
          </span>
        </div>
      </label>
  </div>
</template>

<script>
  import CheckIco from "../../../../../public/img/common/check-brown-white.svg?inline"

  export default {
    name: "LargeSwitch",

    components: {
      CheckIco
    },

    data () {
      return {
        id: null,
        content: this.value,
      }
    },

    props: [
      'activeValue',
      'inactiveValue',
      'labelActiveValue',
      'labelInactiveValue',
      'name',
      'checkedItem'
    ],

    mounted () {
      this.id = this._uid;
    },

    methods:{
      radioChange(val){
        this.$emit('change', val.target.value);
      }
    }

  }
</script>

<style lang="scss">
  @import "../../../../scss/colors";

  .disable-large-switch{
    pointer-events: none;

    .large-switch__input:not(:checked) + .large-switch__btn {
      opacity: 0.3;
    }
  }

  .large-switch{
    display: flex;
    border-radius: 5px;
    max-width: 340px;
    overflow: hidden;
    background: $mainBg;
    border: 1px solid $borderBrown;

    &__input{
      display: none;

      &:checked + .large-switch__btn .large-switch__ico{
        display: flex;
      }
    }

    &__label{
      display: flex;
      cursor: pointer;
      width: 100%;
      max-width: 170px;
      align-items: center;
      justify-content: center;
    }

    &__btn{
      padding: 0 15px;
      height: 42px;
      width: 100%;
      display: flex;
      align-items: center;
      max-width: 170px;
      justify-content: center;
      position: relative;

      &:before{
        content: '';
        background: $brown;
        border-radius: 5px;
        /*width: calc(100% + 18px);*/
        width: calc(100% + 2px);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -1px;
        display: block;
        height: calc(100% + 1px);
        opacity: 0;
      }

      span:not(.large-switch__ico){
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #232323;
        /*position: relative;*/
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }


    &__input:checked + &__btn{
      background: $brown;
      border-radius: 5px;

      &:before{
        opacity: 1;
      }

      span{
        color: white;
      }
    }


    &__ico{
      display: none;
      justify-content: center;
      align-items: center;
      padding-bottom: 1px;

      position: absolute;
      right: 4px;
      top: 4px;

      svg{
        width: 16px;
        height: 16px;
      }
    }


  }
</style>
