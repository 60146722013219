var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[_c('div',{staticClass:"order-create__section-label section-label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'consolidations_status',
        'consolidations_inProgressStatus',
        'consolidations_departedStatus',
        'consolidations_receivedStatus',
        'consolidations_countedStatus',
        ])}}}),_vm._v(" "+_vm._s(_vm.$t('consolidations_status.localization_value.value'))+" ")]),_c('div',{staticClass:"order-create__row custom-row"},[_c('div',{staticClass:"order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"},[_c('DefaultSelect',{staticClass:"w-100",class:{'ui-no-valid': _vm.consolidation.validation.status},attrs:{"options":_vm.CONSOLIDATION_STATUSES_ARRAY,"label":_vm.$t('consolidations_status.localization_value.value'),"otherValue":'translation',"errorTxt":_vm.$t(`${_vm.consolidation.validationTranslate.status}.localization_value.value`),"error":_vm.consolidation.validation.status,"selected":_vm.consolidation.data.status},on:{"change":(val) => {_vm.consolidation.setStatus(val)}}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }