<template>
  <modal
      @close="$emit('close')"
      class="custom-popup buy-label medium-size"
  >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_buyLabelQuest',])"></div>
        {{$t('common_buyLabelQuest.localization_value.value')}}
      </div>

    </template>
    <template slot="body">
      <div class="buy-label__content">

        <div class="date-time"
             v-if="_.has(labelPrice, 'estimated_time_of_arrival') && labelPrice.estimated_time_of_arrival"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['express_EstimatedTimeOfArrival',])"></div>
          {{$t('express_EstimatedTimeOfArrival.localization_value.value')}}
          {{labelPrice.estimated_time_of_arrival | moment("DD/MM/YYYY H:mm:ss")}}
        </div>

        <DotsShadowPreloader
            v-if="Object.keys(labelPrice).length === 0 && $store.getters.getExpressLoadingPrice"
        />

        <LabelPrice
            v-if="Object.keys(labelPrice).length > 0 && !$store.getters.getExpressLoadingPrice"
            :labelPrice="labelPrice"
            :withVariantPrice="false"
            :dearCustomer="false"
        />
        <div v-else
             class="mt-3 mb-4 error-field position-relative fsz14 text-left">
          {{labelError}}
        </div>

        <div v-if="labelNeedMoneyError"
             class="mt-0 mb-4 error-field position-relative fsz14">
          {{labelNeedMoneyError}}
        </div>

      </div>
    </template>
    <template slot="footer">
      <div class="buy-label__btn d-flex align-items-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel','common_payForAuthorize', 'common_buyLabel'])"></div>
        <span
            class="site-link site-link--alt buy-label__btn-i mr-3"
            @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
            :value="$t('common_ConfirmLabel.localization_value.value')"
            class="buy-label__btn-i wfc"
            v-if="confirmation"
            v-bind:class="{'disabled-btn' : $store.getters.getExpressBtn}"
            @click.native="confirmLabel(idCostConsoLabel, deliveryServiceId)"
        />

        <div style="position: relative;" v-else-if="!$store.getters.getExpressLoadingPrice &&
							Object.keys(labelPrice).length > 0 &&
							!labelNeedMoneyAmount &&
							(Object.keys(labelPrice).length > 0 && parseFloat(labelPrice.price) !== 0)"
        >
          <MainButton
              :value="$t('common_buyLabel.localization_value.value')"
              class="buy-label__btn-i wfc"
              @click.native="makeLabelHandler"
              v-bind:class="{'disabled-btn' : $store.getters.getExpressBtn || blockBuyLabelConsolidation}"
          />
          <span class="default-input-wrap__error error-field error-field--btn" v-if="blockBuyLabelConsolidation">
              {{$t('consolidations_buyLabelError.localization_value.value')}}
            </span>
        </div>


        <MainButton
            :value="$t('common_payForAuthorize.localization_value.value')"
            class="buy-label__btn-i wfc"
            @click.native="$emit('payForAuthorize', {labelNeedMoneyAmount, invoiceId, userBalance, orderAmount, paySystemCommission, idCostConsoLabel})"
            v-if="labelNeedMoneyAmount"
        />



      </div>
    </template>
  </modal>

</template>

<script>
import Modal from '../../../../commonModals/Modal.vue'
import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
import {consolidationMixin} from "../../../../../mixins/consolidationMixins/consolidationMixin";
import LabelPrice from "../../../../coreComponents/LabelPrice/LabelPrice";
import DotsShadowPreloader from "../../../../coreComponents/preloaders/DotsShadowPreloader/DotsShadowPreloader";
// import {CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY} from "@/staticData/staticVariables";

export default {
  name: "BuyLabelPopup",
  components: {
    DotsShadowPreloader,
    LabelPrice,
    Modal,
    MainButton,
  },

  mixins: [consolidationMixin],

  props: {
    idCostConsoLabel: Number,
    deliveryServiceId: Number,
    confirmation: Boolean,
  },

  data() {
    return {
      labelPrice: {},
      labelError: '',
      userBalance: '',
      orderAmount: '',
      paySystemCommission: '',
      labelNeedMoneyAmount: '',
      labelNeedMoneyError: '',
      invoiceId: '',
      estimatedTime: '',
      blockBuyLabelConsolidation: false,

      destinationId: null,
    }
  },

  created() {

    this.$store.dispatch('getConsolidationCreate').then(response => {
      let respData = this.getRespData(response)
      this.$store.dispatch('getConsolidation', this.idCostConsoLabel).then((response) => {
        this.destinationId = this.getRespData(response)?.consolidation_destination_id
        this.getLabelPriceFromTable(this.$store.getters.getConsolidationItem, respData?.destinationWarehouses[0])
      })
    })



  },


  methods: {


    makeLabelHandler() {

      let dispatchType = 'consolidationCreateLabel'

      // if(this.destinationId && Number(this.destinationId) === CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY.id) {
      //   dispatchType = 'consolidationDHLCreateLabel'
      // }

      this.$store.dispatch(dispatchType, {'consolidation_id': this.idCostConsoLabel}).then(response => {
        if (this._.has(response, 'data') &&
            response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

          let responseData = response.data.data
          if(responseData.status === 'not_payed'){
            this.openNotify('error', {txtServer: responseData.message})

            this.userBalance = responseData.userBalance
            this.orderAmount = responseData.order_amount
            this.paySystemCommission = responseData.payment_system_commission
            this.labelNeedMoneyAmount = responseData.total_amount
            this.labelNeedMoneyError = responseData.message
            this.invoiceId = responseData.invoice_id
            return
          }

          this.$emit('reload')
          this.$emit('close')

        } else if (response.response.data.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
          let errors = response.response.data.errors

          this.notifyErrorHelper(errors)
        } else {
          this.openNotify('error', 'common_notificationUndefinedError')
        }
      })
    },
  }

}

</script>

<style lang="scss" scoped>
.date-time{
  margin-bottom: 20px;
}
</style>
