<template>
  <div class="fragment">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fbm_SendFromInformation'])"></div>
      {{ $t('fbm_SendFromInformation.localization_value.value') }}
    </div>

    <div class="order-create__row custom-row" v-if="!isAdminEdit">
      <div class="order-create__col custom-col custom-col--66 custom-col--md-66 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <DefaultSelect
            :options="options"
            :label="''"
            :otherValue="'sendFrom'"
            v-bind:class="{'ui-no-valid': consolidation.validation.sendFrom}"
            :errorTxt="$t(`${consolidation.validationTranslate.sendFrom}.localization_value.value`)"
            :error="consolidation.validation.sendFrom"
            :selected="consolidation.data.sendFrom"
            :clearable="false"
            @change="val => {consolidation.data.sendFrom = val}"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--md-33 custom-col--sm-100"
           v-if="!(isAdmin && isAdminEdit)"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <MainButton class="btn-fit-content btn-right-block-i"
                    :value="$t('common_AddNew.localization_value.value')"
                    :ico="'ico'"
                    @click.native="isSendFromDataPopup = true"
        >
          <template slot="ico">
            <PlusIconSVG class="btn-icon-plus"/>
          </template>
        </MainButton>
      </div>
    </div>

    <div class="order-create__row custom-row" v-else-if="consolidation.data.sendFrom && isAdminEdit">
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <DefaultInput
            :label="$t('common_name.localization_value.value')"
            :type="'text'"
            :name="'firstName'"
            v-bind:class="{'ui-no-valid': consolidation.validationTranslate.sendFrom}"
            :errorTxt="$t(`${consolidation.validationTranslate.sendFrom}.localization_value.value`)"
            :error="consolidation.validation.sendFrom"
            v-model="consolidation.data.sendFrom.first_name"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <DefaultInput
            :label="$t('common_surName.localization_value.value')"
            :type="'text'"
            :name="'firstName'"
            v-bind:class="{'ui-no-valid': consolidation.validationTranslate.sendFrom}"
            :errorTxt="$t(`${consolidation.validationTranslate.sendFrom}.localization_value.value`)"
            :error="consolidation.validation.sendFrom"
            v-model="consolidation.data.sendFrom.last_name"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <DefaultInput
            :label="$t('profile_companyName.localization_value.value')"
            :type="'text'"
            :name="'firstName'"
            v-bind:class="{'ui-no-valid': consolidation.validationTranslate.sendFrom}"
            :errorTxt="$t(`${consolidation.validationTranslate.sendFrom}.localization_value.value`)"
            :error="consolidation.validation.sendFrom"
            v-model="consolidation.data.sendFrom.company_name"
        />
      </div>
    </div>


    <SendFromDataPopup
        v-if="isSendFromDataPopup && !(isAdmin && isAdminEdit)"
        :type="'create'"
        @successAdd="getSenderData"
        @close="isSendFromDataPopup = false"
    />

  </div>
</template>

<script>
import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
import SendFromDataPopup from "@/components/coreComponents/Popups/SendFromDataPopup/SendFromDataPopup";
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";

export default {
  name: "SendFromBlockConso",

  components: {DefaultInput, SendFromDataPopup, MainButton, DefaultSelect, PlusIconSVG},

  props: {
    consolidation: Object,
    isAdminEdit: Boolean,
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    getUser() {
      return this.consolidation.data.User.user
    }
  },

  watch: {
    getUser() {
      if(this.isEdit) {
        this.options = this.consolidation.data.Order.data.orderOBJ?.send_from_list?.data
        return
      }

      if(this.isAdmin && this.consolidation.data.User.user?.id) {
        this.$store.dispatch('fetchOrderSendFrom', '?filter[userId]='+this.consolidation.data.User.user?.id).then(response => {
          this.options = this.getRespPaginateData(response)
        })
      }
    }
  },

  data() {
    return {
      options: [],
      isSendFromDataPopup: false,
    }
  },

  mounted() {
    this.getSenderData()
  },

  methods: {
    getSenderData() {
      // console.log(213123);
      // console.log(this.isEdit);
      // if(this.isEdit) {
      //   console.log(this.consolidation.data.Order.data.orderOBJ?.send_from_list);
      //   this.options = this.consolidation.data.Order.data.orderOBJ?.send_from_list?.data
      //   return
      // }
      if(this.isAdminEdit) return

      if(this.consolidation.data.Order.data.orderOBJ?.send_from_list?.data) {
        this.options = this.consolidation.data.Order.data.orderOBJ?.send_from_list?.data
        return
      }

      this.$store.dispatch('fetchOrderSendFrom').then(response => {
        this.options = this.getRespPaginateData(response)
        if(!this.consolidation.data.sendFrom && this.options.length > 0 && !this.isAdmin) {
          this.consolidation.data.sendFrom = this.options[0]
        }
      })
    },
  },

}
</script>

<style scoped>

</style>