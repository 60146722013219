<template>
  <div class="fragment">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'consolidations_dimensions',
          ])"></div>
      {{$t('consolidations_dimensions.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'consolidations_numberOfBoxes',
          'common_weightKg',
          'consolidations_lengthCm',
          'consolidations_widthCm',
          'consolidations_heightCm',
          'consolidations_volumeWeight',
          'consolidations_shippingCost',
          ])"></div>
<!--      <div class="order-create__col custom-col custom-col&#45;&#45;20 custom-col&#45;&#45;md-33 custom-col&#45;&#45;sm-50">-->
<!--        <DefaultInput-->
<!--            :label="$t('consolidations_numberOfBoxes.localization_value.value')"-->
<!--            :type="'text'"-->
<!--            :placeholder="'e.g. 10'"-->
<!--            v-bind:class="{'ui-no-valid': consolidation.validation.numberOfBoxes}"-->
<!--            :errorTxt="$t(`${consolidation.validationTranslate.numberOfBoxes}.localization_value.value`)"-->
<!--            :error="consolidation.validation.numberOfBoxes"-->
<!--            :disabled="$store.getters.getConsolidationBtn"-->
<!--            v-model="consolidation.data.numberOfBoxes"-->
<!--            @input="recalcValues()"-->
<!--        />-->
<!--      </div>-->

      <div class="order-create__col custom-col custom-col--16 custom-col--md-33 custom-col--sm-50">
        <DefaultInput
            :ref="'weight'"
            :label="$t('common_weightKg.localization_value.value')"
            :type="'text'"
            :placeholder="'e.g. 10'"
            v-bind:class="{'ui-no-valid': consolidation.data.Dimensions.validation.weight}"
            :errorTxt="$t(`${consolidation.data.Dimensions.validationTranslate.weight}.localization_value.value`)"
            :error="consolidation.data.Dimensions.validation.weight"
            v-model="consolidation.data.Dimensions.data.weight"
            @blur="recalcValues('weight')"
            @input.native="acceptNumber($event, 'weight')"
        />
<!--            :disabled="$store.getters.getConsolidationBtn"-->
      </div>

      <div class="order-create__col custom-col custom-col--16 custom-col--md-33 custom-col--sm-50">
        <DefaultInput
            :ref="'weight'"
            :label="$t('consolidations_lengthCm.localization_value.value')"
            :type="'text'"
            :placeholder="'e.g. 10'"
            v-bind:class="{'ui-no-valid': consolidation.data.Dimensions.validation.dimensionLength}"
            :errorTxt="$t(`${consolidation.data.Dimensions.validationTranslate.dimensionLength}.localization_value.value`)"
            :error="consolidation.data.Dimensions.validation.dimensionLength"
            v-model="consolidation.data.Dimensions.data.dimensionLength"
            @blur="recalcValues('length')"
            @input.native="acceptNumber($event, 'length')"
        />
<!--            :disabled="$store.getters.getConsolidationBtn"-->
      </div>

      <div class="order-create__col custom-col custom-col--16 custom-col--md-33 custom-col--sm-50">
        <DefaultInput
            :ref="'width'"
            :label="$t('consolidations_widthCm.localization_value.value')"
            :type="'text'"
            :placeholder="'e.g. 10'"
            v-bind:class="{'ui-no-valid': consolidation.data.Dimensions.validation.width}"
            :errorTxt="$t(`${consolidation.data.Dimensions.validationTranslate.width}.localization_value.value`)"
            :error="consolidation.data.Dimensions.validation.width"
            v-model="consolidation.data.Dimensions.data.width"
            @blur="recalcValues('width')"
            @input.native="acceptNumber($event, 'width')"
        />
<!--            :disabled="$store.getters.getConsolidationBtn"-->
      </div>

      <div class="order-create__col custom-col custom-col--16 custom-col--md-33 custom-col--sm-50">
        <DefaultInput
            :ref="'height'"
            :label="$t('consolidations_heightCm.localization_value.value')"
            :type="'text'"
            :placeholder="'e.g. 10'"
            v-bind:class="{'ui-no-valid': consolidation.data.Dimensions.validation.height}"
            :errorTxt="$t(`${consolidation.data.Dimensions.validationTranslate.height}.localization_value.value`)"
            :error="consolidation.data.Dimensions.validation.height"
            v-model="consolidation.data.Dimensions.data.height"
            @blur="recalcValues('height')"
            @input.native="acceptNumber($event, 'height')"
        />
<!--            :disabled="$store.getters.getConsolidationBtn"-->
      </div>

      <div class="order-create__col custom-col custom-col--16 custom-col--md-33 custom-col--sm-50">
        <DefaultInput
            :label="$t('consolidations_volumeWeight.localization_value.value')"
            :type="'text'"
            :placeholder="'e.g. 10'"
            v-model="consolidation.data.volumeWeight"
            :disabled="true"
        />
      </div>

      <div v-if="!isFromYourCity" class="order-create__col custom-col custom-col--16 custom-col--md-33 custom-col--sm-50">
        <DefaultInput
            :label="$t('consolidations_shippingCost.localization_value.value')"
            :type="'text'"
            :placeholder="'e.g. 10'"
            v-model="consolidation.data.shippingCost"
            :disabled="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
import {consolidationMixinHelper} from "../../../../../../../mixins/consolidationMixins/consolidationMixinHelper";

export default {
  name: "DimensionsBlock",

  components: {
    DefaultInput
  },

  mixins: [consolidationMixinHelper],

  props: {
    consolidation: Object,
  },

  mounted() {
    if (this.consolidation.data.Dimensions.data.weight && this.consolidation.data.Dimensions.data.dimensionLength &&
        this.consolidation.data.Dimensions.data.width && this.consolidation.data.Dimensions.data.height && !this.consolidation.data.volumeWeight) {
      this.recalcValues()
    }
  },

  methods: {
    recalcValues() {
      this.consolidation.calcVolumeWeight()
      if (!this.isFromYourCity) {
        this.consolidation.calcShippingCost(this.$store)
      }
    },
    acceptNumber(event, type) {
      switch (type) {
        case 'weight':
          this.consolidation.data.Dimensions.data.weight = event.target.value.replace(',', '.').replace(/[^0-9.]/g, '')
          break
        case 'length':
          this.consolidation.data.Dimensions.data.dimensionLength = event.target.value.replace(/\D/g, '')
          break
        case 'width':
          this.consolidation.data.Dimensions.data.width = event.target.value.replace(/\D/g, '')
          break
        case 'height':
          this.consolidation.data.Dimensions.data.height = event.target.value.replace(/\D/g, '')
          break
        default:
          break
      }
    }
  }
}
</script>

<style scoped>

</style>