<template>
  <div class="fragment">

    <div class="custom-row">
      <div class="custom-col"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                          'consolidations_LunchBreak',
                        ])"></div>
        <DefaultCheckbox
            v-model="consolidation.data.lunchBreak"
            :label="$t('consolidations_LunchBreak.localization_value.value')"
            @click="consolidation.data.lunchBreak = !consolidation.data.lunchBreak"
        />
      </div>
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--33 custom-col--sm-100">
        <div class="order-create__section-label section-label"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                          'consolidations_DeliveryDate',
                        ])"></div>
          {{$t('consolidations_DeliveryDate.localization_value.value')}}
        </div>

        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col mb-0"
               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                          'common_date',
                        ])"></div>
            <DatePickerDefault
                :label="$t('common_date.localization_value.value')"
                v-bind:class="{'ui-no-valid': consolidation.validation.deliveryDate}"
                :errorTxt="$t(`${consolidation.validationTranslate.deliveryDate}.localization_value.value`)"
                :error="consolidation.validation.deliveryDate"
                :value="consolidation.data.deliveryDate"
            >
              <template slot="body">
                <date-picker
                    v-model="consolidation.data.deliveryDate"
                    valueType="format"
                    :format="'YYYY-MM-DD'"
                    :disabled-date="(date) => getRangeDeliveryDate(date)"
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :time-picker-options="timePickerOptionsBeforeLunch"
                ></date-picker>
              </template>
            </DatePickerDefault>
          </div>
        </div>
      </div>

      <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([

                      ])"></div>
        <div class="order-create__section-label section-label">
          {{$t('consolidations_TimeGap.localization_value.value')}}
        </div>

        <div class="order-create__row custom-row  mb-0">
          <div class="order-create__col custom-col custom-col--50">
            <DatePickerDefault
                :label="$t('consolidations_From.localization_value.value')"
                :value="consolidation.data.timeCourierFrom"
                class="date-picker-time-custom"
                v-bind:class="{'ui-no-valid': consolidation.validation.timeCourierFrom}"
                :errorTxt="$t(`${consolidation.validationTranslate.timeCourierFrom}.localization_value.value`)"
                :error="consolidation.validation.timeCourierFrom"
            >
              <template slot="body">
                <date-picker
                    v-model="consolidation.data.timeCourierFrom"
                    valueType="format"
                    format="HH:mm"
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :time-picker-options="timePickerOptionsBeforeLunch"
                    type="time"
                ></date-picker>
              </template>
            </DatePickerDefault>
          </div>
          <div class="order-create__col custom-col  custom-col--50 ">
            <DatePickerDefault
                :label="$t('consolidations_To.localization_value.value')"
                :value="consolidation.data.timeCourierTo"
                class="date-picker-time-custom"
                v-bind:class="{
                        'ui-no-valid': consolidation.validation.timeCourierTo,
                      }"
                :errorTxt="$t(`${consolidation.validationTranslate.timeCourierTo}.localization_value.value`)"
                :error="consolidation.validation.timeCourierTo"
            >
              <template slot="body">
                <date-picker
                    v-model="consolidation.data.timeCourierTo"
                    valueType="format"
                    format="HH:mm"
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :time-picker-options="timePickerOptionsBeforeLunch"
                    type="time"
                ></date-picker>
              </template>
            </DatePickerDefault>
          </div>
        </div>
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
           v-if="consolidation.data.lunchBreak"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                        'consolidations_lunchPeriod',
                        'consolidations_From',
                        'consolidations_To',
                      ])"></div>
        <div class="order-create__section-label section-label">
          {{$t('consolidations_lunchPeriod.localization_value.value')}}
        </div>

        <div class="order-create__row custom-row  mb-0">
          <div class="order-create__col custom-col custom-col--50">
            <DatePickerDefault
                :label="$t('consolidations_From.localization_value.value')"
                :value="consolidation.data.timeNotAvailableFrom"
                class="date-picker-time-custom"
                v-bind:class="{'ui-no-valid': consolidation.validation.timeNotAvailableFrom}"
                :errorTxt="$t(`${consolidation.validationTranslate.timeNotAvailableFrom}.localization_value.value`)"
                :error="consolidation.validation.timeNotAvailableFrom"
            >
              <template slot="body">
                <date-picker
                    v-model="consolidation.data.timeNotAvailableFrom"
                    valueType="format"
                    format="HH:mm"
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :time-picker-options="timePickerOptionsAfterLunch"
                    type="time"
                ></date-picker>
              </template>
            </DatePickerDefault>
          </div>
          <div class="order-create__col custom-col  custom-col--50">
            <DatePickerDefault
                :label="$t('consolidations_To.localization_value.value')"
                :value="consolidation.data.timeNotAvailableTo"
                class="date-picker-time-custom"
                v-bind:class="{'ui-no-valid': consolidation.validation.timeNotAvailableTo}"
                :errorTxt="$t(`${consolidation.validationTranslate.timeNotAvailableTo}.localization_value.value`)"
                :error="consolidation.validation.timeNotAvailableTo"
            >
              <template slot="body">
                <date-picker
                    valueType="format"
                    format="HH:mm"
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    type="time"
                    :time-picker-options="timePickerOptionsAfterLunch"
                    v-model="consolidation.data.timeNotAvailableTo"
                ></date-picker>
              </template>
            </DatePickerDefault>


          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
import DatePicker from 'vue2-datepicker'
import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";

export default {
  name: "PickupDetails",

  components: {
    DefaultCheckbox,
    DatePickerDefault,
    DatePicker
  },

  props: {
    consolidation: Object,
  },

  data() {
    return {
      timePickerOptionsBeforeLunch:{
        start: '09:00',
        step: '00:15',
        end: '16:00'
      },

      timePickerOptionsAfterLunch:{
        start: '09:00',
        step: '00:15',
        end: '16:00'
      },
    }
  },

  methods: {
    getRangeDeliveryDate(date) {
      return (date < new Date().setDate(new Date().getDate() - 1))
    },

    getCourierTimeBeforeLunch(time) {
      let date = new Date()
      if (!this.consolidation.lunchBreak) {
        return (time < new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9, 0) ||
            time > new Date(date.getFullYear(), date.getMonth(), date.getDate(), 16, 0));
      }
      return (time < new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9, 0) ||
          time > new Date(date.getFullYear(), date.getMonth(), date.getDate(), 13, 0));
    },

    getCourierTimeAfterLunch(time) {
      let date = new Date()
      if (!this.consolidation.lunchBreak) {
        return (time < new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9, 0) ||
            time > new Date(date.getFullYear(), date.getMonth(), date.getDate(), 16, 0));
      }
      return (time < new Date(date.getFullYear(), date.getMonth(), date.getDate(), 13, 0) ||
          time > new Date(date.getFullYear(), date.getMonth(), date.getDate(), 16, 0));
    },
  }
}
</script>

<style scoped>

</style>