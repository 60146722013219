<template>
  <div class="fragment">

    <div class="order-create__row custom-row"
    v-if="consolidation.data.comment.length === 0 && !addComment">
      <div class="order-create__col custom-col custom-col--66 custom-col--md-100 "
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'consolidations_addComment',
          ])"></div>
        <div class="site-link" @click="addComment = true">{{$t('consolidations_addComment.localization_value.value')}}</div>
      </div>
    </div>

    <template v-if="consolidation.data.comment.length > 0 || addComment">
      <div class="order-create__section-label section-label"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'consolidations_comment',
          ])"></div>
        {{$t('consolidations_comment.localization_value.value')}}
      </div>

      <div class="order-create__row custom-row" >
        <div class="order-create__col custom-col custom-col--66 custom-col--md-100 ">
          <TextareaDefault
              :label="$t('common_comment.localization_value.value')"
              v-bind:class="{'ui-no-valid': consolidation.validation.comment}"
              :error="consolidation.validation.comment"
              :errorTxt="$t(`${consolidation.validationTranslate.comment}.localization_value.value`)"
              v-model="consolidation.data.comment"
          />
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import TextareaDefault from "../../../../../../UI/textarea/TextareaDefault/TextareaDefault";

export default {
  name: "CommentBlock",

  components: {
    TextareaDefault
  },

  props: {
    consolidation: Object
  },

  data() {
    return {
      addComment: false,
    }
  }
}
</script>

<style scoped>

</style>