<template>
  <div class="accord">
    <div class="accord__header noPrintable">
      <div class="accord__header-title">
        <slot name="header">

        </slot>
      </div>
      <div class="accord__header-arrow"
           :class="{'show' : show}"
           @click="changeShow"
      >
        <AccordArrowIcon/>
      </div>
    </div>
    <div class="accord__body" v-show="show">
      <slot name="body">

      </slot>
    </div>
  </div>
</template>

<script>
  import AccordArrowIcon from '../../../../public/img/common/accord-arrow-icon.svg?inline'


  export default {
    name: "AccordBlock",
    components: {
      AccordArrowIcon,
    },

    data(){
      return {
        show: true,
      }
    },

    methods: {
      changeShow(){
        this.show = !this.show;
      }
    }
  }
</script>

<style lang="scss">
  @import "../../../scss/colors";
  @import "../../../scss/mixins/mixins";

  .accord{
    background: white;
    border-radius: 5px;
    padding: 24px 32px 6px;

    @include for-992{
      padding: 30px 15px 5px;
    }

    &__header{
      position: relative;
      padding-bottom: 16px;
    }

    &__header-title{
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      color: $black;
      padding-right: 20px;
    }

    &__header-arrow{
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      transition: .15s;
      transform: rotate(-180deg);

      @include for-550{
        right: 8px;
      }

      &:before{
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 40px;
        height: 40px;
      }

      &.show{
        transform: rotate(0);
      }
    }

    &__body{
      padding-top: 16px;
      border-top: 1px solid $brown;
    }



  }


</style>
