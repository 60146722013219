import {ProductCells} from "../../components/globalModels/ProductCells";

/**
 * warehouseStorageItemMixin
 */
export const productForCellMixin = {

  methods: {

    initCells(productForCellItem, outbound){
      if(outbound){
        this.getCellForUser(productForCellItem, outbound)
      }
      // return Promise.all([
      //   this.getCellForUser(productForCellItem, outbound),
      //   this.getCellForFree(productForCellItem, outbound),
      //   this.getCellForMixed(productForCellItem, outbound)
      // ])
    },

    getCellForUser(productItem, outbound){
      if(productItem.data.UserCellArray.length > 0 && productItem.data.UserCellArray[0].data.cellOptions.length > 0)
        return

      const query = new this.Query();
      let url = '?';
      let myQuery = query
          .for('posts')

      let productId = productItem.data.Product.getProduct().id
      if(!outbound) {
        productId = productItem.data.ProformProduct.getProductObject().id
      }
      // user

      this.$store.dispatch('fetchProductsWithCells', `?user_id=${this.user.getUserId()}`).then(response => {
        let products = this.getRespData(response)
        let productDataById = this._.find(products, {id: productId})
        let productWithoutCells = this._.find(productDataById.items_in_storage, {cell_id: null, cell: null})

        if(productWithoutCells){
          let productWithoutCellsCount = productWithoutCells.quantity
          productItem.data.withoutCellDeclaredCounts = productWithoutCellsCount
        }
      }).catch(error => this.createErrorLog(error))

      myQuery.where('product_user_id', this.user.getUserId())
      myQuery.where('damage', 0)
      myQuery.where('byNormalProductId', productId)
      myQuery.where('mixed', false)

      url = url + myQuery.url().split('?')[1]


      return this.$store.dispatch('getWarehouseStorageCellWithFilterPublic', url).then(response => {
        productItem.data.UserCell.setCellOptions(this.getRespPaginateData(response))

        let hasBeenExistCell = false
        /**
         * Set Array User cells
         */
        let cells = this.getRespPaginateData(response)
        cells.map(cell => {
          let notDamageProductCount = this._.find(cell.items, {product_id: productId, damage: false})?.quantity
          let existCell = this._.find(productItem.data.UserCellArray, function (item) {
            return item.data.cellSelected.id === cell.id
          })

          /**
           * If exist cell - add only Options and Storage count in UserCellArray item
           * else add new ProductCells in UserCellArray (options, selectedItem, storageCount)
           */
          if(existCell) {
            hasBeenExistCell = true
            existCell.data.cellOptions = cells
            existCell.data.storageCount = notDamageProductCount
          } else {
            if(notDamageProductCount > 0){
              productItem.data.UserCellArray.push(new ProductCells(cells, cell, notDamageProductCount))
            }
          }

        })

        /**
         * Set cellCount in cell with biggest storageCount
         */
        if(!hasBeenExistCell){
          this.setBiggestStorageCount(productItem)
        }

      }).catch(error => this.createErrorLog(error))
    },

    getCellForFree(productItem, outbound){
      if(outbound) return

      const query = new this.Query();
      let url = '?';
      let myQuery = query
          .for('posts')

      myQuery.where('isFree', 1)

      url = url + myQuery.url().split('?')[1]

      return this.$store.dispatch('getWarehouseStorageCellWithFilterPublic', url).then(response => {
        productItem.data.FreeCell.setCellOptions(this.getRespPaginateData(response))

        /**
         * Set Array User cells
         */
        let cells = this.getRespPaginateData(response)

        cells.map((cell, index) => {
          if(index > 15) return

          let existCell = this._.find(productItem.data.UserCellArray, function (item) {
            return item.data.cellSelected.id === cell.id
          })

          /**
           * If exist cell - add only Options and Storage count in MixedCellArray item
           * else add new ProductCells in MixedCellArray (options, selectedItem, storageCount)
           */
          if(existCell) {
            existCell.data.cellOptions = cells
          } else {
            productItem.data.FreeCellArray.push(new ProductCells(cells, cell))
          }
        })

      }).catch(error => this.createErrorLog(error))
    },

    getCellForMixed(productItem, outbound){
      if(productItem.data.MixedCellArray.length > 0 && productItem.data.MixedCellArray[0].data.cellOptions.length > 0)
        return

      const query = new this.Query();
      let url = '?';
      let myQuery = query.for('posts')

      let productId = productItem.data.Product.getProduct().id
      if(!outbound) {
        productId = productItem.data.ProformProduct.getProductObject().id
      }

      myQuery.where('mixed', true)
      myQuery.where('product_user_id', this.user.getUserId())
      myQuery.where('damage', 0)
      myQuery.where('byNormalProductId', productId)

      url = url + myQuery.url().split('?')[1]

      return this.$store.dispatch('getWarehouseStorageCellWithFilterPublic', url).then(response => {
        productItem.data.MixedCell.setCellOptions(this.getRespPaginateData(response))

        /**
         * Set Array User cells
         */
        let cells = this.getRespPaginateData(response)
        cells.map(cell => {
          let notDamageProductCount = this._.find(cell.items, {product_id: productId, damage: false})?.quantity
          let existCell = this._.find(productItem.data.MixedCellArray, function (item) {
            return item.data.cellSelected.id === cell.id
          })

          /**
           * If exist cell - add only Options and Storage count in MixedCellArray item
           * else add new ProductCells in MixedCellArray (options, selectedItem, storageCount)
           */
          if(existCell) {
            existCell.data.cellOptions = cells
            existCell.data.storageCount = notDamageProductCount
          } else {
            if(notDamageProductCount > 0){
              productItem.data.MixedCellArray.push(new ProductCells(cells, cell, notDamageProductCount))
            }
          }
        })
      }).catch(error => this.createErrorLog(error))
    },

    setBiggestStorageCount(productItem) {
      let sortByStorageCount = this._.sortBy(productItem.data.UserCellArray, function (item) {
        return item.data.storageCount
      })
      let biggestStorageCount = this._.last(sortByStorageCount)
      if(biggestStorageCount){
        biggestStorageCount.data.cellCount = productItem.data.scannedCount
      }
    },


    changeProductName(item, val) {
      item.setProduct(val)
    },
    changeProductQuantity(item, val) {
      item.setProductCount(val)
    },

    setCellsInProductForCell(val, productForCell){
      productForCell.data.MixedCellArray = []
      productForCell.data.UserCellArray = []

      val.items_in_storage.map(itemInStorage => {
        let productCells = new ProductCells(false, itemInStorage.cell, itemInStorage.quantity, false)
        if(itemInStorage?.cell?.mixed){
          productForCell.data.MixedCellArray.push(productCells)
        } else {
          productForCell.data.UserCellArray.push(productCells)
        }
      })
    },

    openProductForCellPopup(setIndex = false, productInSetIndex = false){
      if(setIndex >= 0){
        this.setIndex = setIndex
        this.productInSetIndex = productInSetIndex
      } else {
        this.setIndex = -1
        this.productInSetIndex = -1
      }
      this.changeProductForCellPopup(true)
    },

    changeProductChecked(checked, item) {
      let firstProductsForCell = this._.first(item.data.UserCellArray)
      let firstProductsMixedForCell = this._.first(item.data.MixedCellArray)

      if(checked){
        item.allScannedProduct()

        if(firstProductsForCell) {
          return firstProductsForCell.data.cellCount = item.data.productCount
        }

        if(firstProductsMixedForCell) {
          return firstProductsMixedForCell.data.cellCount = item.data.productCount
        }

        // if(parseInt(item.data.withoutCellDeclaredCounts)) {
        // }
          return item.data.withoutCellEndCounts = item.data.productCount

      } else {
        item.removeAllScannedProduct()

        if(firstProductsForCell) {
          firstProductsForCell.data.cellCount = 0
        }

        if(firstProductsMixedForCell) {
          firstProductsMixedForCell.data.cellCount = 0
        }

        if(parseInt(item.data.withoutCellEndCounts) !== 0) {
          item.data.withoutCellEndCounts = 0
        }
      }
    },

    getCellsInTable(item) {
      let text = ''
      let count = -1
      // let userCell = item.data.UserCell.data.cellSelected,
      //   freeCell = item.data.FreeCell.data.cellSelected,
      //   mixedCell = item.data.MixedCell.data.cellSelected
      //
      // userCell ? text = userCell?.combo_name : null
      // freeCell && text === '' ? text = freeCell?.combo_name : null
      // mixedCell && text === '' ? text = mixedCell?.combo_name : null

      let userCellArray = item.data.UserCellArray,
        mixedCellArray = item.data.MixedCellArray

      userCellArray.map(userCell => {
        let userCellSelected = userCell.data.cellSelected
        count === -1 ? text = userCellSelected?.combo_name : null
        count ++
        // let userCellCount = userCell.data.cellCount
        // if (userCellCount > 0) {
        //   count === -1 ? text = userCellSelected?.combo_name : null
        //   count ++
        // }
      })
      mixedCellArray.map(mixedCell => {
        let mixedCellSelected = mixedCell.data.cellSelected
        count === -1 ? text = mixedCellSelected?.combo_name : null
        count ++
        // let mixedCellCount = mixedCell.data.cellCount
        // if (mixedCellCount > 0) {
        //   text = mixedCellSelected?.combo_name
        //   count ++
        // }
      })
      return {text, count}
    },



    getCellsForUser(productItem, userId, warehouseId = false){
      // let productId = productItem.product.id
      // if(productItem.data.UserCellArray.length > 0 && productItem.data.UserCellArray[0].data.cellOptions.length > 0)
      //   return

      const query = new this.Query();
      let url = '?';
      let myQuery = query
        .for('posts')

      let productId = productItem.data.Product.getProduct().id

      this.$store.dispatch('fetchProductsWithCells', `?user_id=${userId}${warehouseId ? `&filter[byStorageId]=`+warehouseId : ''}`).then(response => {
        let products = this.getRespData(response)
        let productDataById = this._.find(products, {id: productId})
        let productWithoutCells = this._.find(productDataById.items_in_storage, {cell_id: null, cell: null})

        if(productWithoutCells){
          let productWithoutCellsCount = productWithoutCells.quantity
          productItem.data.withoutCellDeclaredCounts = productWithoutCellsCount
        }
      }).catch(error => this.createErrorLog(error))

      myQuery.where('product_user_id', userId)
      myQuery.where('damage', 0)
      myQuery.where('byNormalProductId', productId)
      // myQuery.where('mixed', false)

      if(warehouseId) {
        myQuery.where('byStorageId', warehouseId)
      }

      if(warehouseId) {
        myQuery.where('byStorageId', warehouseId)
      }

      url = url + myQuery.url().split('?')[1]


      return this.$store.dispatch('getWarehouseStorageCellWithFilterPublic', url).then(response => {
        productItem.data.UserCell.setCellOptions(this.getRespPaginateData(response))

        let hasBeenExistCell = false
        /**
         * Set Array User cells
         */
        let cells = this.getRespPaginateData(response)
        cells.map(cell => {
          let notDamageProductCount = this._.find(cell.items, {product_id: productId, damage: false})?.quantity
          let existCell = this._.find(productItem.data.UserCellArray, function (item) {
            return item.data.cellSelected.id === cell.id
          })

          /**
           * If exist cell - add only Options and Storage count in UserCellArray item
           * else add new ProductCells in UserCellArray (options, selectedItem, storageCount)
           */
          if(existCell) {
            hasBeenExistCell = true
            existCell.data.cellOptions = cells
            existCell.data.storageCount = notDamageProductCount
          } else {
            if(notDamageProductCount > 0){
              productItem.data.UserCellArray.push(new ProductCells(cells, cell, notDamageProductCount))
            }
          }

        })

        /**
         * Set cellCount in cell with biggest storageCount
         */
        if(!hasBeenExistCell){
          this.setBiggestStorageCount(productItem)
        }

      }).catch(error => this.createErrorLog(error))
    },

  },
}
