<template>
  <div class="fragment">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'consolidations_productsForConsolidation',
          ])"></div>
      {{ $t('consolidations_productsForConsolidation.localization_value.value') }}
    </div>


    <div class="custom-row">
      <div class="custom-col">
        <div class="site-table-wrap small-table products-table"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
          'consolidations_Quantity',
          'consolidations_perItem',
          ])"></div>
          <div class="site-table-wrap table-small" v-if="!isMobileFunc()">
            <table class="site-table"
                   v-if="consolidation.data.proformProductsForTable.length > 0"
            >
              <thead>
              <tr>
                <th>Укр</th>
                <th>En</th>
                <th>Матеріал</th>
                <th>Material</th>
                <th>Для</th>
                <th>For</th>
                <th class="text-lg-right white-space-line">
                  {{ $t('consolidations_Quantity.localization_value.value') }}
                </th>
                <th class="text-lg-right white-space-line">
                  {{ $t('consolidations_perItem.localization_value.value') }}
                </th>
                <th style="width: 100px;"></th>
              </tr>
              </thead>
              <tbody>

              <tr
                  v-for="(item, index) in consolidation.data.proformProductsForTable"
                  :key="index"
              >
                <td>
                  <ValueHelper
                      :value="item"
                      :deep="'product.proformData.proformTypes.1.typeValue.value.translationStorage.ua.name'"
                  />
<!--                  {{-->
<!--                    item.product.proformData.proformTypes[1].typeValue !== '' ?-->
<!--                        item.product.proformData.proformTypes[1].typeValue.value.translationStorage.ua.name : ''-->
<!--                  }}-->
                </td>
                <td>
                  <ValueHelper
                      :value="item"
                      :deep="'product.proformData.proformTypes.1.typeValue.value.translationStorage.en.name'"
                  />
<!--                  {{-->
<!--                    item.product.proformData.proformTypes[1].typeValue !== '' ?-->
<!--                        item.product.proformData.proformTypes[1].typeValue.value.translationStorage.en.name : ''-->
<!--                  }}-->
                </td>
                <td>
                  <ValueHelper
                      :value="item"
                      :deep="'product.proformData.proformTypes.2.typeValue.value.translationStorage.ua.name'"
                  />
<!--                  {{-->
<!--                    item.product.proformData.proformTypes[2].typeValue !== '' ?-->
<!--                        item.product.proformData.proformTypes[2].typeValue.value.translationStorage.ua.name : ''-->
<!--                  }}-->
                </td>
                <td>
                  <ValueHelper
                      :value="item"
                      :deep="'product.proformData.proformTypes.2.typeValue.value.translationStorage.en.name'"
                  />
<!--                  {{-->
<!--                    item.product.proformData.proformTypes[2].typeValue !== '' ?-->
<!--                        item.product.proformData.proformTypes[2].typeValue.value.translationStorage.en.name : ''-->
<!--                  }}-->
                </td>
                <td>
                  <ValueHelper
                      :value="item"
                      :deep="'product.proformData.proformTypes.5.typeValue.value.translationStorage.ua.name'"
                  />
<!--                  {{-->
<!--                    item.product.proformData.proformTypes[5].typeValue !== '' ?-->
<!--                        item.product.proformData.proformTypes[5].typeValue.value.translationStorage.ua.name : ''-->
<!--                  }}-->
                </td>
                <td>
                  <ValueHelper
                      :value="item"
                      :deep="'product.proformData.proformTypes.5.typeValue.value.translationStorage.en.name'"
                  />
<!--                  {{-->
<!--                    item.product.proformData.proformTypes[5].typeValue !== '' ?-->
<!--                        item.product.proformData.proformTypes[5].typeValue.value.translationStorage.en.name : ''-->
<!--                  }}-->
                </td>
                <td align="right">
                  <div class="table-row table-row--input">
                    <DefaultInput
                        class="right"
                        v-model="item.product.itemsQuantity"
                    />
                  </div>
                </td>
                <td align="right">
                  <div class="table-row table-row--input">
                    <DefaultInput
                        class="right"
                        v-model="item.product.price"
                    />
                  </div>
                </td>
                <td style="width: 100px;" class="manage-row">
                  <div class="table-right table-manage-list justify-content-end">

                    <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) "
                                   style="width: 100px;"
                                   :key="$store.getters.getGlobalUpdateManagerButton">
                      <template slot="item">
                        <LinkButton
                            :value="$t('common_delete.localization_value.value')"
                            :type="'delete'"
                            @click.native="removeItem(index)"
                        />
                      </template>
                    </ManagerButton>
                  </div>
                </td>
              </tr>

              </tbody>
            </table>
          </div>

          <div class="site-table-mobile"
               v-if="isMobileFunc()"
          >
            <div class="site-table-mobile__inner custom-row">
              <div class="custom-col custom-col--50 custom-col--sm-100"
                   v-for="(item, index) in consolidation.data.proformProductsForTable"
                   :key="index"
              >
                <div class="site-table-mobile__item">
                  <div class="site-table-mobile__head">
                    <ValueHelper
                        :value="item"
                        :deep="'product.proformData.proformTypes.1.typeValue.value.translationStorage.ua.name'"
                    />
<!--                    {{-->
<!--                      item.product.proformData.proformTypes[1].typeValue !== '' ?-->
<!--                          item.product.proformData.proformTypes[1].typeValue.value.translationStorage.ua.name : ''-->
<!--                    }}-->
                  </div>
                  <div class="site-table-mobile__row custom-row mb-0">
                    <div class="site-table-mobile__piece custom-col custom-col--50">
                      En
                      <span>
                         <ValueHelper
                             :value="item"
                             :deep="'product.proformData.proformTypes.1.typeValue.value.translationStorage.en.name'"
                         />
<!--											{{-->
<!--                          item.product.proformData.proformTypes[1].typeValue !== '' ?-->
<!--                              item.product.proformData.proformTypes[1].typeValue.value.translationStorage.en.name : ''-->
<!--                        }}-->
											</span>
                    </div>
                    <div class="site-table-mobile__piece custom-col custom-col--50">
                      Матеріал
                      <span>
                        <ValueHelper
                            :value="item"
                            :deep="'product.proformData.proformTypes.2.typeValue.value.translationStorage.ua.name'"
                        />
<!--											{{-->
<!--                          item.product.proformData.proformTypes[2].typeValue !== '' ?-->
<!--                              item.product.proformData.proformTypes[2].typeValue.value.translationStorage.ua.name : ''-->
<!--                        }}-->
											</span>
                    </div>
                    <div class="site-table-mobile__piece custom-col custom-col--50">
                      Material
                      <span>
                        <ValueHelper
                            :value="item"
                            :deep="'product.proformData.proformTypes.2.typeValue.value.translationStorage.en.name'"
                        />
<!--											{{-->
<!--                          item.product.proformData.proformTypes[2].typeValue !== '' ?-->
<!--                              item.product.proformData.proformTypes[2].typeValue.value.translationStorage.en.name : ''-->
<!--                        }}-->
											</span>
                    </div>
                    <div class="site-table-mobile__piece custom-col custom-col--50">
                      Для
                      <span>
                  <ValueHelper
                      :value="item"
                      :deep="'product.proformData.proformTypes.5.typeValue.value.translationStorage.ua.name'"
                  />
<!--											{{-->
<!--                          item.product.proformData.proformTypes[5].typeValue !== '' ?-->
<!--                              item.product.proformData.proformTypes[5].typeValue.value.translationStorage.ua.name : ''-->
<!--                        }}-->
											</span>
                    </div>
                    <div class="site-table-mobile__piece custom-col custom-col--50">
                      For
                      <span>
                  <ValueHelper
                      :value="item"
                      :deep="'product.proformData.proformTypes.5.typeValue.value.translationStorage.en.name'"
                  />
<!--											{{-->
<!--                          item.product.proformData.proformTypes[5].typeValue !== '' ?-->
<!--                              item.product.proformData.proformTypes[5].typeValue.value.translationStorage.en.name : ''-->
<!--                        }}-->
											</span>
                    </div>
                  </div>
                  <div class="site-table-mobile__row custom-row">
                    <div class="site-table-mobile__piece custom-col custom-col--50">
                      {{ $t('consolidations_Quantity.localization_value.value') }}
                      <span>
																<DefaultInput
                                    v-model="item.product.itemsQuantity"
                                />
											</span>
                    </div>
                    <div class="site-table-mobile__piece custom-col custom-col--50">
                      {{ $t('consolidations_perItem.localization_value.value') }}
                      <span>
																<DefaultInput
                                    v-model="item.product.price"
                                />
											</span>
                    </div>
                  </div>
                  <div class="site-table-mobile__piece custom-col">
                    <LinkButton
                        :value="$t('common_delete.localization_value.value')"
                        :type="'delete'"
                        @click.native="removeItem(index)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="block-table__total site-line-total mb-2"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
          'consolidations_totallyPerOrder',
          ])"></div>
            {{ $t('consolidations_totallyPerOrder.localization_value.value') }}
            <span>${{ getTotalPerOrder }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";

export default {
  name: "ProductsTable",

  components: {
    ValueHelper,
    LinkButton,
    ManagerButton,
    DefaultInput
  },

  mixins: [
    mixinDetictingMobile
  ],

  props: {
    consolidation: Object,
  },

  computed: {
    getTotalPerOrder: function () {
      let totalPrice = 0
      this.consolidation.data.proformProductsForTable.forEach(item => {
        totalPrice += (item.product.itemsQuantity * item.product.price) || 0
      })

      return totalPrice.toFixed(2)
    },
  },

  methods: {
    removeItem(index) {
      this.consolidation.removeProformProductsForTableItem(index)
    }
  }
}
</script>

<style scoped>
.small-table th, .small-table td {
  white-space: normal;
}
</style>